import Cookies from 'universal-cookie';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const trackingParametersStorageKey = 'tracking.zustand';

type State = {
  fbclid?: string;
  affiliateInfo: string;
};

type Action = {
  setFbclid: (fbclid: State['fbclid']) => void;
  setAffiliateInfo: (affiliateInfo: State['affiliateInfo']) => void;
};

const cookieKeys = {
  // Meta Pixel cookies
  fbc: '_fbc',
  fbp: '_fbp',
};

type Parameters = {
  fbc?: string;
  fbp?: string;
  affiliateInfo: string;
};

export const useTrackingParametersStore = create(
  persist<State & Action>(
    (set) => ({
      fbclid: undefined,
      affiliateInfo: 'general-web',
      setFbclid: (fbclid) => set(() => ({ fbclid })),
      setAffiliateInfo: (affiliateInfo) => set(() => ({ affiliateInfo })),
    }),
    {
      name: trackingParametersStorageKey,
    },
  ),
);

/**
 * Generate Facebook's fbc parameter based on the fbclid URL parameter
 * https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc
 */
function getFbcFromFbclid(fbclid: string) {
  // Version is always the same
  const version = 'fb';
  // In which domain the cookie is defined: 'com' = 0, 'example.com' = 1, 'www.example.com' = 2
  const subdomainIndex = [...location.host.matchAll(/\./g)].length;
  // UNIX time since epoch in milliseconds
  const creationTime = Date.now();
  return [version, subdomainIndex, creationTime, fbclid].join('.');
}

/**
 * A hook that retrieves tracking params from the local storage
 */
export function useTrackingParameters(): Parameters {
  const { fbclid, affiliateInfo } = useTrackingParametersStore();

  const parameters: Parameters = {
    // Affiliate link
    affiliateInfo,
  };
  const cookies = new Cookies();

  // Facebook tracking
  const fbc = cookies.get<string>(cookieKeys.fbc);
  const fbp = cookies.get<string>(cookieKeys.fbp);
  if (fbc && fbp) {
    // Use cookies from Google Pixel when available,
    parameters.fbc = fbc;
    parameters.fbp = fbp;
  } else if (fbclid) {
    // Fallback to the URL parameter
    parameters.fbc = getFbcFromFbclid(fbclid);
  }

  return parameters;
}
