import { parse, object, string, picklist, nullish, boolean } from 'valibot';
/**
 * Define the environment variables that are required for the app to run
 * @typedef {Object} Environment
 */
const EnvironmentSchema = object({
  NODE_ENV: picklist(
    ['development', 'production', 'test'],
    '`NODE_ENV` must be one of `development`, `production` or `test`',
  ),
  NEXT_BUILD_ID: nullish(string()),
  NEXT_VERSION_INFO: nullish(string()),
  NEXT_PUBLIC_ALGOLIA_API_KEY: string('`NEXT_PUBLIC_ALGOLIA_API_KEY` must be a string'),
  NEXT_PUBLIC_ALGOLIA_ID: string('`NEXT_PUBLIC_ALGOLIA_ID` must be a string'),
  NEXT_PUBLIC_ALGOLIA_INDEX: string('`NEXT_PUBLIC_ALGOLIA_INDEX` must be a string'),
  NEXT_PUBLIC_APP_ID_IOS: string('`NEXT_PUBLIC_APP_ID_IOS` must be a string'),
  NEXT_PUBLIC_AUDIENCE_API_OAUTH_URL: string('`NEXT_PUBLIC_AUDIENCE_API_OAUTH_URL` must be a string'),
  NEXT_PUBLIC_AUDIENCE_API_URL: string('`NEXT_PUBLIC_AUDIENCE_API_URL` must be a string'),
  NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME: string('`NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME` must be a string'),
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID: string('`NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID` must be a string'),
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_A: string('`NEXT_PUBLIC_GOOGLE_TAG_MANAGER_A` must be a string'),
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV: string('`NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV` must be a string'),
  NEXT_PUBLIC_MUX_KEY: string('`NEXT_PUBLIC_MUX_KEY` must be a string'),
  NEXT_PUBLIC_PHRASE_PROJECT_ID: string('`NEXT_PUBLIC_PHRASE_PROJECT_ID` must be a string'),
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: string('`NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY` must be a string'),
  NEXT_PUBLIC_STRIPE_FRAUD_DETECTION_ENABLED: boolean('`NEXT_PUBLIC_STRIPE_FRAUD_DETECTION_ENABLED` must be a boolean'),
});

// Parse the environment variables, throwing an error if any are missing or invalid
// This will be run when the app is built, so it will fail if any required environment variables are missing
// eslint-disable-next-line unicorn/prevent-abbreviations
export const env = parse(EnvironmentSchema, {
  // Simply passing `process.env` here will not work, instead we need to pass each environment variable individually
  // so the Webpack DefinePlugin can replace them with their values at build time
  NODE_ENV: process.env.NODE_ENV,
  NEXT_BUILD_ID: process.env.NEXT_BUILD_ID,
  NEXT_VERSION_INFO: process.env.NEXT_VERSION_INFO,
  NEXT_PUBLIC_ALGOLIA_API_KEY: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
  NEXT_PUBLIC_ALGOLIA_ID: process.env.NEXT_PUBLIC_ALGOLIA_ID,
  NEXT_PUBLIC_ALGOLIA_INDEX: process.env.NEXT_PUBLIC_ALGOLIA_INDEX,
  NEXT_PUBLIC_APP_ID_IOS: process.env.NEXT_PUBLIC_APP_ID_IOS,
  NEXT_PUBLIC_AUDIENCE_API_OAUTH_URL: process.env.NEXT_PUBLIC_AUDIENCE_API_OAUTH_URL,
  NEXT_PUBLIC_AUDIENCE_API_URL: process.env.NEXT_PUBLIC_AUDIENCE_API_URL,
  NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_A: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_A,
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV,
  NEXT_PUBLIC_MUX_KEY: process.env.NEXT_PUBLIC_MUX_KEY,
  NEXT_PUBLIC_PHRASE_PROJECT_ID: process.env.NEXT_PUBLIC_PHRASE_PROJECT_ID,
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  NEXT_PUBLIC_STRIPE_FRAUD_DETECTION_ENABLED: process.env.NEXT_PUBLIC_STRIPE_FRAUD_DETECTION_ENABLED === 'true',
});

/* Audience API config */
export const audienceApi = {
  url: env.NEXT_PUBLIC_AUDIENCE_API_URL,
  oauthUrl: env.NEXT_PUBLIC_AUDIENCE_API_OAUTH_URL,
};

/* Algolia config */
export const algolia = {
  applicationId: env.NEXT_PUBLIC_ALGOLIA_ID,
  apiKey: env.NEXT_PUBLIC_ALGOLIA_API_KEY,
  indexName: env.NEXT_PUBLIC_ALGOLIA_INDEX,
};

/* Google Analytics config */
export const googleTagManager = {
  id: env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  auth: env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_A,
  environment: env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV,
};

/* Stripe config */
export const stripe = {
  countryCode: 'DE', // The country code of the STAGE+ Stripe account
  publishableKey: env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
};
