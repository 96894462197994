import { useEffect } from 'react';

/**
 * Calulates some CSS variables to be used in the global CSS file.
 * Currently it setting these custom properties:
 * `--scrollbar-width` - the width of the scrollbar in pixels
 * `--vh` - 1% of the viewport height in pixels
 */
function setCssVariables() {
  // set horizontal scrollbar width variable for use in CSS (e.g. for the grid container)
  document.documentElement.style.setProperty(
    '--scrollbar-width',
    `${window.innerWidth - document.documentElement.clientWidth}px`,
  );
  /**
   * Some mobile browsers take the address bar into account when calculating the viewport height. This leads to the
   * content being too big when using 100vh (e.g. in the modal). For a more detailed explanation see the CSS-Tricks
   * article this approach is based on: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
   *
   * @todo in the future we could fall back on the `svh` or `dvh` units when they are supported by all major browsers
   * because they are made for this exact use case. See https://caniuse.com/?search=svh
   */
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
}

/**
 * Component responsible for setting global CSS variables that can not be calculated in CSS itself.
 * It should be included in the main App component.
 * @returns null
 */
export default function CssVariables() {
  // Run once on mount
  useEffect(() => {
    // Calculate the CSS variables as soon as the component is mounted
    setCssVariables();
    // Add event listeners to recalculate the CSS variables on window load and resize to make sure they are always up to date
    window.addEventListener('load', setCssVariables);
    window.addEventListener('resize', setCssVariables);

    return () => {
      window.removeEventListener('load', setCssVariables);
      window.removeEventListener('resize', setCssVariables);
    };
  }, []);

  return null;
}
