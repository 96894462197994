import { useEffect } from 'react';
import { env } from 'src/config';
import { useIsAuthenticated } from 'src/state/auth';

/**
 * Hook to load Stripe.js for fraud detection on all pages for authenticated users.
 *
 * This enhances Stripe’s fraud detection capabilities as per their documentation: https://stripe.com/docs/js/including
 *
 * @note
 *  - Only loads for authenticated users to minimize page load performance impact.
 *  - Completely optional and the checkout process will work without it.
 *  - Can be disabled via the `NEXT_PUBLIC_STRIPE_FRAUD_DETECTION_ENABLED` environment variable.
 * @example
 * ```tsx
 * // Inside the App component
 * const App = () => {
 *  useStripeFraudDetection();
 *  // Other code
 * };
 * ```
 */
export function useStripeFraudDetection() {
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (env.NEXT_PUBLIC_STRIPE_FRAUD_DETECTION_ENABLED && isAuthenticated) {
      // This module will insert a <script> tag that loads Stripe.js from https://js.stripe.com
      void import('@stripe/stripe-js');
    }
  }, [isAuthenticated]);
}
