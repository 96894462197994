import { useEffect, useState } from 'react';
import useSWR, { mutate as mutateSwrKey } from 'swr';
import useSdk, { SessionQuery } from 'src/hooks/use-sdk';
import { useToken } from 'src/state/auth';
import { getUserIdFromToken } from 'src/utilities/token';

/**
 * Extract the `User` type from the `SessionQuery` type
 */
export type SessionUser = Extract<SessionQuery['currentUser'], { __typename: 'User' }>;
/**
 * Extract the `Ticket` type from the `SessionUser` type
 */
export type SessionUserTicket = Extract<SessionUser['ticket'], { __typename: 'Ticket' }>;

/**
 * Fetches and stores information about the current user, logged in or not
 * When logged in, the user's favorites and recently played items are fetched
 * and stored in the state. Otherwise, only geoip information is fetched.
 */
export default function useSession() {
  const accessToken = useToken();
  const sdk = useSdk();
  const userId = accessToken && getUserIdFromToken(accessToken);
  const [swrKey, setSwrKey] = useState<string | undefined>(userId);

  const response = useSWR(swrKey, () => sdk.session(), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
  });

  // Update the swr key whenever the user id changes
  useEffect(() => {
    const updateSwrKey = async () => {
      // Reset the cache whenever the user id changes
      if (swrKey && swrKey !== userId) {
        await mutateSwrKey(swrKey);
      }
      setSwrKey(userId);
    };
    void updateSwrKey();
  }, [userId, swrKey]);

  return response;
}
