import ErrorTwoToneIcon from '@stageplus/icons/react/error-two-tone';
import { TextButton, TextButtonLink } from 'src/components/buttons/text-button';
import useTranslate from 'src/hooks/use-translate';

type TransientMessageProps = {
  icon: React.ReactNode;
  title?: string;
  message?: string;
  log?: string;
  button?: React.ReactNode;
  className?: string;
};

/**
 * A component to display error states as well as empty transient states.
 * @component
 *
 * @param className - a string containing class names for the message element
 * @param icon - the icon element of the message
 * @param title - the message title
 * @param message - the message text
 * @param log - an optional technical log message
 * @param button - the button element
 */

const TransientMessage = ({ className, icon, title, message, log, button }: TransientMessageProps): JSX.Element => {
  return (
    <div className={className} data-test="message-transient">
      <div className="mb-6 flex justify-center">{icon}</div>
      {title && <h1 className="dg-text-medium-1 mb-4 text-center">{title}</h1>}
      <div className="dg-text-regular-3 mb-6 text-center">
        {message && <h2 className="opacity-70">{message}</h2>}
        {log && <p className="opacity-55">[ {log} ]</p>}
      </div>
      {button && <div className="flex justify-center">{button}</div>}
    </div>
  );
};

export default TransientMessage;

type TransientErrorMessageProps = {
  title?: string;
  message?: string;
  log?: string;
  className?: string;
  action?: 'reload' | 'homepage';
};

/**
 * A component to display error states such as page errors.
 * @component
 *
 * @param className - a string containing class names for the message element
 * @param title - the message title
 * @param message - the message text
 * @param log - an optional technical log message
 */
export const TransientErrorMessage = ({
  className,
  title,
  message,
  log,
  action = 'reload',
}: TransientErrorMessageProps): JSX.Element => {
  const t = useTranslate();
  const icon = (
    <div className="inline-block">
      <ErrorTwoToneIcon className="size-20" />
    </div>
  );
  const reloadButton = (
    <TextButton onClick={() => window.location.reload()} variation="secondary" data-test="error-message-button-reload">
      {t('component__transient_error_retry_button_label')}
    </TextButton>
  );

  const backToHomepageButton = (
    <TextButtonLink href="/" variation="secondary" data-test="error-message-button-back">
      {t('component__transient_error_back_button_label')}
    </TextButtonLink>
  );

  return (
    <TransientMessage
      className={className}
      icon={icon}
      title={title}
      message={message}
      log={log}
      button={action === 'homepage' ? backToHomepageButton : reloadButton}
    />
  );
};
