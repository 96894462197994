import { PaymentProvider, SubscriptionPlanInterval, TicketStatus, TicketType } from 'generated/graphql';
import useSession, { SessionUserTicket } from 'src/hooks/use-session';

type TicketStatusData = {
  /**
   * Whether the ticket’s `validUntil` date is in the past
   * @default true
   */
  isExpired: boolean;
  /**
   * Whether the ticket’s status is `active` and the `validUntil` date is in the future
   * @default false
   */
  isActive: boolean;
  /**
   * Whether the ticket’s status is `canceled` and the `validUntil` date is in the future
   * @default false
   */
  isCanceled: boolean;
  /**
   * A date object representing the ticket’s `validUntil` date or `undefined` if the ticket is expired
   * @default undefined
   */
  validUntil: Date | undefined;
  /**
   * Whether the ticket is a gift card with validity
   */
  isGiftCard: boolean;
  /**
   * Whether the ticket is a weekly subscription
   * @default false
   */
  isWeekly: boolean;
  /**
   * Whether the ticket is a monthly subscription
   * @default false
   */
  isMonthly: boolean;
  /**
   * Whether the ticket is a yearly subscription
   * @default false
   */
  isYearly: boolean;
  /**
   * Whether the ticket uses Stripe as a payment provider
   * @default false
   */
  isStripe: boolean;
  /**
   * Whether still has a valid ticket,
   * even if it’s canceled or last payment has failed (the backend will retry a payment)
   * @default false
   */
  isValid: boolean;
  /**
   * Whether user is on a trial period
   * @default false
   */
  isTrial: boolean;
};

/**
 * A helper function to derive ticket status information from a given ticket object
 * @param ticket A ticket object
 * @returns `TicketStatusData` object
 */
export const getTicketStatus = (ticket?: SessionUserTicket): TicketStatusData => {
  // verify that the ticket exists and is still valid
  const validUntil =
    ticket?.validUntil && new Date(ticket.validUntil) > new Date() ? new Date(ticket.validUntil) : undefined;

  const isExpired = !validUntil;
  const isActive = !!validUntil && ticket?.status === TicketStatus.Active;
  // note: canceled tickets are still valid until the end of the subscription
  const isCanceled = !!validUntil && ticket?.status === TicketStatus.Canceled;
  const isWeekly = ticket?.interval === SubscriptionPlanInterval.Days_7;
  const isMonthly = ticket?.interval === SubscriptionPlanInterval.Monthly;
  const isYearly = ticket?.interval === SubscriptionPlanInterval.Yearly;
  const isStripe = ticket?.paymentProvider === PaymentProvider.Stripe;
  const isGiftCard = ticket?.ticketType === TicketType.ETicket;
  const isTrial = Boolean(ticket?.isTrial);
  // users can play premium content if they have a __still__ valid ticket
  // @note: AwaitingPayment status will be removed from the schema in the future, no need to check for it then
  const isValid = !!validUntil && ticket?.status !== TicketStatus.AwaitingPayment;

  return {
    isGiftCard,
    isExpired,
    isActive,
    isCanceled,
    validUntil,
    isWeekly,
    isMonthly,
    isYearly,
    isStripe,
    isValid,
    isTrial,
  };
};

/**
 * Return ticket status information derived from the current user’s ticket
 */
export default function useTicketStatus(): TicketStatusData & { isLoading: boolean } {
  const { data: sessionData, isLoading } = useSession();
  const ticket = sessionData?.currentUser?.ticket;

  return { ...getTicketStatus(ticket), isLoading };
}
