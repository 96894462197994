import { createContext, useContext } from 'react';

/**
 * The display context of a component, e.g. LandingPage, TicketsPage, etc.
 * add new context values below:
 */
export const TrackingContextValues = {
  LandingPage: 'Landing Page',
  LandingPageHero: 'Landing Page Hero',
  LandingPageSubscriptionPlans: 'Landing Page Subscription Plans',
  LandingPageUpcomingLiveConcert: 'Landing Page Upcoming Live Concert',
  MultiDevice: 'Multi Device',
  Releases: 'Releases',
  SliderGridPage: 'Slider Grid Page',
  SubscriptionModal: 'Subscription Modal',
  TicketsPage: 'Tickets Page',
  SignupModal: 'Signup Modal',
  SignupPage: 'Signup Page',
  LoginModal: 'Login Modal',
  LoginPage: 'Login Page',
  ForgotPasswordModal: 'Forgot Password Modal',
  ForgotPasswordPage: 'Forgot Password Page',
  SignupConfirmationRequiredModal: 'Signup Confirmation Required Modal',
  TopNavigation: 'Top Navigation',
  MobileNavigation: 'Mobile Navigation',
  SubscriptionConfirmedModal: 'Subscription Confirmed Modal',
  // default value when context is not set
  NotSet: 'Context not set',
} as const;

// convert the object to a union type that we can use in other types
export type TrackingContextValue = (typeof TrackingContextValues)[keyof typeof TrackingContextValues];

/**
 * TrackingContext provides the tracking context to its children.
 * e.g. TrackingContexts.LandingPage, TrackingContexts.TicketsPage, etc.
 * @param value the display context of the component
 * @example
 * <TrackingContext.Provider value={TrackingContexts.LandingPage}>
 *  <MyComponent />
 * </TrackingContext.Provider>
 */
export const TrackingContext = createContext<TrackingContextValue | undefined>(undefined);

/**
 * A hook to get the tracking context, throws an error if used outside of a TrackingContextProvider
 * @param enforceTrackingContext whether to throw an error if component is not wrapped in a TrackingContextProvider, defaults to `false`
 * @returns the tracking context
 * @example
 * const trackingContext = useTrackingContext();
 * trackEvent({ eventName: 'SubscriptionClick', list: trackingContext, ... });
 */
export const useTrackingContext = (enforceTrackingContext = false): TrackingContextValue => {
  const context = useContext(TrackingContext);
  if (enforceTrackingContext && context === undefined) {
    throw new Error('useTrackingContext must be used within a TrackingContextProvider');
  }
  return context || TrackingContextValues.NotSet;
};
