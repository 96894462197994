import { pushToGoogleTagManager } from 'src/tracking/gtm';

// favorite event types
export type FavoriteEventPayload = { eventName: 'FavoriteAdded' | 'FavoriteRemoved'; contentId: string; name?: string };

/**
 * Track favorites in analytics and GTM
 * based on the spec from:
 * https://guide.trakken.de/5f807081e90fd60009b13d1b/published/60829afcb4ea24000aab8185#chapter3.7.1
 */
export const trackFavorites = ({ eventName, contentId, name }: FavoriteEventPayload) => {
  const isFavoriting = eventName === 'FavoriteAdded';
  pushToGoogleTagManager({
    event: 'Generic Event',
    event_name: isFavoriting ? 'add_to_wishlist' : 'remove_from_wishlist',
    [isFavoriting ? 'add_to_wishlist' : 'remove_from_wishlist']: {
      item_name: name || '',
      item_id: contentId,
    },
  });
};
