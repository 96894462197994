import React, { ComponentProps } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import { SupportedLocale } from 'src/types';
import { transferImportantQueryParameters } from 'src/utilities/url-helpers';

type LinkProps = ComponentProps<typeof Link>;
type LegalLinkProps = Pick<LinkProps, 'children' | 'className' | 'target'>;

/**
 * A generic link to a legal page on the Stage+ website
 * @param pathname The path of the legal page {e.g. "/terms"}
 * @param hash The hash of the section of the legal page to link to {e.g. "6-instructions-on-rights-of-revocation"}
 * */
function LegalLinkBasic({ pathname, hash, ...restProps }: { pathname: string; hash?: string } & LegalLinkProps) {
  const { query } = useRouter();
  // Transfer the important query parameters to the legal page if needed
  const queryToCarry = transferImportantQueryParameters(query);
  return <Link {...restProps} href={{ pathname, query: queryToCarry, hash }} prefetch={false} />;
}
/**
 * A link to the Terms of Use page of the Stage+ website
 */
function LegalLinkTerms(props: LegalLinkProps) {
  return <LegalLinkBasic {...props} pathname="/terms" />;
}
/**
 * A link to the privacy policy page of the Stage+ website
 */
function LegalLinkPrivacy(props: LegalLinkProps) {
  return <LegalLinkBasic {...props} pathname="/privacy" />;
}
/**
 * A link to the notice on direct marketing page of the Stage+ website
 */
function LegalLinkNotice(props: LegalLinkProps) {
  return <LegalLinkBasic {...props} pathname="/notice-on-direct-marketing" />;
}
/**
 * A link to the direct marketing page of the Deutsche Grammophon website
 */
function DirectMarketingNoticeDg(props: LegalLinkProps) {
  return (
    <Link
      {...props}
      href="https://www.deutschegrammophon.com/en/terms-and-conditions/direct-marketing"
      prefetch={false}
    />
  );
}
/**
 * A link to the security and privacy page of the Deutsche Grammophon website
 */
function LegalLinkSecurityDg(props: LegalLinkProps) {
  return <Link {...props} href="https://sicherheitunddatenschutz.deutschegrammophon.com" prefetch={false} />;
}

// deep links to the withdrawal info for each locale
// e.g. for the https://www.stage-plus.com/terms#6-instructions-on-rights-of-revocation
const withdrawalSectionHash: Record<SupportedLocale, string> = {
  en: '6-instructions-on-rights-of-revocation',
  de: '6-widerrufsbelehrung',
  ja: '6-キャンセルする権利についての説明',
};

/**
 * A localized link to the Rights of Withdrawal, which is a section of the Terms and Conditions
 */
function LegalLinkWithdrawal(props: LegalLinkProps) {
  const currentLocale = useCurrentLocale();
  // Get the localized link to the Rights of Withdrawal for the current locale
  const localizedWithdrawalLink = withdrawalSectionHash[currentLocale];
  return <LegalLinkBasic {...props} pathname="/terms" hash={localizedWithdrawalLink} />;
}

/**
 * A map of all the legal links
 */
const LegalLinks = {
  Terms: LegalLinkTerms,
  Privacy: LegalLinkPrivacy,
  Notice: LegalLinkNotice,
  DirectMarketingDg: DirectMarketingNoticeDg,
  SecurityDg: LegalLinkSecurityDg,
  Withdrawal: LegalLinkWithdrawal,
};

export default LegalLinks;
