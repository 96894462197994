import { AudioType, VideoType, PlayableMedia, TrailerVideo } from 'src/types';
/**
 * A collection of useful functions for working with the playback queue.
 */

type QueueItem = {
  id: string;
};
const findItemId = <T extends QueueItem | undefined>({
  items,
  currentItemId,
  delta: advance,
}: {
  items?: T[];
  currentItemId?: string;
  delta: number;
}) => {
  // no items, no id
  if (!items?.length) {
    return;
  }
  // if there's no track or work selected yet, simply pick the first one
  if (!currentItemId) {
    return items[0];
  }
  // find out the index of the current track or work
  const currentItemIndex = items.findIndex((item) => item?.id === currentItemId);

  return items[currentItemIndex + advance];
};

/**
 * a helpful function to get the next track or work in a concert or album
 */
export const findNextItem = <T extends QueueItem | undefined>({
  items,
  currentItemId,
}: {
  items?: T[];
  currentItemId?: string;
}) => findItemId({ items, currentItemId, delta: 1 });
/**
 * a helpful function to get the previous track or work in a concert or album
 */
export const findPreviousItem = <T extends QueueItem | undefined>({
  items,
  currentItemId,
}: {
  items?: T[];
  currentItemId?: string;
}) => findItemId({ items, currentItemId, delta: -1 });

/**
 * get the id of the first track or work in the a concert or album
 * @param mediaSet - a track or a concert
 */
export const getFirstItemId = ({ mediaSet }: { mediaSet: PlayableMedia | undefined }) => {
  if (!mediaSet) {
    return;
  }
  if (mediaSet.__typename === 'Album') {
    return mediaSet.trackSets[0]?.tracks[0]?.id;
  }
  if ('performanceWorks' in mediaSet) {
    return mediaSet.performanceWorks[0]?.id;
  }
  return;
};

/**
 * get the trailer object of the media if possible
 * @returns
 */
export const getTrailerMedia = (media: AudioType | VideoType): TrailerVideo | undefined => {
  const trailerStream = 'trailerStream' in media ? media.trailerStream : undefined;
  if (!trailerStream) {
    return undefined;
  }
  return {
    __typename: 'Trailer',
    id: media.id,
    title: media.title,
    stream: trailerStream,
  };
};
