import React, {
  useRef,
  useState,
  useEffect,
  RefObject,
  createContext,
  createRef,
  useContext,
  useCallback,
} from 'react';

import usePlayerKeyboardControls from 'src/hooks/use-player-keyboard-controls';
import useStreaming, { PlaybackError } from 'src/hooks/use-streaming';
import useVideoTextTracks from 'src/hooks/use-video-text-tracks';
import { usePlayback } from 'src/state/playback';
import { noop } from 'src/utilities/noop';

export type VideoSeekHandler = (targetTime: number | ((currentTime: number) => number)) => void;

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
enum MediaElementReadyState {
  HAVE_NOTHING,
  HAVE_METADATA,
  HAVE_CURRENT_DATA,
  HAVE_FUTURE_DATA,
  HAVE_ENOUGH_DATA,
}

type SiteVideoContextType = {
  videoRef: RefObject<HTMLVideoElement>;
  isPlaying: boolean;
  isLoading: boolean;
  play: () => void;
  pause: () => void;
  togglePlay: () => void;
  load: () => void;
  seek: VideoSeekHandler;
  seekRelative: (relativeSeekPosition: number) => void;
  playbackError: PlaybackError | undefined;
  levels: ReturnType<typeof useStreaming>['levels'] | undefined;
  audio: ReturnType<typeof useStreaming>['audio'] | undefined;
  textTracks: TextTrack[];
};

/** A globally used video hook that gives access to the same video tag.
 * This allows us to pass through the user interaction blocks on Safari
 * which otherwise prevent programmatic video playback initialization.
 *
 **/
export const useGlobalVideo = (): SiteVideoContextType => {
  // a reference to the video tag
  const videoRef = useRef<HTMLVideoElement>(null);
  // an internal playback state
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { currentStream, skipToNext, clearQueue } = usePlayback();

  const streamUrl = currentStream?.url;

  // enable HLS streaming for this video element
  // the stream returns different resolutions for now, the return will evolve in the future depending on the UI needs
  const { levels, playbackError, audio } = useStreaming({
    videoElRef: videoRef,
    stream: currentStream,
  });

  // text tracks / subtitles of the current video context
  const textTracks = useVideoTextTracks({ videoElRef: videoRef, videoURL: streamUrl });

  const play = useCallback(() => {
    // the play method returns a promise,
    // for now we choose to ignore when it has failed e.g. because of prevented autoplay
    // to solve the failed autoplay, you might decide to highlight the play button visually
    // to motivate the user to click on it
    videoRef.current?.play().catch(noop);
  }, []);

  const pause = useCallback(() => {
    videoRef.current?.pause();
  }, []);

  const togglePlay = useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  }, [isPlaying, pause, play]);

  /**
   * Initialize the video element,
   * it is used to prepare the video for playback on click,
   * e.g. to avoid the autoplay blocking in Safari/iOS
   */
  const load = useCallback(() => {
    videoRef.current?.load();
  }, []);

  /**
   * Seek to a specific time in the video
   * @param targetTime the time to seek to in seconds, can be a number
   * or a function that calculates the time based on the current video position
   */
  const seek = useCallback(
    (targetTime: number | ((currentTime: number) => number)) => {
      const video = videoRef.current;
      if (!video) {
        return;
      }
      // allow the callee to calculate the seek time if needed
      const timeToJumpTo = typeof targetTime === 'function' ? targetTime(video.currentTime) : targetTime;
      // make sure the tag has some duration before seeking
      if (video.duration > 0) {
        // advance to the seek position, make sure we stay within bounds of possible timeline
        const limitedSeekTime = Math.min(Math.max(timeToJumpTo, 0), video.duration || 0);
        video.currentTime = limitedSeekTime;
      }
    },
    [videoRef],
  );

  /**
   * Seek to a specific time in the video
   * @param relativeSeekPosition is a number between 0 and 1
   */
  const seekRelative = useCallback(
    (relativeSeekPosition: number) => {
      const video = videoRef.current;
      if (!video) {
        return;
      }
      seek(video.duration * relativeSeekPosition);
    },
    [videoRef, seek],
  );

  // listen to the video object changes and update the global state
  const onVideoStatusUpdate = useCallback(
    async (event: Event) => {
      const video = event.target as HTMLVideoElement;
      // check if the video is playing
      setIsPlaying(!video.paused);
      // check if the video can be played
      setIsLoading(video.readyState < MediaElementReadyState.HAVE_FUTURE_DATA.valueOf());
      // when the video starts/resumes playing
      // when the video has finished playing
      if (event.type === 'ended') {
        // skip to the next one in the queue automatically
        const nextId = skipToNext();
        // if there was no next video in the queue
        if (!nextId) {
          // leave the player url
          await clearQueue();
        }
      }
    },
    [skipToNext, clearQueue],
  );

  // allow keyboard controls for this video element
  usePlayerKeyboardControls({ videoRef: videoRef, seek, play, pause });

  const videoElement = videoRef.current;
  useEffect(() => {
    // observe all video element status changes
    if (videoElement) {
      videoElement.addEventListener('play', onVideoStatusUpdate);
      videoElement.addEventListener('pause', onVideoStatusUpdate);
      videoElement.addEventListener('waiting', onVideoStatusUpdate);
      videoElement.addEventListener('canplay', onVideoStatusUpdate);
      videoElement.addEventListener('ended', onVideoStatusUpdate);
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('play', onVideoStatusUpdate);
        videoElement.removeEventListener('pause', onVideoStatusUpdate);
        videoElement.removeEventListener('waiting', onVideoStatusUpdate);
        videoElement.removeEventListener('canplay', onVideoStatusUpdate);
        videoElement.removeEventListener('ended', onVideoStatusUpdate);
      }
    };
  }, [videoElement, onVideoStatusUpdate]);

  return {
    videoRef,
    isPlaying,
    isLoading,
    play,
    pause,
    togglePlay,
    load,
    seek,
    seekRelative,
    playbackError,
    levels,
    audio,
    textTracks,
  };
};

const initialContext: SiteVideoContextType = {
  videoRef: createRef(),
  isPlaying: false,
  isLoading: false,
  play: noop,
  pause: noop,
  togglePlay: noop,
  load: noop,
  seek: noop,
  seekRelative: noop,
  playbackError: undefined,
  levels: undefined,
  audio: undefined,
  textTracks: [],
};

const VideoContext = createContext<SiteVideoContextType>(initialContext);

/**
 * The video context provider gives all descendant components access to the global video element and its controls
 * @param children - the components that will have access to the video context
 * @component
 */
export const VideoContextProvider = ({ children }: { children: React.ReactNode }) => {
  // the instance of the global player video element
  const globalVideo = useGlobalVideo();
  // Note: subtitles are inserted into the video element from the HLS stream
  return (
    <VideoContext.Provider value={globalVideo}>
      <div className="fixed size-1"></div>
      {children}
    </VideoContext.Provider>
  );
};

/**
 * allows the descendants of VideoContextProvider to access the global video element and its controls
 * @returns SiteVideoContextType
 */
export function useVideoContext(): SiteVideoContextType {
  const videoContext = useContext(VideoContext);
  if (videoContext === undefined) {
    throw new Error(`useVideoContext must be used within a VideoContextProvider`);
  }
  return videoContext;
}
