import Cookies from 'universal-cookie';

export const ACCESS_TOKEN_KEY = 'dgplus_access_token';
export const REFRESH_TOKEN_KEY = 'dgplus_refresh_token';

export type Token = string | undefined;

const cookies = new Cookies();

/**
 * We use cookies and localStorage to persist the `accessToken`, and `refreshToken`
 * in the browser respectively so they can be used by the client to authenticate
 * API requests. Since the cookie is not HTTP-only, it can be used by not only our
 * own client, but also any other external scripts that are loaded on the same
 * domain. The same goes for localStorage. This is a known security vulnerability,
 * but we accept it for now.
 */

/**
 * Retrieve the access token from the `ACCESS_TOKEN_KEY` cookie
 * @returns The token or undefined if it doesn't exist
 */
export function getAccessToken(): Token {
  return cookies.get(ACCESS_TOKEN_KEY) as Token;
}

/**
 * Store the access token in the `ACCESS_TOKEN_KEY` cookie
 * @param token The token to store
 */
export function saveAccessToken(token: string): void {
  /**
   * Expiry date of 1 year from now.
   * @todo Either use the `expires_in` value returned by the OAuth server or
   *       parse the `expires_in` value from the token.
   */
  const maxAge = 365 * 24 * 60 * 60;
  cookies.set(ACCESS_TOKEN_KEY, token, { path: '/', maxAge });
}

/**
 * Destroy the `ACCESS_TOKEN_KEY` cookie
 */
export function destroyAccessToken(): void {
  cookies.remove(ACCESS_TOKEN_KEY, { path: '/' });
}

/**
 * Retrieve the refresh token from the `REFRESH_TOKEN_KEY` localStorage key
 * @returns The token or undefined if it doesn't exist
 */
export function getRefreshToken(): Token {
  if (typeof window === 'undefined') return;
  return window.localStorage.getItem(REFRESH_TOKEN_KEY) as Token;
}

/**
 * Store the refresh token in the `REFRESH_TOKEN_KEY` localStorage key
 * @param token The token to store
 */
export function saveRefreshToken(token: string): void {
  if (typeof window === 'undefined') return;
  window.localStorage.setItem(REFRESH_TOKEN_KEY, token);
}

/**
 * Destroy the `REFRESH_TOKEN_KEY` localStorage key
 */
export function destroyRefreshToken(): void {
  if (typeof window === 'undefined') return;
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
}

type DecodedJwt = {
  raw: string;
  header?: {
    jku: string; // e.g. "https://audience.api.stageplus.io/jwt/jwks.json"
    alg: string; // "RS512"
    kid: string; // "d1hQ68nTXrp6NuUE6w0RTGn7_dvwY0Vi1dshx6SR4fB"
    typ: string; // "JWT"
  };
  payload?: {
    // the Resource Servers that should accept this token
    aud: string;
    //
    dev: string;
    // the time when the token will expire, unix timestamp
    exp: number;
    // the time when the token was issued, unix timestamp
    iat: number;
    // the issuer of the token e.g. "dg_stage_audience_api",
    iss: string;
    // the unique identifier of the token
    jti: string;
    // the time when the token will become valid, unix timestamp
    nbf: number;
    // the user id
    sub: string;
    // the type of token, e.g. "access_token"
    typ: string;
    // ticket validity time, unix timestamp
    tvt?: string;
  };
};

/**
 * Attempt to decode a JWT token into its header and payload.
 * @param tokenString string containing the jwt token
 * @returns an object containing the raw token, header, and payload data
 */
function decodeAccessToken(tokenString: string): DecodedJwt {
  const splitToken = tokenString.split('.');
  if (tokenString.length < 2) {
    return { raw: tokenString };
  }
  try {
    const header = JSON.parse(window.atob(splitToken[0])) as DecodedJwt['header'];
    const payload = JSON.parse(window.atob(splitToken[1])) as DecodedJwt['payload'];
    return {
      raw: tokenString,
      header,
      payload,
    };
  } catch {
    return { raw: tokenString };
  }
}

/**
 * Try to get the user id from the access token
 * @param tokenString
 * @returns the user id or undefined if it can't be found
 */
export function getUserIdFromToken(tokenString: string): string | undefined {
  const decodedToken = decodeAccessToken(tokenString);
  return decodedToken.payload?.sub ?? undefined;
}
