// Custom Types for the Consent Manager library

import { useEffect } from 'react';

// https://help.consentmanager.net/books/cmp/page/cmp-events
type CMPEventName =
  | 'init'
  | 'settings'
  | 'consentscreen'
  | 'consentscreenoff'
  | 'consentscreencustom'
  | 'consent'
  | 'consentrejected'
  | 'consentapproved'
  | 'consentcustom'
  | 'liestablished'
  | 'vendorconsent';
type CMPEventSetup = [CMPEventName, (eventname: CMPEventName) => void, boolean];

// A list of the vendors the user has given consent to, e.g. { 's905': true, 'c33490': true }
// more https://help.consentmanager.net/books/cmp/page/javascript-api
type VendorConsentList = Record<string, boolean>;

type CmpData = {
  vendorConsents?: VendorConsentList;
};

type TypedCmp = {
  description: string;
  // window.__cmp provides different signatures for different uses
  (command: 'getCMPData'): CmpData;
  (command: 'showScreen'): void;
  (command: 'close'): void;
  (command: 'addEventListener' | 'removeEventListener', parameter: CMPEventSetup, x: null): void;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    __cmp?: TypedCmp;
  }
}

/**
 * The Consent Manager vendor IDs for the services we use
 * The vendor IDs are defined in the Consent Manager dashboard (if you have access)
 * see https://www.consentmanager.net/
 * Otherwise, you can find the vendor ID in the Consent Manager object in the browser console
 * by running `window.__cmp('getCMPData')`and inspecting the vendorConsents object
 **/
export enum Vendor {
  GoogleTagManager = 's905',
  Mux = 'c33490',
  Sentry = 's2522',
  Mixpanel = 'c40705',
  FacebookMeta = 's7',
}

function getCMPData(): CmpData | undefined {
  return typeof window === 'undefined' ? undefined : window.__cmp?.('getCMPData');
}

/**
 * Checks if the user has given consent for the given vendor.
 * @param vendorId The vendor ID of the service to check
 * @returns `true` if the user has given consent, `false` otherwise
 */
export const checkVendorConsent = (vendorId: Vendor): boolean => {
  const vendorConsents = getVendorConsents();
  if (vendorConsents?.[vendorId]) {
    // Vendor with the given id has consent
    return true;
  }
  return false;
};

/**
 * Returns the list of vendors the user has given consent to.
 */
export const getVendorConsents = (): VendorConsentList | undefined => {
  const cmpData = getCMPData();
  return cmpData?.vendorConsents;
};

/**
 * Adds a listener to the consent manager that calls the given callback when the user changes their consent.
 * @param callback
 * @returns
 */
export const onConsentChange = (callback: (vendorList?: VendorConsentList) => void): void => {
  if (!window.__cmp) {
    return;
  }
  const handleConsent = () => {
    callback(getVendorConsents());
  };
  window.__cmp('addEventListener', ['consent', handleConsent, false], null);
};

/**
 * A React hook that calls the given callback when the user changes their consent.
 * The callback will be triggered also on the first render, when the consent is read from the CMP cookies.
 * @param callback - A callback function that will be called on every consent change,
 * with a list of the vendors the user has given consent to
 */
export const useConsentManagerChange = (callback: () => void | Promise<void>): void => {
  useEffect(() => {
    // wait for the CMP changes and call the callback
    window.__cmp?.('addEventListener', ['consent', callback, false], null);
    return () => {
      // remove the listener when the component is unmounted
      window.__cmp?.('removeEventListener', ['consent', callback, false], null);
    };
  }, [callback]);
};
