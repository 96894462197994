// the default configuration for the next-translate plugin
// see docs https://github.com/aralroca/next-translate#3-configuration
const index18Config = {
  /** @type { import("./src/types").SupportedLocale[] } */
  locales: ['en', 'de', 'ja'],
  /** @type { import("./src/types").SupportedLocale } */
  defaultLocale: 'en',
  defaultNS: 'common',
  // disable NextJS locale detection as it is causing redirect loops when used together with the middleware locale routing
  localeDetection: false,
  pages: {
    // the default namespace is common.json
    // we are also loading the A/B tests namespace for all pages
    '*': ['common', 'abtesting'],
  },
  /**
   * Each page has a log indicating: namespaces, current language and method used to load the namespaces.
   * By default this is `true` which results in unnecessary logs in the console during the build step.
   */
  logBuild: process.env.NODE_ENV !== 'production',
};

module.exports = index18Config;
