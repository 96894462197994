import { RefObject, useEffect, useState } from 'react';

type Arguments = {
  freezeOnceVisible?: boolean;
} & IntersectionObserverInit;

/**
 * The useIntersectionObserver hook is a React hook that notifies when an element is in view.
 * It is much more performant than the scroll event listener.
 * from https://usehooks-ts.com/react-hook/use-intersection-observer
 * @param elementRef The element to observe
 * @param options The options to pass to the intersection observer
 * @returns
 */
function useIntersectionObserver<T extends HTMLElement>(
  elementRef: RefObject<T>,
  { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false }: Arguments,
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  // threshold might be a deeper object so we make sure to update the hook if it changes
  const thresholdString = JSON.stringify(threshold);

  useEffect(() => {
    // the element we will be observing
    const node = elementRef.current;
    // verify that IntersectionObserver is available in this browser
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParameters = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParameters);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, thresholdString, root, rootMargin, frozen, threshold]);

  return entry;
}

export default useIntersectionObserver;
