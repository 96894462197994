import { useCallback } from 'react';
import type Hls from 'hls.js';
import type { Options as MuxEmbedOptions } from 'mux-embed';
import { env } from 'src/config';
import useCurrentUser from 'src/hooks/use-current-user';
import { enableStreamDebug, PlaybackError } from 'src/hooks/use-streaming';
import { usePlayback } from 'src/state/playback';
import { checkVendorConsent, Vendor } from 'src/utilities/consent-manager';
import { hlsPlaybackSupport } from 'src/utilities/streaming-helpers';

const MUX_KEY = env.NEXT_PUBLIC_MUX_KEY;
const MUX_PLAYER_NAME = 'Web Player';

// the types of streams we are reporting to Mux.com
type MuxStreamType = 'live' | 'on-demand';

// load the mux-embed library dynamically, to save on initial bundle size
const dynamicallyLoadMuxEmbed = async () => {
  const muxDynamic = await import('mux-embed');
  return muxDynamic.default;
};

/**
 * Initialize the Mux.com monitoring for the video element
 * @param videoElement - the video element that is playing the stream
 * @param currentMedia - the media that is currently playing
 * @param playbackMethod - the playback method that is used to play the stream
 */
const setUpMuxMonitoring = async ({
  videoElement,
  containerMedia,
  trackMedia,
  viewerId,
  hlsInstance,
  hlsConstructor,
  videoStreamType,
}: {
  videoElement: HTMLVideoElement;
  containerMedia: { id: string; title: string; __typename: string; isrc?: string; uspc?: string; trailerIsrc?: string };
  trackMedia?: { id: string; title: string; __typename: string; isrc?: string };
  viewerId?: string;
  hlsInstance?: MuxEmbedOptions['hlsjs'];
  hlsConstructor?: MuxEmbedOptions['Hls'];
  videoStreamType: MuxStreamType;
}) => {
  let mediaId = trackMedia?.id || containerMedia.id;
  const seriesId = containerMedia.id;
  // check if we have a isrc or uspc value for the media
  // this should allow to trace back the CDN/original source of the media
  let customId1 =
    // video media has `isrc`
    ('isrc' in containerMedia ? containerMedia.isrc : undefined) ??
    // album media has `uspc`
    ('uspc' in containerMedia ? containerMedia.uspc : undefined);
  // try to get the isrc value from the track media
  let customId2 = trackMedia?.isrc;
  // combine the container media title and the track media title in one string, with a '|' separator for easier reading
  let mediaTitle = [containerMedia?.title?.slice(0, 80), trackMedia?.title?.slice(0, 120)].filter(Boolean).join('|');

  // if the media is a trailer, we have to adjust the id and title so it can be recognized by Mux.com
  if (containerMedia.__typename === 'Trailer') {
    mediaId = `trailer_${containerMedia.id}`;
    mediaTitle = `Trailer : ${containerMedia.title}`;
    // trailers have a different isrc value from the main media
    customId1 = 'trailerIsrc' in containerMedia ? containerMedia.trailerIsrc : undefined;
    customId2 = undefined;
  }
  //  check if we are already tracking the video
  if (videoElement.mux) {
    videoElement.mux?.emit('videochange', {
      video_id: mediaId,
      video_series: seriesId,
      video_title: mediaTitle,
      video_stream_type: videoStreamType,
      custom_1: customId1,
      custom_2: customId2,
    });
    return;
  }
  // use the dynamically loaded mux-embed library to save on initial bundle size
  const mux = await dynamicallyLoadMuxEmbed();

  const options: MuxEmbedOptions = {
    debug: enableStreamDebug,
    hlsjs: hlsInstance,
    Hls: hlsConstructor,
    data: {
      env_key: MUX_KEY,
      // Metadata fields
      player_name: MUX_PLAYER_NAME,
      player_software_name: hlsInstance ? 'hls.js' : 'html5',
      // Video Metadata
      video_id: mediaId,
      video_series: seriesId,
      video_title: mediaTitle,
      video_stream_type: videoStreamType,
      viewer_user_id: viewerId ?? 'anonymous',
      // Custom Metadata
      custom_1: customId1, // isrc or uspc of the main media
      custom_2: customId2, // isrc of the track/performance work media
    },
  };
  // monitor the video element performance with mux.com analytics
  mux.monitor(videoElement, options);
};

/**
 * Log a playback error to Mux.com
 * @param videoElement - the video element that is playing the stream
 * @param playbackError - the error that occurred
 */
export const logPlaybackErrorInMux = async (videoElement: HTMLVideoElement, playbackError: PlaybackError) => {
  // use the dynamically loaded mux-embed library to save on initial bundle size
  const mux = await dynamicallyLoadMuxEmbed();
  // log the error to mux.com
  mux.emit(videoElement, 'error', {
    player_error_code: playbackError.code,
    player_error_message: playbackError.message,
    player_error_context: playbackError.errorType,
  });
};

/**
 * Mux.com provides video analytics and monitoring for our video streams.
 * This hooks initializes the Mux.com data connection
 * each time the video element is mounted and the video URL changes.
 **/
const useMuxVideoTracking = () => {
  const { currentMedia, currentActiveTrack, currentActiveWork } = usePlayback();
  const { currentUser } = useCurrentUser();
  // get the current user id and scope it here so it doesn't change on each page render
  const viewerId = currentUser?.id;
  // initialize the Mux.com monitoring when the video element is mounted

  const trackWithMux = useCallback(
    async ({
      videoElement,
      videoURL,
      playbackMethod,
      hlsInstance,
      hlsConstructor,
    }: {
      videoElement: HTMLVideoElement | null;
      videoURL?: string;
      playbackMethod: ReturnType<typeof hlsPlaybackSupport>;
      hlsInstance?: Hls;
      hlsConstructor?: typeof Hls;
    }) => {
      // check if the user has provided a consent for the Mux.com service
      const userConsent = checkVendorConsent(Vendor.Mux);

      // it's a different stream type when playing a live concert
      const videoStreamType: MuxStreamType = currentMedia?.__typename === 'LiveConcert' ? 'live' : 'on-demand';

      // if user has not provided a consent, we can't track the video
      // if there is no video element or stream URL set, we can't track the video
      if (!userConsent || !videoElement || !videoURL || !currentMedia) {
        return;
      }
      // check if the HLS.js instance is provided as a prop
      if (playbackMethod === 'hlsJs' && !hlsInstance) {
        console.warn('useMuxTracking: HLS.js instance is not provided');
        return;
      }
      // normalize the track and work media to be recognized by Mux.com
      const trackMedia = currentActiveWork
        ? {
            id: currentActiveWork?.id ?? '',
            title: currentActiveWork?.work?.title ?? '',
            isrc: currentActiveWork?.isrc,
            __typename: currentActiveWork?.__typename ?? '',
          }
        : currentActiveTrack;

      // now we have everything needed to initialize the Mux.com monitoring
      return setUpMuxMonitoring({
        videoElement,
        containerMedia: currentMedia,
        trackMedia,
        videoStreamType,
        viewerId,
        hlsInstance,
        hlsConstructor,
      });
    },
    [currentMedia, currentActiveTrack, currentActiveWork, viewerId],
  );

  return trackWithMux;
};

export default useMuxVideoTracking;
