import { useRouter } from 'next/router';
import { SupportedLocale } from 'src/types';
import { getCurrentLocale } from 'src/utilities/i18n-helpers';

/**
 * Returns the current locale based on the next/router locale.
 * When the router locale is not available, it returns the default locale from the locales config.
 * @returns a locale string
 * @example
 * ```ts
 * const locale = useCurrentLocale();
 * // locale === 'de'
 * ```
 */
export function useCurrentLocale(): SupportedLocale {
  const { locale } = useRouter();

  const currentLocale = getCurrentLocale(locale);
  return currentLocale;
}
