import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type DevelopmentToolsState = {
  /**
   * Whether to show the grid overlay
   * @default false
   */
  gridOverlayActive: boolean;
  /**
   * Whether to show the Phrase translation editor
   * @default false
   */
  phraseEditorActive: boolean;
  /**
   * Toggle the grid overlay between active and inactive
   */
  toggleGridOverlay: () => void;
  /**
   * Toggle the phrase editor between active and inactive
   */
  togglePhraseEditor: () => void;
};

const storageKey = 'developmentToolsState.zustand';

/**
 * Provide access to the locally stored development tools settings of the current user,
 * e.g. if the user has enabled or disabled the grid overlay
 */
export const useDevelopmentTools = create(
  persist<DevelopmentToolsState>(
    (set) => ({
      gridOverlayActive: false,
      phraseEditorActive: false,
      toggleGridOverlay: () => set((state) => ({ ...state, gridOverlayActive: !state.gridOverlayActive })),
      togglePhraseEditor: () => set((state) => ({ ...state, phraseEditorActive: !state.phraseEditorActive })),
    }),
    {
      name: storageKey,
      storage: typeof window === 'undefined' ? undefined : createJSONStorage(() => sessionStorage),
      version: 2,
    },
  ),
);
