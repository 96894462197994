// list of the different measurements, helps to keep track of what's being measured
type MeasureType = 'playerInitialization';
type MarkType = 'playerRequested' | 'playbackStarted';

/**
 * The function is used to mark a specific timing event, e.g. user click or playback start
 * @param markName - the name of the mark
 * @param markOptions - the options of the mark, see https://developer.mozilla.org/en-US/docs/Web/API/Performance/mark
 */
export const setTimingMark = (markName: MarkType, markOptions?: PerformanceMarkOptions) =>
  performance.mark(markName, markOptions);

/**
 * The function is used to record the results of a measurement, usually between two marks
 * @param type - the type of the supported measurement
 * @returns
 */
export const measureTiming = (type: MeasureType) => {
  switch (type) {
    case 'playerInitialization': {
      // measure the time between the moment when the player was requested and the moment when the playback started
      return recordMeasurement('playerInitialization', 'playerRequested', 'playbackStarted');
    }
    default: {
      return null;
    }
  }
};

// the prefix is used to distinguish the marks and measures created by our app from the ones created by the browser or Next.js
const measureNamePrefix = 'dgStageMeasure:';

// records a measurement between two marks
const recordMeasurement = (telemetry: MeasureType, startMark: MarkType, endMark: MarkType) => {
  // a specific name to identify the measurement in the performance timeline
  // e.g. https://blog.bitsrc.io/using-the-performance-web-api-with-chrome-devtools-f4c59564b3d4
  const measureName = `${measureNamePrefix}${telemetry}`;
  // in some cases (e.g. when some action was started with a keyboard event)
  // one of the marks might not be available, the measure would fail in that case
  try {
    // https://developer.mozilla.org/en-US/docs/Web/API/Performance/measure
    return performance.measure(measureName, startMark, endMark);
  } catch {
    // do nothing
    return null;
  }
};
