import { phraseAppConfig } from 'src/components/development-tools/phrase-editor';
import { useDevelopmentTools } from 'src/hooks/use-development-tools';
import useHasMounted from 'src/hooks/use-has-mounted';
import type { TranslationKey } from 'src/types';

type PhraseEditorKeyHook = ((translationKey: TranslationKey) => string) | undefined;

/**
 * Converts a translation key to a key that can be used in the phrase editor
 * @param translationKey The translation key to convert
 * @returns The phrase editor key
 *
 * @example
 * ```tsx
 * getPhraseEditorKey('hello_world') // => '{{__phrase_hello_world__}}'
 * ```
 */
function getPhraseEditorKey(translationKey: TranslationKey) {
  return `${phraseAppConfig.prefix}phrase_${translationKey}${phraseAppConfig.suffix}`;
}

/**
 * Returns a function that converts a translation key to a key that can be used by the phrase editor
 *
 * If the phrase editor is not enabled, this hook will return undefined.
 *
 * @example
 * ```tsx
 * const phraseEditorKey = usePhraseEditorKey();
 *
 * if (phraseEditorKey) {
 *  const key = phraseEditorKey('my_translation_key');
 *  // key === '{{__phrase_my_translation_key__}}'
 * }
 * ```
 */
export default function usePhraseEditorKey(): PhraseEditorKeyHook {
  const { showPhraseEditor } = useDevelopmentTools((state) => ({
    showPhraseEditor: state.phraseEditorActive,
  }));
  // Only show the phrase editor after the component has mounted to avoid SSR mismatches
  const hasMounted = useHasMounted();

  return hasMounted && showPhraseEditor ? getPhraseEditorKey : undefined;
}
