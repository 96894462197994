import type { SVGProps } from 'react';
const SvgLanguage = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.108 10.045c-.41-.813-1.138-1.428-2.067-1.758.083-.267.174-.552.234-.7l-1.547-.412a7 7 0 0 1-.108.844 6 6 0 0 0-.231-.005c-.615 0-1.268.087-1.877.24.025-.417.058-.835.098-1.24 1.553-.08 3.236-.255 4.458-.499l-.013-1.58c-1.371.362-2.73.53-4.262.595q.06-.402.124-.747l.033-.146c.04-.181.084-.378.143-.582L5.46 4c.012.247-.013.564-.038.838l-.04.382-.035.345-.504.002c-.767 0-1.861-.11-2.314-.179l.038 1.581.564.03c.545.03 1.192.066 1.675.066q.194 0 .399-.003c-.048.556-.09 1.137-.117 1.715C3.328 9.687 2 11.531 2 13.306c0 1.402.767 2.007 1.685 2.007.674 0 1.348-.23 1.957-.56q.077.301.156.56l1.446-.468a24 24 0 0 1-.284-1.027c.967-.877 1.966-2.316 2.647-4.174.857.364 1.31 1.096 1.31 1.916 0 1.361-.994 2.694-3.421 2.983L8.339 16a6.6 6.6 0 0 0 1.825-.565zm-8.624 2.903c0-.773.63-1.828 1.577-2.528.022.957.12 1.905.259 2.758-.463.296-.905.458-1.257.458-.415 0-.579-.234-.579-.688m2.98-3.202c0 .848.056 1.6.149 2.283a8.3 8.3 0 0 0 1.59-2.626 5.7 5.7 0 0 0-1.738.311v.032ZM13.59 20H11l3.946-11h3.114L22 20h-2.59l-.846-2.508h-4.127zm2.958-8.486 1.405 4.162h-2.9l1.406-4.162h.09Z"
    />
  </svg>
);
export default SvgLanguage;
