import { ImageLoaderProps } from 'next/image';
import { env } from 'src/config';
import { getAbsoluteUrl } from 'src/utilities/url-helpers';
const CLOUD_NAME = env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;

type SupportedImageFormats = 'auto' | 'webp' | 'avif' | 'jpg' | 'png';
type SupportedAspectRatios = '1:1' | '16:9';
type SupportedQualities = number | 'auto' | 'auto:eco' | 'auto:good' | 'auto:best';
type SupportedCrops = 'limit' | 'fill';

type LoaderProps = Pick<ImageLoaderProps, 'src' | 'width'> & {
  quality?: SupportedQualities;
  format?: SupportedImageFormats;
  aspectRatio?: SupportedAspectRatios;
  crop?: SupportedCrops;
};

const baseUrl = `https://res.cloudinary.com/${CLOUD_NAME}/image/fetch/`;

/**
 * Default image quality to use for Cloudinary images
 * Can be a number or one of the automatic quality settings (auto, auto:eco, auto:good, auto:best, etc.)
 * @see https://cloudinary.com/documentation/image_optimization#automatic_quality_selection_q_auto
 */
function stripFirstSlash(source: string): string {
  return source.startsWith('/') ? source.slice(1) : source;
}

/**
 * Returns a Cloudinary image url for the given parameters and image url
 * @param params Array of Cloudinary transformations to use (e.g. 'f_auto') – see https://cloudinary.com/documentation/image_transformations
 * @param url Image url to use
 * @returns Cloudinary image url
 *
 * @example
 * ```ts
 * getCloudinaryUrl(['f_auto', 'c_limit', 'w_1000', 'q_auto:eco'], 'https://example.com/image.jpg')
 * // => https://res.cloudinary.com/your-cloud-name/image/fetch/f_auto,c_limit,w_1000,q_auto:eco/https://example.com/image.jpg
 * ```
 */
function getCloudinaryUrl(params: (string | null)[], url: string): string {
  const paramsString = params.filter((parameter) => !!parameter).join(',') + '/';
  return `${baseUrl}${paramsString}${stripFirstSlash(url)}`;
}

/**
 * Returns a Cloudinary image in the correct size and quality
 *
 * Note: local image URLs (like `/images/tacocat.jpg`) will use Cloudinary
 * loading only in production environment, converting automatically to absolute URLs
 */
export function cloudinaryLoader({
  src,
  width,
  quality = 'auto:eco', // use auto:eco as it produces the best balance between quality and load performance for the Stage+ content,
  format = 'auto', // by default, Cloudinary will use the best format for the given image
  crop = 'limit', // by default, use the crop mode of the original image
  aspectRatio, // by default, use the aspect ratio of the original image
}: LoaderProps): string {
  // Enforce SVG format to avoid rasterization of SVG image
  const imageFormat = src.endsWith('.svg') && format === 'auto' ? 'svg' : format;

  const options = [
    `f_${imageFormat}`,
    `c_${crop}`,
    aspectRatio ? `ar_${aspectRatio}` : null,
    `w_${width}`,
    `q_${quality}`,
  ];

  if (src.startsWith('/')) {
    // Handle relative images:
    // <Image src="/images/tacocat.jpg" loader={cloudinaryLoader}/>
    return process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
      ? // Convert the URL to absolute in production
        getCloudinaryUrl(options, getAbsoluteUrl(src))
      : // Skip the loader otherwise
        src;
  }

  return getCloudinaryUrl(options, src);
}

/**
 * Returns a square Cloudinary image in the correct size and quality
 */
export function cloudinaryLoaderSquare(loadProps: Omit<LoaderProps, 'aspectRatio' | 'crop'>): string {
  return cloudinaryLoader({ ...loadProps, crop: 'fill', aspectRatio: '1:1' });
}

/**
 * Returns a wide Cloudinary image in the correct size and quality
 */
export function cloudinaryLoaderWide(loadProps: Omit<LoaderProps, 'aspectRatio' | 'crop'>): string {
  return cloudinaryLoader({ ...loadProps, crop: 'fill', aspectRatio: '16:9' });
}
