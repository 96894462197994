import { withSentryConfig } from '@sentry/nextjs';

/**
 * Sentry webpack options
 * @see https://github.com/getsentry/sentry-webpack-plugin#options
 * @type {Partial<SentryWebpackPluginOptions>}
 */
const sentryWebpackPluginOptions = {
  // For all available options, see:
  // https://github.com/getsentry/sentry-webpack-plugin#options
  // pass the sentry credentials to the webpack plugin responsible for the source-maps
  org: process.env.SENTRY_ORG,
  project: process.env.SENTRY_PROJECT,
  authToken: process.env.SENTRY_AUTH_TOKEN,
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
  // Suppresses source-map related logs during build
  silent: true,
};

/**
 * Sentry options
 * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/
 * @type {Partial<UserSentryOptions>}
 */
const sentryOptions = {
  // Upload a larger set of source maps for prettier stack traces (increases build time)
  widenClientFileUpload: true,

  // Hides source maps from generated client bundles
  hideSourceMaps: true,

  // Automatically tree-shake Sentry logger statements to reduce bundle size
  disableLogger: true,
};

// check if sentry is configured
const sentryIsConfigured = !!process.env.SENTRY_ORG && !!process.env.SENTRY_PROJECT && !!process.env.SENTRY_AUTH_TOKEN;

// get the environment, keep in mind these values will be overwritten during the build process on the server
const environment = process.env.NEXT_PUBLIC_VERCEL_ENV;

// check if the app is in mock mode - running e2e tests
const isMocked = process.env.NEXT_PUBLIC_MOCK_API === 'true';

// enable sentry error reporting only in production or preview environment
export const enabledSentryEnvironment = environment !== 'development' && !isMocked;

export const withSentry = (nextConfig) =>
  /** Only load Sentry when configured (not in test environment) */
  sentryIsConfigured ? withSentryConfig(nextConfig, { ...sentryOptions, ...sentryWebpackPluginOptions }) : nextConfig;
