import type { SVGProps } from 'react';
const SvgFavoriteOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M18.563 9.453 14.57 8.88l-1.804-3.637c-.301-.629-1.23-.656-1.559 0L9.43 8.88l-4.02.574c-.71.11-.984.985-.465 1.504l2.871 2.816-.683 3.965c-.11.711.656 1.258 1.285.93L12 16.781l3.555 1.887c.629.328 1.394-.219 1.285-.93l-.684-3.965 2.871-2.816c.52-.52.246-1.394-.465-1.504m-3.829 3.856.657 3.773L12 15.305l-3.418 1.777.656-3.773-2.761-2.68 3.8-.547L12 6.637l1.695 3.445 3.801.547z"
    />
  </svg>
);
export default SvgFavoriteOutline;
