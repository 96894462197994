import { useCallback } from 'react';
import { ClientError } from 'graphql-request';
import useSWR from 'swr';
import { Coupon } from 'generated/graphql';
import { useCouponCodeStore } from 'src/hooks/use-coupon-code-store';
import useSdk from 'src/hooks/use-sdk';

export const CouponCodeErrorType = {
  Invalid: 'INVALID',
  Expired: 'EXPIRED',
} as const;

async function fetchCouponCode(sdk: ReturnType<typeof useSdk>, couponCode: string): Promise<Coupon> {
  try {
    const query = await sdk.couponCode({ couponCode });
    if (!query.subscriptionPlansStripe.coupon) throw new Error(CouponCodeErrorType.Invalid);
    return query.subscriptionPlansStripe.coupon;
  } catch (error) {
    // Todo improve error mapping/matching
    if (error instanceof ClientError && error.response.errors?.[0].message === 'coupon_expired') {
      throw new Error(CouponCodeErrorType.Expired);
    }
    throw new Error(CouponCodeErrorType.Invalid);
  }
}

/**
 * Hook to handle coupon code storage and validation.
 * It will try and retrieve the coupon code from local storage and validate it with the server to check if it's still valid.
 * If the coupon code is no longer valid, it will be removed from local storage.
 */
export function useCouponCode(): {
  couponCode: string | undefined;
  setCouponCode: (value: string) => Promise<Coupon>;
  resetCouponCode: () => void;
  coupon: Coupon | undefined;
} {
  const sdk = useSdk();
  const [couponCodeStoreValue, setCouponCodeStoreValue, resetCouponCodeStoreValue] = useCouponCodeStore((state) => [
    state.value,
    state.setCode,
    state.resetCode,
  ]);

  const { data: coupon, mutate } = useSWR<Coupon | undefined, Error>(
    couponCodeStoreValue && `couponCode/${couponCodeStoreValue}`,
    couponCodeStoreValue ? () => fetchCouponCode(sdk, couponCodeStoreValue) : null,
    {
      shouldRetryOnError: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onError: resetCouponCodeStoreValue,
    },
  );

  const setCouponCode = useCallback(
    async (value: string) => {
      const coupon = await fetchCouponCode(sdk, value);
      await mutate(coupon);
      setCouponCodeStoreValue(value);
      return coupon;
    },
    [mutate, sdk, setCouponCodeStoreValue],
  );

  return {
    couponCode: couponCodeStoreValue,
    setCouponCode,
    resetCouponCode: resetCouponCodeStoreValue,
    coupon,
  };
}
