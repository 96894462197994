import { sdk } from 'src/hooks/use-sdk';

/**
 * Checks if the given value is a valid email address.
 *
 * @param email - The value to be checked.
 * @returns `true` if the value is a valid email address, `false` otherwise.
 */
export function isEmail(email: unknown): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email));
}

/**
 * Check if the value is likely an invalid email by checking on the server.
 * It will return `true` if the email is likely invalid, `false` otherwise.
 * When the server response is not available, it will default to `false`.
 *
 * @param email The email to check
 * @returns `true` if the email is likely invalid, `false` otherwise
 */
export async function isLikelyInvalidEmail(email: string): Promise<boolean> {
  const response = await sdk.checkEmail({ email });
  return response?.checkRegistrationData?.emailLikelyValid === false;
}
