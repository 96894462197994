import { ComponentProps } from 'react';
import Trans from 'next-translate/Trans';
import { TranslationKeyCommon } from 'src/types';

// adjust the props of the `Trans` component to only accept valid translation keys
type TranslateProps = {
  /** Only valid/existing translation keys are allowed */
  i18nKey: TranslationKeyCommon;
} & ComponentProps<typeof Trans>;

/**
 * A wrapper around multi-line translations that accepts only valid translation keys
 * @param props - same as `TransProps` of `<Trans />` component
 * @example
 <Translate i18nKey="form_notifications_stage_newsletter_note">
    <Link href="/privacy" prefetch={false} />
  </Translate>
 */
export default function Translate(props: TranslateProps) {
  return <Trans {...props} />;
}
