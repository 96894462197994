import { Fragment, ReactNode, type ComponentType } from 'react';
import Head from 'next/head';
import FooterDefault from 'src/components/footer';
import FooterLimited from 'src/components/footer-limited';
import { NavigationFull, NavigationLimited } from 'src/components/navigation/navigation';
import NavigationMeta from 'src/components/navigation-meta';
import useHideNavigation from 'src/hooks/use-hide-navigation';
import useTranslate from 'src/hooks/use-translate';
import useUserTracking from 'src/hooks/use-user-tracking';

function HeaderFull() {
  return (
    <Fragment>
      <NavigationMeta />
      <NavigationFull />
    </Fragment>
  );
}

/**
 * A link that navigates to the main page content, hidden by default but visible
 * on focus
 */
function SkipToContentLink() {
  const t = useTranslate();

  return (
    <a
      href="#main-content"
      className="absolute -top-full left-5 rounded-lg bg-surface-0 px-4 py-3 text-buttonForeground outline-offset-0 focus-outline focus:top-12"
    >
      {t('header__skip_to_content_label')}
    </a>
  );
}

/**
 * A layout that contains a navigation bars, footer and a content area.
 * @param limitedNavigation - hide the navigation blocks that are not needed for the current page (e.g. for the payment page)
 * @param headerComponent - allows for a page to provide its own custom header
 * @param footerComponent - allows for a page to provide its own custom footer. I.e. the landing page
 * @returns
 */
const PageLayout = ({
  children,
  limitedNavigation,
  headerComponent: Header,
  footerComponent: Footer = FooterDefault,
}: {
  children: ReactNode;
  limitedNavigation?: boolean;
  headerComponent?: ComponentType;
  footerComponent?: ComponentType;
}) => {
  // check if the navigation was hidden by the query parameter
  // @todo: this is called on every layout render, maybe it makes sense to create specific pages without navigation, with a dedicated layout
  const navigationHiddenViaQueryParameter = useHideNavigation();
  // if the navigation was hidden via some of the options, don't show it and hide the footer
  const reducedNavigation = limitedNavigation || navigationHiddenViaQueryParameter;

  // Track the user's status - e.g. if they are a guest, registered or subscribed
  useUserTracking();

  return (
    <div className="page-container">
      {reducedNavigation ? (
        <Fragment>
          <Head>
            <meta name="robots" content="noindex" />
          </Head>
          <SkipToContentLink />
          {Header ? <Header /> : <NavigationLimited />}
          {children}
          <FooterLimited />
        </Fragment>
      ) : (
        <Fragment>
          <SkipToContentLink />
          {Header ? <Header /> : <HeaderFull />}
          {children}
          <Footer />
        </Fragment>
      )}
    </div>
  );
};

export default PageLayout;
