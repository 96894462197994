import { audienceApi } from 'src/config';
import { getDeviceInfo, getClientDeviceId } from 'src/utilities/device-info';

type AuthToken = {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
};

export type AuthResponse = {
  access_token: string;
  refresh_token: string;
  device_id: string;
  expires_in: number;
  token_type: 'Bearer';
};

export type AuthError = {
  code: string;
  message: string;
};

type PasswordGrant = {
  grant_type: 'password';
  username?: string;
  password?: string;
};

type RefreshTokenGrant = {
  grant_type: 'refresh_token';
  refresh_token?: string;
};

export type AuthGrant = PasswordGrant | RefreshTokenGrant;
type AuthGrantWithDeviceInfo = AuthGrant & {
  device_info: string;
  client_device_id?: string;
};

export type PasswordCredentials = Pick<PasswordGrant, 'username' | 'password'>;

/**
 * Fetches the auth token using an OAuth grant.
 * Currently only password and refresh_token grants are supported.
 *
 * @param grant The grant to use for authentication.
 * @returns The auth token
 */
const authRequest = (grant: AuthGrant): Promise<AuthToken> => {
  const grantWithDeviceInfo: AuthGrantWithDeviceInfo = {
    ...grant,
    device_info: getDeviceInfo(),
    client_device_id: getClientDeviceId(),
  };
  const body = JSON.stringify(grantWithDeviceInfo);
  return fetch(audienceApi.oauthUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  })
    .then((response) => {
      if (response.status !== 200) {
        // Get the error message from the response body
        return response.json().then((error: AuthError) => {
          throw new Error(error.message);
        });
      }
      return response.json();
    })
    .then((response: AuthResponse) => {
      return {
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
        expiresIn: response.expires_in,
      };
    });
};

/**
 * Fetches the access token using OAuth password grant.
 * https://www.oauth.com/oauth2-servers/access-tokens/password-grant/
 *
 * @param AuthCredentials user credentials representing a login attempt
 * @returns a promise resolving into a token
 */
export const authRequestWithPassword = ({ username, password }: PasswordCredentials): Promise<AuthToken> => {
  return authRequest({ grant_type: 'password', username, password });
};

/**
 * Fetches the access token using OAuth refresh token grant.
 * https://www.oauth.com/oauth2-servers/access-tokens/refreshing-access-tokens/
 *
 * @param refreshToken refresh token to use for refreshing the access token
 * @returns a promise resolving into a token
 */
export const authRequestWithRefreshToken = (refreshToken: string): Promise<AuthToken> => {
  return authRequest({ grant_type: 'refresh_token', refresh_token: refreshToken });
};
