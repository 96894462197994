import { useEffect } from 'react';
import { useTrackingParametersStore } from 'src/hooks/use-tracking-parameters';

/**
 * Get parameter value from query string, e.g. ?at=affiliate-name
 */
const getValueFromUrl = (name: string): string | undefined => {
  const searchQuery = window?.location?.search;
  const value = new URLSearchParams(searchQuery).get(name);
  return value ?? undefined;
};

/**
 * A hook that retrieves tracking params from the query string, stores it in
 * the local storage, and removes it from the URL. (e.g. `?fbclid=c0ffee`)
 */
export function useTrackingParametersFromQuery(): void {
  const { setFbclid, setAffiliateInfo } = useTrackingParametersStore();

  // Facebook: `?fbclid=c0ffee`
  useEffect(() => {
    const fbclidFromQuery = getValueFromUrl('fbclid');
    if (fbclidFromQuery) {
      setFbclid(fbclidFromQuery);
    }
  }, [setFbclid]);

  // Affiliate link: `?at=bada55`
  useEffect(() => {
    const affiliateInfoFromQuery = getValueFromUrl('at');
    if (affiliateInfoFromQuery) {
      setAffiliateInfo(affiliateInfoFromQuery);
    }
  }, [setAffiliateInfo]);
}
