import { PropsWithChildren, useEffect, useState } from 'react';
import { Middleware, SWRConfig, SWRConfiguration } from 'swr';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import { SupportedLocale } from 'src/types';

/**
 * The GraphQL and Algolia APIs return different results based on the current locale.
 * This middleware ensures that the SWR cache is invalidated when the locale changes by adding the locale to the cache key.
 * @param locale The current locale
 * @returns A SWR middleware
 */
function localizeMiddleware(locale: SupportedLocale): Middleware {
  return (useSWRNext) => (key, fetcher, config) => {
    /**
     * Only add the locale to the cache key if it is a string.
     * When the cache key is a function, it is the responsibility of the function to include the locale in the cache key.
     * Similarly, when the cache key is `null` or `undefined`, the cache key should not be modified.
     * @see https://swr.vercel.app/docs/options
     * @see https://swr.vercel.app/docs/conditional-fetching
     */
    const keyWithLocale = typeof key === 'string' ? `${locale}/${key}` : key;
    return useSWRNext(keyWithLocale, fetcher, config);
  };
}

// Determine if we should mock API requests, e.g. when running E2E tests
const shouldMockRequests = process.env.NEXT_PUBLIC_MOCK_API === 'true';

/**
 * A component that sets the default config for any useSWR calls in any of its children.
 * Therefore it should sit as high up the React node tree as possible.
 */
export default function APIRoot({ children }: PropsWithChildren<unknown>) {
  const currentLocale = useCurrentLocale();

  const globalSWRConfig: SWRConfiguration = {
    use: [localizeMiddleware(currentLocale)],
  };

  // If we are using mocked API,
  // we will start hydration only after the browser mocks have been initialized
  // Otherwise, the first few SWR requests might pass through to the real API,
  // instead of immediately using the mocks.
  // See https://github.com/mswjs/msw/issues/1971

  // if we are not using mocks, we can start hydration immediately
  const [startHydration, setStartHydration] = useState(!shouldMockRequests);

  useEffect(() => {
    // If we are using mocks, load and initialize them
    if (shouldMockRequests) {
      void loadAndInitMocks();
      // announce that we are using mocks to the web developer console
      console.info('::::::: Will be Using mocks for client-side API requests');
    }
    /**
     * Dynamically import the browser mocks and initialize them.
     */
    async function loadAndInitMocks() {
      const { initBrowserMocks } = await import('src/mocks');
      void initBrowserMocks().then(() => {
        setStartHydration(true);
      });
    }
  }, []);

  // If we are using mocks and we have not started hydration, do not render any children yet
  if (shouldMockRequests && !startHydration) {
    return null;
  }

  return <SWRConfig value={globalSWRConfig}>{children}</SWRConfig>;
}
