import { SVGProps } from 'react';

export default function CircularProgress(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24" {...props}>
      <g fill="none">
        <path
          d="m24 12c0 6.62742-5.37258 12-12 12s-12-5.37258-12-12 5.37258-12 12-12 12 5.37258 12 12zm-20.4 0c0 4.6392 3.7608 8.4 8.4 8.4s8.4-3.7608 8.4-8.4-3.7608-8.4-8.4-8.4-8.4 3.7608-8.4 8.4z"
          fill="#fff"
          fillOpacity=".55"
        />
        <path
          d="m24 12c0-1.57586-.3104-3.1363-.9134-4.5922-.6032-1.4559-1.487-2.77878-2.6014-3.89308-1.11422-1.1143-2.4371-1.998218-3.893-2.601274s-3.01634-.913446-4.5922-.913446v3.6c1.1031 0 2.1954.21728 3.21454.63942s1.94514 1.04088 2.72516 1.82088c.78.78002 1.39874 1.70602 1.82088 2.72516.42222 1.01914.63942 2.11144.63942 3.21454z"
          fill="#1a2436"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 12 12; 360 12 12"
          dur="750ms"
          additive="sum"
          repeatCount="indefinite"
        />
      </g>
    </svg>
  );
}
