import type { SVGProps } from 'react';
const SvgYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.797 15.784c-.13 1.593-1.342 3.626-3.041 3.92-5.432.42-11.879.369-17.51 0-1.757-.22-2.911-2.33-3.04-3.92-.274-3.343-.274-5.247 0-8.591.129-1.59 1.312-3.688 3.04-3.88 5.566-.466 12.044-.366 17.51 0 1.958.072 2.911 2.079 3.04 3.672.272 3.344.272 5.456 0 8.8m-14.196.37 7.2-4.783-7.2-4.784z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgYoutube;
