import { useCallback } from 'react';
import { useAuth } from 'src/state/auth';
import { usePlayback } from 'src/state/playback';
import { trackEvent } from 'src/tracking';

/**
 * Clear all persistent state in the local storage
 *  */
const clearLocalStorage = () => {
  if (typeof window === 'undefined') {
    return;
  }
  window.localStorage.clear();
};

/**
 * A hook that allows to logout the user,
 * it also resets the player state and clears all local storage items
 */
export default function useLogout() {
  // remove authentication token
  const { logout } = useAuth();
  // reset playback state
  const { clearQueue } = usePlayback();

  return useCallback(() => {
    // clear the playback queue
    void clearQueue();
    // clear the token
    logout();
    // clear the local storage
    clearLocalStorage();
    // track the logout event
    trackEvent({
      eventName: 'Logout',
    });
  }, [clearQueue, logout]);
}
