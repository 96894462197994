import type { SVGProps } from 'react';
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m18.125 11.406 1-.562a.74.74 0 0 0 .344-.844c-.344-1.312-1.063-2.5-2-3.469-.25-.218-.625-.281-.907-.125l-1 .594a5.6 5.6 0 0 0-1.03-.594V5.25c0-.312-.25-.656-.563-.719a7.77 7.77 0 0 0-3.969 0 .73.73 0 0 0-.562.719v1.156A5.6 5.6 0 0 0 8.406 7l-1-.594c-.281-.156-.656-.093-.906.125-.937.969-1.656 2.157-2 3.469a.74.74 0 0 0 .344.844l1 .562c-.032.188-.032.438-.032.594 0 .188 0 .438.032.594l-1 .594a.74.74 0 0 0-.344.843c.344 1.313 1.063 2.5 2 3.469.25.219.625.281.906.125l1-.594c.25.188.719.469 1.031.594v1.156c0 .313.25.656.563.719a7.76 7.76 0 0 0 3.969 0 .73.73 0 0 0 .562-.719v-1.156a5.5 5.5 0 0 0 1.031-.594l1 .594c.282.156.657.094.907-.125.937-.969 1.656-2.156 2-3.469a.74.74 0 0 0-.344-.844l-1-.593a7.5 7.5 0 0 0 0-1.188m-1.656 2 1.375.781c-.188.563-.688 1.376-1.063 1.813l-1.375-.781c-1 .844-1.125.937-2.375 1.375v1.594c-.281.03-.75.093-1.031.093-.313 0-.781-.062-1.063-.094v-1.593c-1.25-.438-1.406-.531-2.375-1.375L7.187 16c-.375-.437-.875-1.25-1.062-1.812l1.375-.782c-.25-1.312-.25-1.469 0-2.781l-1.375-.781c.25-.656.594-1.281 1.062-1.813l1.375.781c1-.843 1.125-.937 2.375-1.374V5.844c.282-.032.75-.094 1.063-.094.281 0 .75.063 1.031.094v1.593c1.25.438 1.406.532 2.375 1.375l1.375-.78c.375.437.875 1.25 1.063 1.812l-1.375.781c.25 1.313.25 1.469 0 2.781M12 9c-1.656 0-3 1.375-3 3a3 3 0 0 0 3 3c1.625 0 3-1.344 3-3 0-1.625-1.375-3-3-3m0 4.5a1.48 1.48 0 0 1-1.5-1.5 1.5 1.5 0 0 1 1.5-1.5c.812 0 1.5.688 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5"
    />
  </svg>
);
export default SvgSettings;
