import UAParser from 'ua-parser-js';
import Cookies from 'universal-cookie';
import { isTouchDevice } from 'src/utilities/capabilities';
import { generateUuid, isValidUuid } from 'src/utilities/uuid';

/**
 * Converts user agent to a string that can be used by the OAuth server’s device_info field
 * e.g. `Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_4) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.138 Safari/537.36`
 * will return `Chrome 81 (Mac OS)`
 *
 * @example
 * ```
 * const deviceInfo = getDeviceInfo();
 * // deviceInfo = Mozilla Firefox 97 (Mac OS)
 * ```
 */
export function getDeviceInfo() {
  const parser = new UAParser();
  const browser = parser.getBrowser();
  const os = parser.getOS();
  const browserName = browser.name;
  const majorBrowserVersion = browser.version?.split('.')[0];
  const osName = os.name;
  const browserInfo = [browserName, majorBrowserVersion].filter(Boolean).join(' ');

  return `${browserInfo}${osName ? ` (${osName})` : ''}` || 'Unknown Device';
}

// Encode the values that are accepted by the RecordPlaybackProgressInput.clientDevice field
// from the web client.
export const ClientDevice = {
  WebDesktop: 'WebDesktop',
  WebiOS: 'WebiOS',
  WebAndroid: 'WebAndroid',
  WebTablet: 'WebTablet',
  WebOther: 'WebOther',
} as const;

/**
 * Returns the client device type
 *
 * @example
 * ```
 * const clientDevice = getClientDevice();
 * // clientDevice = `WebDesktop` or `WebiOS` or `WebAndroid` or `WebOther`
 * ```
 */
export function getClientDevice(): keyof typeof ClientDevice {
  const parser = new UAParser();
  const os = parser.getOS();
  const isTouch = isTouchDevice();
  switch (os.name) {
    case 'iOS': {
      return ClientDevice.WebiOS;
    }
    case 'Android': {
      return ClientDevice.WebAndroid;
    }
    case 'Mac OS': {
      // iPadOs is advertised as MacOS in UserAgent by default
      return isTouch ? ClientDevice.WebiOS : ClientDevice.WebDesktop;
    }
    case 'Linux': {
      // in some cases, Samsung Internet browser running on Galaxy Tab would report itself as Linux, we have to correct that
      return parser.getBrowser().name === 'Samsung Internet' ? ClientDevice.WebAndroid : ClientDevice.WebDesktop;
    }
    case 'Windows': {
      return ClientDevice.WebDesktop;
    }
    default: {
      return ClientDevice.WebOther;
    }
  }
}

/**
 * Returns current device ID. The device ID is a UUID and stored in a cookie to ensure it is the same across multiple
 * sessions. If the device ID is not stored in a cookie, a new UUID is generated and stored. The device ID is used to
 * identify the device when registering via the API or logging in via OAuth to avoid creating multiple devices for the
 * same browser.
 *
 * @note In an ideal world we would use the `device_id` field returned by the `/oauth/token` endpoint when logging in
 * or the `user.devices[].id` field returned by the `registerUser` mutation on the API. Unfortunately, this currently
 * still creates duplicate devices for the same browser. It seems like the API considers `deviceId` and
 * `clientDeviceId` to be two completely different things. This is why we resort to generating our own UUID string.
 * @todo Check if we can use localStorage instead of cookies. We currently use cookies because localStorage.clean() is
 * called on logout and we need this to persist across sessions.
 */
export function getClientDeviceId(): string | undefined {
  const cookieKey = 'dgplus_device_id';
  const cookies = new Cookies();
  // Get device id from cookie
  const deviceId = cookies.get<string | undefined>(cookieKey);
  // Check whether the stored device id is a valid UUID
  if (deviceId && isValidUuid(deviceId)) return deviceId;
  // If no device id is found or is is not valid, generate a new UUID
  const newDeviceId = generateUuid();
  // Store new device id in a cookie for 1 year
  cookies.set(cookieKey, newDeviceId, { path: '/', maxAge: 31_536_000, sameSite: 'lax' });
  // Return new device id
  return newDeviceId;
}
