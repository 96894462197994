import { Fragment } from 'react';
import CheckmarkIcon from '@stageplus/icons/react/checkmark';
import ChevronDownIcon from '@stageplus/icons/react/chevron-down';
import LanguageIcon from '@stageplus/icons/react/language';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import ContextMenu from 'src/components/context-menu';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import useTranslate from 'src/hooks/use-translate';
import { SupportedLocale } from 'src/types';
import { supportedLocales } from 'src/utilities/i18n-helpers';

const localesNames: Record<SupportedLocale, string> = { en: 'English', de: 'Deutsch', ja: '日本語' };

export default function NavigationLanguageSwitcher() {
  const { asPath, pathname, query, push } = useRouter();
  const currentLocale = useCurrentLocale();
  const t = useTranslate();

  async function handleLocaleSelect(locale: SupportedLocale) {
    // Set `NEXT_LOCALE` cookie to persist locale selection between sessions for 1 year
    const cookies = new Cookies();
    cookies.set('NEXT_LOCALE', locale, { path: '/', maxAge: 1000 * 60 * 60 * 24 * 365 });
    // Redirect to the same page with the new locale
    await push({ pathname, query }, asPath, { locale });
  }

  return (
    <ContextMenu data-test="language-switcher" className="relative h-full">
      <ContextMenu.Button as={Fragment}>
        {({ open }) => {
          const localeName = localesNames[currentLocale];
          return (
            <button
              aria-label={t('language_switcher__label', [localeName])}
              className={clsx(
                'group h-full select-none whitespace-nowrap outline-none mouse:hover:text-brand-dg',
                open ? 'text-brand-dg' : 'text-surface-100',
              )}
            >
              <span className="flex items-center justify-center gap-x-1 rounded-sm py-3 outline-offset-2 group-focus-visible:focus-outline">
                <LanguageIcon className="size-5 shrink-0" aria-hidden />
                <span
                  className={clsx(
                    'dg-text-regular-4 mouse:group-hover:text-inherit',
                    open ? 'text-inherit' : 'text-textPrimary',
                  )}
                >
                  {localeName}
                </span>
                <ChevronDownIcon className="size-5 shrink-0" aria-hidden />
              </span>
            </button>
          );
        }}
      </ContextMenu.Button>
      <ContextMenu.Items>
        {supportedLocales?.map((locale) => (
          <ContextMenu.Item
            key={locale}
            onClick={() => handleLocaleSelect(locale)}
            icon={locale === currentLocale ? CheckmarkIcon : undefined}
          >
            {localesNames[locale]}
          </ContextMenu.Item>
        ))}
      </ContextMenu.Items>
    </ContextMenu>
  );
}
