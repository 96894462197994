import { captureException as captureSentryException, Scope, SeverityLevel, User } from '@sentry/nextjs';

// a partial copy of the `ScopeContext` interface from `@sentry/nextjs`
type ScopeContext = {
  user: User;
  level: SeverityLevel;
  extra: Record<string, unknown>;
  tags: Record<string, string | number | boolean | undefined>;
  fingerprint: string[];
};

/**
 * Use this method to capture an error on a client and send it to Sentry.
 * It will not send an error if Sentry is disabled
 * or if the user has not consented to the use of the service
 * @see `sentry.client.config.ts` for setup details.
 * @param error
 * @param context
 */
export const captureException = (error: unknown, context?: Scope | Partial<ScopeContext>) => {
  // Capture the exception using Sentry
  captureSentryException(error, context);
  // Only log the error to the console in a browser environment
  if (typeof window !== 'undefined') {
    console.error('captureException', error);
  }
};
