import { ReactNode } from 'react';
import { create } from 'zustand';
import { FunctionalComponentWithChildren } from 'src/types';

export type NotificationType = 'success' | 'info' | 'error';

type Notification = {
  /**
   * The notification text
   */
  text: ReactNode;
  /**
   * The notification title
   */
  title: string;
  /**
   * The element to render as the action button
   */
  action?: FunctionalComponentWithChildren;
  /**
   * How many seconds to wait before hiding the notification
   */
  hideAfterSeconds?: number;
  /**
   * The type of notification, e.g. success, info, error
   */
  type?: NotificationType;
};

type NotificationStore = {
  /**
   * The notification properties
   */
  notification: Notification | undefined;
  /**
   * Whether the notification is open
   * @default false
   */
  open: boolean;
  /**
   * Display a notification to the user.
   */
  showNotification: (newNotification: Notification) => void;
  /**
   * Hide/close a notification
   */
  hideNotification: () => void;
};

/**
 * The store hook that holds the notification state.
 */
export const useNotification = create<NotificationStore>((set, get) => ({
  notification: undefined,
  open: false,
  showNotification: (newNotification: Notification) => {
    set({ notification: newNotification, open: true });
  },
  hideNotification: () => {
    set({ ...get().notification, open: false });
  },
}));

/**
 * React hook that allows you to show a notification.
 *
 * @example
 * const showNotification = useShowNotification();
 *
 * function onClick() {
 *   showNotification({
 *     type: 'success',
 *     title: 'Success',
 *     text: 'This is a success message',
 *   });
 * }
 */
export function useShowNotification() {
  const showNotification = useNotification((state) => state.showNotification);

  return (state: Notification) => {
    showNotification(state);
  };
}
