import type { SVGProps } from 'react';
const SvgFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.52 23V12.742H6V8.963h3.52V6.17C9.52 2.823 11.564 1 14.55 1c1.43 0 2.66.106 3.017.154v3.498h-2.07c-1.624 0-1.939.773-1.939 1.905v2.406h3.78l-.54 3.78h-3.24V23z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFacebook;
