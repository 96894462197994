import { Album, LiveConcert, Video, VodConcert } from 'generated/graphql';

type ContentType = Pick<Album | LiveConcert | Video | VodConcert, '__typename'>['__typename'] | 'Trailer';

/**
 * A helper function to get the tracking type of a content from a route
 * @param pathname - the path to get the content type from, e.g. '/video/live_concert_123' or '/audio/album_123'
 * @param search - the search query part of the url, e.g. '?player=true&trailer=true' or '?q=bach'
 * @returns
 */
export const getContentTypeFromUrl = ({
  pathname,
  search,
}: Pick<URL, 'pathname' | 'search'>): ContentType | undefined => {
  if (search.includes('trailer=true')) {
    return 'Trailer';
  } else if (pathname.includes('/audio/album')) {
    return 'Album';
  } else if (pathname.includes('/video/live_concert')) {
    return 'LiveConcert';
  } else if (pathname.includes('/video/video')) {
    return 'Video';
  } else if (pathname.includes('/video/vod_concert')) {
    return 'VodConcert';
  } else {
    return undefined;
  }
};
