import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from 'src/components/container-grid.module.css';

/**
 *  The responsive grid container
 * @component
 *  All block elements that will be treated as individual columns.
 *  By default each child will be set 1 column wide. For the wider elements set the width with the 'col-span-x' class. E.g. 'col-span-6' or 'col-span-2'
 * @param children - block elements, e.g. divs or sections
 *
 * @example  <ContainerGrid>
              <div className="bg-red-500 h-20 col-span-6"></div>
              <div className="bg-green-500 h-20 col-span-6"></div>
            </ContainerGrid>
 */

export type ContainerGridProps = PropsWithChildren<{
  /**
   * Whether the container should take up the full height of the parent element
   * @default false
   */
  fullHeight?: boolean;
  /**
   * Whether the container should take up the full width of the parent element on mobile
   * @default false
   */
  fullWidthMobile?: boolean;
  /**
   * Whether the grid supports rows as well as columns
   * @default false
   */
  rows?: boolean;
  dataTest?: string;
}>;

const ContainerGrid = ({
  children,
  fullHeight = false,
  fullWidthMobile = false,
  rows = false,
  dataTest,
}: ContainerGridProps) => {
  return (
    <div
      className={clsx(
        styles.root,
        fullHeight && styles.fullHeight,
        fullWidthMobile && styles.fullWidthMobile,
        rows && styles.rows,
      )}
      data-test={dataTest}
    >
      {children}
    </div>
  );
};

export default ContainerGrid;
