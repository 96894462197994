import { RefObject, useCallback, useEffect } from 'react';
import { PublicationLevel } from 'generated/graphql';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';
import { pushToGoogleTagManager } from 'src/tracking/gtm';
import { PlayableMedia } from 'src/types';
import { measureTiming, setTimingMark } from 'src/utilities/measure';
import { getNodePath } from 'src/utilities/url-helpers';

// All playback analytics tracking events should be defined here

type PlaybackEvent = 'PlaybackStart' | 'PlaybackPlay' | 'PlaybackPause' | 'PlaybackSkip' | 'PlaybackComplete';
type SubtitleEvent = 'SubtitleOpen' | 'SubtitleLanguageSelected';
type AudioQualityEvent = 'AudioQualityOpen' | 'AudioQualityOpenSelected';

type ContentInteractionEventPayload = {
  currentMedia?: PlayableMedia;
  currentActiveTrack?: ReturnType<typeof usePlayback>['currentActiveTrack'];
  currentActiveWork?: ReturnType<typeof usePlayback>['currentActiveWork'];
};

export type PlaybackEventPayload = {
  eventName: PlaybackEvent;
  audioQuality?: string;
} & ContentInteractionEventPayload;

export type SubtitleEventPayload = {
  eventName: SubtitleEvent;
  selectedLanguage?: string;
  languages: string[];
} & ContentInteractionEventPayload;

export type AudioQualityEventPayload = {
  eventName: AudioQualityEvent;
  // the user-selected audio quality label
  selectedAudioQuality?: string;
  // the list of available audio quality levels
  audioQualityList: string[];
} & ContentInteractionEventPayload;

export type PlayerControlsEventPayload = {
  eventName: 'PlayerControlsPlay' | 'PlayerControlsPause' | 'PlayerControlsSkip';
};

const playerControlsEventNames: Record<PlayerControlsEventPayload['eventName'], [string, string]> = {
  PlayerControlsPlay: ['play_button_click', 'Play Button'],
  PlayerControlsPause: ['pause_button_click', 'Pause Button'],
  PlayerControlsSkip: ['skip_button_click', 'Skip Button'],
};

/**
 * Returns the artists from the current media (e.g. album, video, live concert)
 * Used to track the content container artists by name and id
 */
function getContentContainerArtists(currentMedia: PlayableMedia) {
  // A trailer has no artists
  if (currentMedia.__typename === 'Trailer') return;
  // Extract artists from the current media
  const artists =
    [...(currentMedia.soloists?.edges ?? []), ...(currentMedia.conductors?.edges ?? [])].map((edge) => ({
      id: edge?.node?.id,
      name: edge?.node?.name,
    })) ?? [];
  // Also extract groups from albums
  const groups =
    currentMedia.__typename === 'Album'
      ? currentMedia.groups.map((group) => ({
          id: group?.id,
          name: group?.name,
        }))
      : [];

  const artistsAndGroups = [...artists, ...groups];
  return artistsAndGroups.length > 0 ? artistsAndGroups : undefined;
}

/**
 * Returns the artists from the current item (e.g. track, performance work)
 * Used to track the content item artists by name and id
 */
function getContentItemArtists(
  currentItem:
    | ReturnType<typeof usePlayback>['currentActiveTrack']
    | ReturnType<typeof usePlayback>['currentActiveWork'],
) {
  // A track currently has no relyable way of retrieving the artist id because it has no `artists` field
  if (currentItem?.__typename === 'Track') return;
  // Extract artists from the current item
  const artists =
    currentItem?.artists?.edges.map((edge) => ({
      id: edge?.node?.id,
      name: edge?.node?.name,
    })) ?? [];
  return artists.length > 0 ? artists : undefined;
}

function getContentSubtype(currentMedia: PlayableMedia) {
  switch (currentMedia.__typename) {
    case 'LiveConcert': {
      return currentMedia.liveConcertType;
    }
    case 'Video': {
      return currentMedia.videoType;
    }
    case 'VodConcert': {
      return currentMedia.vodConcertType;
    }
  }
}

/**
 * Get the publication level of the current media
 */
function getContentPublicationLevel(
  currentMedia: PlayableMedia,
  currentItem:
    | ReturnType<typeof usePlayback>['currentActiveTrack']
    | ReturnType<typeof usePlayback>['currentActiveWork'],
): PublicationLevel {
  if (currentItem && currentItem.stream) {
    return currentItem.stream.publicationLevel;
  } else if ('stream' in currentMedia) {
    return currentMedia.stream.publicationLevel;
  } else if (currentMedia && 'publicationLevel' in currentMedia) {
    return currentMedia.publicationLevel;
  } else {
    return PublicationLevel.TicketRequired;
  }
}

const getContentInteractionObject = ({
  currentMedia,
  currentActiveTrack,
  currentActiveWork,
}: Omit<PlaybackEventPayload, 'eventName'>) => {
  if (!currentMedia) {
    return undefined;
  }
  const contentUrl = getNodePath(currentMedia);
  const contentContainerArtists = getContentContainerArtists(currentMedia);
  const contentItemArtists = getContentItemArtists(currentActiveTrack || currentActiveWork);
  const contentSubtype = getContentSubtype(currentMedia);
  const contentAccessType =
    getContentPublicationLevel(currentMedia, currentActiveTrack || currentActiveWork) ===
    PublicationLevel.TicketRequired
      ? 'paid'
      : 'free';

  return {
    content_access_type: contentAccessType,
    content_type: currentMedia?.__typename,
    content_subtype: contentSubtype,
    content_container_name: currentMedia.title,
    content_container_id: currentMedia.id,
    content_container_artist_name: contentContainerArtists?.map((artist) => artist.name).join(','),
    content_container_artist_id: contentContainerArtists?.map((artist) => artist.id).join(','),
    content_item_name: currentActiveTrack?.title || currentActiveWork?.work?.title,
    content_item_id: currentActiveTrack?.id || currentActiveWork?.id,
    content_item_artist_name: contentItemArtists?.map((artist) => artist.name).join(','),
    content_item_artist_id: contentItemArtists?.map((artist) => artist.id).join(','),
    content_link: contentUrl,
  };
};

/**
 * Track audio and video playback in analytics and GTM
 * based on the spec from:
 https://docs.google.com/spreadsheets/d/1v69EYKu8GXmL5ZWR6oeDS8Hv5ZsC4WBXNUUecuMXvrk/edit?pli=1&gid=1333359965#gid=1333359965&range=112:112
 */
export const trackPlayback = ({
  eventName,
  currentMedia,
  currentActiveTrack,
  currentActiveWork,
  audioQuality,
}: PlaybackEventPayload) => {
  // Remove the 'Playback' prefix from the event name, e.g. 'PlaybackStart' -> 'Start'
  const action = eventName.replace('Playback', '');
  // Combine the current media and specific playback item data to create the content object for tracking
  const contentObject = getContentInteractionObject({
    currentMedia,
    currentActiveTrack,
    currentActiveWork,
  });

  if (!contentObject) {
    // If there is no current media, do not track the event
    return;
  }

  pushToGoogleTagManager({
    event: 'Generic Event',
    event_name: 'content_interaction',
    content_interaction: {
      action: action,
      audio_quality: audioQuality,
      ...contentObject,
    },
  });
};

/**
 * Track subtitle events in analytics and GTM
 * based on the spec from:
 * https://docs.google.com/spreadsheets/d/1v69EYKu8GXmL5ZWR6oeDS8Hv5ZsC4WBXNUUecuMXvrk/edit?pli=1&gid=1333359965#gid=1333359965&range=126:126
 * @param eventName the subtitle event name
 * */
export const trackSubtitle = ({
  eventName,
  currentMedia,
  currentActiveTrack,
  currentActiveWork,
  languages,
  selectedLanguage,
}: SubtitleEventPayload) => {
  // Remove the 'Subtitles' prefix from the event name, e.g. 'SubtitlesOpen' -> 'Open'
  const action = eventName === 'SubtitleOpen' ? 'subtitle_opened' : 'subtitle_language_selected';
  const contentObject = getContentInteractionObject({
    currentMedia,
    currentActiveTrack,
    currentActiveWork,
  });

  if (!contentObject) {
    // If there is no current media, do not track the event
    return;
  }

  pushToGoogleTagManager({
    event: 'Generic Event',
    event_name: action,
    [action]: {
      // The available subtitle languages
      subtitle_language_available: languages.join(','),
      // The selected subtitle language
      ...(selectedLanguage ? { subtitle_language: selectedLanguage } : {}),
      action: action,
      ...contentObject,
    },
  });
};

/**
 * Track audio quality events in analytics and GTM
 * based on the spec from:
 * https://docs.google.com/spreadsheets/d/1v69EYKu8GXmL5ZWR6oeDS8Hv5ZsC4WBXNUUecuMXvrk/edit?pli=1&gid=1333359965#gid=1333359965&range=126:126
 * @param eventName the audio quality event name
 * */
export const trackAudioQuality = ({
  eventName,
  currentMedia,
  currentActiveTrack,
  currentActiveWork,
  audioQualityList,
  selectedAudioQuality,
}: AudioQualityEventPayload) => {
  // Remove the 'Subtitles' prefix from the event name, e.g. 'SubtitlesOpen' -> 'Open'
  const action = eventName === 'AudioQualityOpen' ? 'audio_quality_opened' : 'audio_quality_selected';
  const contentObject = getContentInteractionObject({
    currentMedia,
    currentActiveTrack,
    currentActiveWork,
  });

  if (!contentObject) {
    // If there is no current media, do not track the event
    return;
  }

  pushToGoogleTagManager({
    event: 'Generic Event',
    event_name: action,
    [action]: {
      // The available subtitle languages
      audio_quality_available: audioQualityList.join(','),
      // The selected subtitle language
      ...(selectedAudioQuality ? { audio_quality_selected: selectedAudioQuality } : {}),
      action: action,
      ...contentObject,
    },
  });
};

export const trackPlayerControls = ({ eventName }: PlayerControlsEventPayload) => {
  const trackingEvent = playerControlsEventNames[eventName][0];
  pushToGoogleTagManager({
    event: 'Generic Event',
    event_name: trackingEvent,
    [trackingEvent]: {},
  });
};

/**
 * A hook that tracks content interaction events, it automatically gets the current media name and label
 * @returns a track function that only requires the event name
 * @example const { track } = usePlaybackTracking();
 *         track('PlaybackStart');
 */
export const useContentInteractionTracking = (videoRef: RefObject<HTMLVideoElement>) => {
  // Get the current media and particular item from the playback state
  const { currentActiveTrack, currentActiveWork, currentMedia } = usePlayback();
  // Get the audio context to track the audio quality
  const { audio } = useVideoContext();
  // Get the current audio quality label
  const audioQuality = audio?.currentAudioTrack?.label;

  const trackPlaybackEvent = useCallback(
    (eventName: PlaybackEvent) => {
      trackPlayback({
        eventName,
        currentMedia,
        currentActiveTrack,
        currentActiveWork,
        audioQuality,
      });
    },
    [audioQuality, currentActiveTrack, currentActiveWork, currentMedia],
  );

  // listen to the video object changes and update the global state
  const onVideoStatusUpdate = useCallback(
    (event: Event) => {
      const video = event.target as HTMLVideoElement;
      // when the video starts/resumes playing
      if (event.type === 'play' || event.type === 'playing') {
        // track Start event, but if the video has already started playing, track simple Play event
        trackPlaybackEvent(video.currentTime > 1 ? 'PlaybackPlay' : 'PlaybackStart');
        // log the playback start for the performance tools
        setTimingMark('playbackStarted');
        // measure how long it took to start playback
        measureTiming('playerInitialization');
      }
      // when the video is paused
      if (event.type === 'pause') {
        // track pause event
        trackPlaybackEvent('PlaybackPause');
      }
      // when the video has finished playing
      if (event.type === 'ended') {
        // track end event
        trackPlaybackEvent('PlaybackComplete');
      }
    },
    [trackPlaybackEvent],
  );

  useEffect(() => {
    const video = videoRef.current;
    // listen to the video object changes and track the playback events
    // video?.addEventListener('play', onVideoStatusUpdate);
    // we use the 'playing' event instead of 'play' to track the start event
    // because the 'play' event is fired in hls before the video channels are ready and eg missing audio info
    video?.addEventListener('playing', onVideoStatusUpdate);
    video?.addEventListener('pause', onVideoStatusUpdate);
    video?.addEventListener('ended', onVideoStatusUpdate);

    return () => {
      // remove the event listeners when the video element is unmounted
      // video?.removeEventListener('play', onVideoStatusUpdate);
      video?.removeEventListener('playing', onVideoStatusUpdate);
      video?.removeEventListener('pause', onVideoStatusUpdate);
      video?.removeEventListener('ended', onVideoStatusUpdate);
    };
  }, [videoRef, onVideoStatusUpdate]);
};

/**
 * Track subtitle-related events in analytics and GTM
 * @returns a track function that only requires the event name
 */
export const useSubtitleTracking = () => {
  // Get the current media and particular item from the playback state
  const { currentActiveTrack, currentActiveWork, currentMedia } = usePlayback();
  // Track subtitle menu opening
  const trackSubtitleOpen = useCallback(
    (languages: string[]) => {
      trackSubtitle({
        eventName: 'SubtitleOpen',
        languages,
        currentMedia,
        currentActiveTrack,
        currentActiveWork,
      });
    },
    [currentActiveTrack, currentActiveWork, currentMedia],
  );
  // Track subtitle language selection
  const trackSubtitleSelect = useCallback(
    (selectedLanguage: string, languages: string[]) => {
      trackSubtitle({
        eventName: 'SubtitleLanguageSelected',
        selectedLanguage,
        languages,
        currentMedia,
        currentActiveTrack,
        currentActiveWork,
      });
    },
    [currentActiveTrack, currentActiveWork, currentMedia],
  );

  return {
    trackSubtitleOpen,
    trackSubtitleSelect,
  };
};

/**
 * Track audio quality-related events in analytics and GTM
 * @returns a track function that only requires the event name
 */
export const useAudioQualityTracking = () => {
  // Get the current media and particular item from the playback state
  const { currentActiveTrack, currentActiveWork, currentMedia } = usePlayback();
  // Track subtitle menu opening
  const trackAudioQualityOpen = useCallback(
    (audioQualityList: string[]) => {
      trackAudioQuality({
        eventName: 'AudioQualityOpen',
        audioQualityList,
        currentMedia,
        currentActiveTrack,
        currentActiveWork,
      });
    },
    [currentActiveTrack, currentActiveWork, currentMedia],
  );
  // Track subtitle language selection
  const trackAudioQualitySelect = useCallback(
    (selectedAudioQuality: string, audioQualityList: string[]) => {
      trackAudioQuality({
        eventName: 'AudioQualityOpenSelected',
        audioQualityList,
        selectedAudioQuality,
        currentMedia,
        currentActiveTrack,
        currentActiveWork,
      });
    },
    [currentActiveTrack, currentActiveWork, currentMedia],
  );

  return {
    trackAudioQualityOpen,
    trackAudioQualitySelect,
  };
};
