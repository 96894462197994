import { SupportedLocale } from 'src/types';
import { defaultLocale } from 'src/utilities/i18n-helpers';

const helpCenterBaseUrl = 'https://help.stage-plus.com/hc';
const helpCenterLocaleMap: Record<SupportedLocale, string> = {
  en: 'en-gb',
  de: 'de',
  ja: 'ja-jp',
};

/**
 * Returns the help center URL for the given locale
 * @param locale The locale to get the help center URL for
 * @param path Optional path to append to the URL
 * @returns The help center URL
 */
export function getHelpCenterUrl(locale: SupportedLocale, path = ''): string {
  // Get the locale-specific part of the URL, falling back to default locale if needed
  const localeSegment = helpCenterLocaleMap[locale] || helpCenterLocaleMap[defaultLocale];

  // Ensure path starts with a forward slash if provided
  const normalizedPath = path && !path.startsWith('/') ? `/${path}` : path;

  return `${helpCenterBaseUrl}/${localeSegment}${normalizedPath}`;
}
