import type { SVGProps } from 'react';
const SvgErrorTwoTone = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path
        d="M15.422 2.5H12.5V1h2.922c.729 0 1.428.29 1.944.805l5.329 5.329a2.75 2.75 0 0 1 .805 1.944v6.344a2.75 2.75 0 0 1-.806 1.944l-5.328 5.329a2.75 2.75 0 0 1-1.944.805H12.5V22h2.922c.331 0 .649-.132.883-.366l5.329-5.328a1.25 1.25 0 0 0 .366-.884V9.078a1.25 1.25 0 0 0-.366-.883l-5.329-5.329a1.25 1.25 0 0 0-.883-.366"
        opacity={0.8}
      />
      <path
        d="M8.578 22H12.5v1.5H8.578a2.75 2.75 0 0 1-1.944-.805l-5.329-5.329A2.75 2.75 0 0 1 .5 15.422V9.078c0-.729.29-1.428.805-1.944l5.329-5.329A2.75 2.75 0 0 1 8.578 1H12.5v1.5H8.578a1.25 1.25 0 0 0-.883.366L2.366 8.195A1.25 1.25 0 0 0 2 9.078v6.344c0 .331.132.649.366.884l5.329 5.328c.234.234.552.366.883.366"
        opacity={0.4}
      />
      <path d="M15.889 17 7 8.111 8.111 7 17 15.889z" opacity={0.6} />
      <path d="M7 15.889 15.889 7 17 8.111 8.111 17z" />
    </g>
  </svg>
);
export default SvgErrorTwoTone;
