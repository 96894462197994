import React, { ReactElement, ReactNode, type ComponentType } from 'react';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import {
  AlbumFieldsFragment,
  AudioDetailPageQuery,
  LiveConcertFieldsFragment,
  LivePerformanceWorkFieldsFragment,
  PerformanceWorkFieldsFragment,
  PlayerContentQuery,
  VideoFieldsFragment,
  VodConcertFieldsFragment,
  Stream,
  TrackFieldsFragment,
  LandingPageQuery,
} from 'generated/graphql';
import germanCommonTranslations from 'locales/de/common.json';
import abTestsTranslations from 'locales/en/abtesting.json';
import englishCommonTranslations from 'locales/en/common.json';
import legalTranslations from 'locales/en/legal.json';
import seoTranslations from 'locales/en/seo.json';
import japaneseCommonTranslations from 'locales/ja/common.json';
// Define a type for representing plural forms based on Unicode Common Locale Data Repository (CLDR) pluralization rules
// @see https://cldr.unicode.org/index/cldr-spec/plural-rules
type PluralSuffixType = 'plural' | 'zero' | 'one' | 'other';

// Combine all common translations into a single type
// IMPORTANT: This type must be updated whenever a new translation is added
type AllCommonTranslations = typeof englishCommonTranslations &
  typeof germanCommonTranslations &
  typeof japaneseCommonTranslations;

// Define a union type for all possible translation keys in the `abtesting.json` file
export type TranslationKeyAbTests = keyof typeof abTestsTranslations;

// Define a union type for all possible translation keys in the `common.json` file
// This includes all top-level keys as well as any pluralized root keys
export type TranslationKeyCommon =
  // All translation keys in the common translation files
  | keyof AllCommonTranslations
  // All translation keys in the `abtesting.json` file, so we can use them in the app during A/B tests
  | TranslationKeyAbTests
  // All pluralized root keys in the common translation files
  | keyof {
      // Use a mapped type to extract the root key from any pluralized key
      // For example, if the key is "ensemble_plural", or "ensemble_zero" the root key is "ensemble"
      // Note: `_plural`, `_zero`, and other suffixes are automatically added to the translations via the `file_format: i18next_4` config in `phrase.yml`
      [K in keyof AllCommonTranslations as K extends `${infer Root}_${infer Suffix}`
        ? Suffix extends PluralSuffixType // Check if the key is a plural form key (e.g. "ensemble_plural")
          ? Root // If it is, use the root key as a valid translation key
          : never // If it's not, exclude it from the type
        : never]: string;
    };

// Define a union type for all possible translation keys in the `legal.json` file
export type TranslationKeyLegal = keyof typeof legalTranslations; // keys in the `legal.json` file
// Define a union type for all possible translation keys in the `seo.json` file
export type TranslationKeySeo = keyof typeof seoTranslations; // keys in the `seo.json` file
// Define a union type for all possible translation keys across all translation files
export type TranslationKey = TranslationKeyCommon | TranslationKeyLegal | TranslationKeySeo;

// A list of all supported locales based on the `locales` config in `i18n.js`
// When adding a new locale in `i18n.js`, make sure to add it here as well
export type SupportedLocale = 'en' | 'de' | 'ja';

// the potential namespaces for translation keys
export const TranslationNamespace = {
  Common: 'common',
  Legal: 'legal',
  Seo: 'seo',
  AbTesting: 'abtesting',
} as const;

// customized Next app properties that allow to pass custom layout options to the App component
export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
  hideNavigation?: boolean;
  headerComponent?: ComponentType;
  footerComponent?: ComponentType;
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

type VideoTypeWithWorks = VodConcertFieldsFragment & {
  performanceWorks?: PerformanceWorkFieldsFragment[];
};

//  a custom type for the video trailers that uses a subset of video data types
export type TrailerVideo = { __typename: 'Trailer'; id: string; title: string; stream: Omit<Stream, 'id'> };

// Common type for all API video types
export type VideoType = LiveConcertFieldsFragment | VideoFieldsFragment | VideoTypeWithWorks;

// the audio type used in players etc. comes from an album page, minus the curatedContent
export type AudioType = Omit<Exclude<AudioDetailPageQuery['album'], undefined>, 'curatedContent'>;

// define the types of API content that can be played, the UI will adapt depending on that type
export type PlaybackContentType = 'Video' | 'VodConcert' | 'LiveConcert' | 'Album';
// extract the type from the playback content query, otherwise it will include all root query types
export type PlayerContentQueryData = Extract<PlayerContentQuery['node'], Partial<{ __typename: PlaybackContentType }>>;
// types that can be put on the queue, includes playable data from the API and data synthesized in the app, e.g. trailers
export type PlayableMedia = PlayerContentQueryData | TrailerVideo;
// a more compact playable type, used when only fragment data is needed, e.g. in sliders or detail pages
export type PlayableMediaCompact = AudioType | VideoType | TrailerVideo;

export type AudioListItemData = {
  album: AlbumFieldsFragment;
  track: TrackFieldsFragment;
};

export type LinkAndButtonHandler = (event?: React.MouseEvent) => void;

// a compact representation of an individual work in a performance recording
// with less required fields
export type PerformanceWorkCompact = PerformanceWorkFieldsFragment;
export type LivePerformanceWorkCompact = LivePerformanceWorkFieldsFragment;

// landing page slider types
// Type definition of a Slider type returned by the landing page query
export type LandingPageSlider = Extract<
  LandingPageQuery['curatedContent']['curatedContent'][number],
  { __typename: 'ListSlider' }
>;
// specific types of items in the landing page slider
export type LandingPageLiveConcert = Extract<LandingPageSlider['items'][number], { __typename: 'LiveConcert' }>;
export type LandingPagePopularNow = Extract<LandingPageSlider['items'][number], { __typename: 'VodConcert' }>;
export type LandingPageNewAlbums = Extract<LandingPageSlider['items'][number], { __typename: 'Album' }>;

// a compact representation of a LiveConcert with less required fields
export type LiveConcertCompact = LiveConcertFieldsFragment;

export type FunctionalComponentWithChildren = React.FC<{
  children?: React.ReactNode;
}>;

export type EmptyPageParameters = Record<string, never>;
