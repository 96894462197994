import { useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { sprintf } from 'sprintf-js';
import usePhraseEditorKey from 'src/hooks/use-phrase-editor-key';
import { TranslationKeyCommon, TranslationKeyLegal, TranslationKeyAbTests, TranslationNamespace } from 'src/types';

// Returns a union of translation keys for either the `common` or `legal` namespace - defaults to `common`
type TranslationKey<T> = T extends typeof TranslationNamespace.Legal
  ? TranslationKeyLegal
  : T extends typeof TranslationNamespace.AbTesting
    ? TranslationKeyAbTests
    : TranslationKeyCommon;

/**
 * React hook for translations. Returns a function that accepts a translation key and options and returns a translated
 * string in the current language.
 *
 * By default, translation keys will be looked up in the `common` namespace. If you want to look up a translation key
 * in a different namespace, you can pass the namespace in the options object like this: `{ ns: 'myNamespace' }`.
 *
 * Interpolation for the translation key is supported with the following syntax:
 * - the i18next format uses `{{key}}`
 * - the sprintf format uses `%s`
 *
 * When using the i18next format, the options should be a key-value object.
 * When using the sprintf format, the options should be an array of strings.
 *
 * @example
 * // /locales/en/common.json
 * // {
 * //   "greeting": "Hello",
 * //   "greeting_name": "Hello, {{name}}!",
 * //   "greeting_name_sprintf": "Hello, %s!"
 * // }
 * // /locales/en/legal.json
 * // {
 * //   "greeting": "Dear"
 * // }
 * const t = useTranslate(); // namespace: 'common'
 * const tLegal = useTranslate(TranslationNamespace.Legal); // namespace: 'legal'
 *
 * t('greeting'); // 'Hello'
 * t('greeting_name', { name: 'John' }); // => 'Hello, John!'
 * t('greeting_x', ['John']); // => 'Hello, John!'
 * tLegal('greeting'); // => 'Dear'
 */
export default function useTranslate<T extends (typeof TranslationNamespace)[keyof typeof TranslationNamespace]>(
  // Default to the `common` namespace when it is `undefined`
  namespace: T = TranslationNamespace.Common as T,
): (key: TranslationKey<T>, options?: Record<string, unknown> | (string | number)[]) => string {
  const { t } = useTranslation(namespace);
  const phraseEditorKey = usePhraseEditorKey();

  return useCallback(
    (key, options = {}) => {
      // If the phrase editor is enabled, return the phrase editor key instead of the translation
      if (phraseEditorKey) {
        return phraseEditorKey(key);
      }
      // If options is an array, use sprintf for interpolation
      if (Array.isArray(options)) {
        return sprintf(t(key), ...options);
      }
      // Otherwise, use the i18next interpolation function
      const translation = t(key, options);
      // If the translation is missing, the `next-translate` library returns the key as the translation and logs a warning
      // We want to return an empty string instead of the key if the translation is missing
      if (translation === key) return '';
      // Otherwise, return the translation
      return translation;
    },
    [t, phraseEditorKey],
  );
}
