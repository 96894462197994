import { SubscriptionPlanStripe, Price } from 'generated/graphql';
import { CurrencyCode, defaultCurrencyCode } from 'src/utilities/currency-code-helpers';

type PriceWithTypedCurrency = Price & { currency: CurrencyCode };

/**
 * Helper function to get the preferred price for the currently selected plan.
 * @param subscriptionPlan The currently selected plan.
 * @param currency The currency to get the price in.
 * @returns The price object for the given currency.
 */
export function getPrice(
  subscriptionPlan: SubscriptionPlanStripe,
  currency: CurrencyCode = defaultCurrencyCode,
): PriceWithTypedCurrency {
  const price = subscriptionPlan.prices.find((price): price is PriceWithTypedCurrency => price.currency === currency);
  if (!price) {
    throw new Error(`No price found for currency ${currency}`);
  }
  return price;
}
