import {
  Album,
  Artist,
  Epoch,
  Favorite,
  Genre,
  GeoAccessMode,
  Group,
  Label,
  LiveConcert,
  LivePerformanceWork,
  Movement,
  Partner,
  PerformanceWork,
  Picture,
  PlaybackProgress,
  Role,
  Slider,
  Track,
  Video,
  VodConcert,
  Work,
} from 'generated/graphql';

// All possible content node types (i.e. all implemented types of the `Node` type)
export type ContentAvailabilityNode =
  | Pick<
      | Album
      | Artist
      | Epoch
      | Favorite
      | Genre
      | Group
      | Label
      | LivePerformanceWork
      | Movement
      | Partner
      | PerformanceWork
      | Picture
      | PlaybackProgress
      | Role
      | Slider
      | Track
      | Work,
      '__typename'
    >
  | Pick<Video, '__typename' | 'geoAccessCountries' | 'geoAccessMode' | 'takedownDate' | 'archiveReleaseDate'>
  | Pick<VodConcert, '__typename' | 'geoAccessCountries' | 'geoAccessMode' | 'takedownDate' | 'archiveReleaseDate'>
  | Pick<LiveConcert, '__typename' | 'geoAccessCountries' | 'geoAccessMode' | 'finalEndTime' | 'plannedForVod'>;

export const ContentAvailability = {
  Available: 'AVAILABLE', // Returned when the content is genrally available (published and not geo blocked)
  GeoBlocked: 'GEO_BLOCKED', // Returned when the content is geo blocked (not available in the current country)
  NotPublished: 'NOT_PUBLISHED', // Returned when the content is not published (still archived or already taken down)
} as const;

/**
 * Returns the availability mode of the given content node (available, geo blocked, or not published)
 */
export function getContentAvailability(
  node: ContentAvailabilityNode,
  options?: { countryCode?: string },
): (typeof ContentAvailability)[keyof typeof ContentAvailability] {
  const isoDateString = new Date().toISOString();

  /**
   * Checks whether the content is geo blocked for the given country code
   */
  function isGeoBlocked(geoAccessCountries: string[], geoAccessMode: GeoAccessMode) {
    // if we don't have a country code, assume it's not geo blocked
    if (!options?.countryCode) return false;
    // If the geo access mode is an allow list, check whether the current country is in the list of allowed countries
    if (geoAccessMode === GeoAccessMode.Allow) return !geoAccessCountries.includes(options.countryCode);
    // If the geo access mode is an deny list, check whether the current country is NOT in the list of denied countries
    if (geoAccessMode === GeoAccessMode.Deny) return geoAccessCountries.includes(options.countryCode);
    // Otherwise, always allow access
    return false;
  }

  switch (node.__typename) {
    case 'LiveConcert': {
      // If the final end time of the LiveConcert is in the past and it is not planned to become a VodConcert, it is not considered published
      if (node.finalEndTime < isoDateString && !node.plannedForVod) return ContentAvailability.NotPublished;
      // If the current country is not in the list of allowed countries, the LiveConcert is geo blocked
      if (isGeoBlocked(node.geoAccessCountries, node.geoAccessMode)) return ContentAvailability.GeoBlocked;
      // Otherwise, the LiveConcert is available
      return ContentAvailability.Available;
    }
    case 'Video':
    case 'VodConcert': {
      // If the archive release date of the Video or VodConcert is in the future, or the takedown date is in the past, it is not considered published
      if (
        (node.archiveReleaseDate || isoDateString) > isoDateString ||
        (node.takedownDate || isoDateString) < isoDateString
      )
        return ContentAvailability.NotPublished;
      // If the current country is not in the list of allowed countries, the Video or VodConcert is geo blocked
      if (isGeoBlocked(node.geoAccessCountries, node.geoAccessMode)) return ContentAvailability.GeoBlocked;
      // Otherwise, the Video or VodConcert is available
      return ContentAvailability.Available;
    }
    default: {
      return ContentAvailability.Available;
    }
  }
}

/**
 * Helper function that returns whether the given content node is generally available (published and not geo blocked)
 */
export function isContentAvailable(node: ContentAvailabilityNode, options?: { countryCode?: string }): boolean {
  return getContentAvailability(node, options) === ContentAvailability.Available;
}
