import { useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useCouponCode } from 'src/hooks/use-coupon-code';

const couponCodeQueryKey = 'coupon';

/**
 * A hook that retrieves a coupon code from the query string, stores it in the
 * global state, and removes it from the url. (e.g. `?coupon=30PERCENTOFF`)
 */
export function useCouponCodeFromQuery(): void {
  const { query, isReady, replace, pathname } = useRouter();
  const { setCouponCode } = useCouponCode();

  // Get the coupon code from the query string
  const couponCodeFromQuery = useMemo(() => {
    return isReady && typeof query.coupon === 'string' && query.coupon.length > 0 ? query.coupon : undefined;
  }, [isReady, query]);

  // Remove the coupon code from the query string
  const removeCouponCodeFromQuery = useCallback(async () => {
    delete query[couponCodeQueryKey];
    await replace({ pathname, query });
  }, [pathname, query, replace]);

  // Set the coupon code in the global state and remove it from the query string
  useEffect(() => {
    async function run(): Promise<void> {
      if (couponCodeFromQuery) {
        try {
          // Verify the coupon code and store it in the global state
          await setCouponCode(couponCodeFromQuery);
        } catch (error) {
          /** @todo Decide whether to continue silently ignoring the invalid coupon code, or show a message to the user. */
          console.error('Error setting coupon code from query:', error);
        }
        // Remove the coupon code from the query string
        await removeCouponCodeFromQuery();
      }
    }
    void run();
  }, [couponCodeFromQuery, removeCouponCodeFromQuery, setCouponCode]);
}
