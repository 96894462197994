import { useState, useEffect, useCallback } from 'react';
import useCurrentUser from 'src/hooks/use-current-user';
import { useSubscriptionModal } from 'src/hooks/use-modal';
import { VodConcert, LiveConcert, Album, PublicationLevel, Video } from 'src/hooks/use-sdk';
import { getTicketStatus } from 'src/hooks/use-ticket-status';
import { useAuthModal } from 'src/state/auth';
import { TrailerVideo } from 'src/types';

/**
 * A hook to verify the permissions of the current user.
 * Allows to check if the user has the required permissions to access a certain media.
 * And if not, it can open the required modals to allow the user to get the required permissions.
 */
export default function usePermissions() {
  const { currentUser, isLoading: isCurrentUserLoading } = useCurrentUser();

  const { open } = useAuthModal();
  const { open: openSubscriptionModal } = useSubscriptionModal();
  // check if the current user data was loaded
  const userLoaded = !!currentUser || !isCurrentUserLoading;
  // the permissions are ready when the current user data is loaded
  const [permissionsReady, setPermissionsReady] = useState(userLoaded);

  // we'll update the permissions ready state when the current user is loaded
  // and only on the client with useEffect, to avoid SSR issues
  useEffect(() => {
    setPermissionsReady(userLoaded);
  }, [userLoaded, setPermissionsReady]);

  type Requirements = 'isLoading' | 'allGood' | 'needsSignup' | 'needsVerification' | 'needsSubscription';

  type RequirementMap = {
    beLoggedIn: boolean;
    beVerified: boolean;
    beSubscribed: boolean;
  };

  type MediaToCheck =
    | Pick<VodConcert | Video | LiveConcert, '__typename' | 'publicationLevel'>
    | Pick<Album, '__typename'>
    | Pick<TrailerVideo, '__typename'>;

  const fulfillsRequirement = useCallback(
    ({ beLoggedIn, beVerified, beSubscribed }: RequirementMap): Requirements => {
      // if no account needed, return true immediately
      if (beLoggedIn === false) {
        return 'allGood';
      }
      // if permissions are not ready yet, return loading
      if (!permissionsReady) {
        return 'isLoading';
      }
      // does the user need to have an account?
      if (beLoggedIn && !currentUser) {
        return 'needsSignup';
      }
      // does the user have to have a verified email?
      // note: this rule doesn't apply if user has registered less than 48 hours ago
      const twoDays = 2 * 24 * 60 * 60 * 1000;
      if (
        beVerified &&
        currentUser?.emailVerified !== true &&
        currentUser?.insertedAtDate &&
        currentUser?.insertedAtDate < Date.now() - twoDays
      ) {
        return 'needsVerification';
      }

      // does the user have to have a subscription ticket that allows playback?
      const hasValidSubscription = getTicketStatus(currentUser?.ticket).isValid;

      // does the user have to have an active subscription?
      if (beSubscribed && !hasValidSubscription) {
        if (currentUser?.ticket) {
          // warn if the ticket exists but is not valid, this should not happen
          // but it does this should help debugging locally or in the tests
          console.warn('Ticket is not valid!', currentUser?.ticket);
        }
        // this user needs a valid subscription
        return 'needsSubscription';
      }
      return 'allGood';
    },
    [currentUser, permissionsReady],
  );

  const openRequiredModalsFor = useCallback(
    (requirement: Requirements) => {
      switch (requirement) {
        case 'needsSignup': {
          open('signup');
          break;
        }
        case 'needsVerification': {
          open('verify');
          break;
        }
        case 'needsSubscription': {
          openSubscriptionModal(undefined);
          break;
        }
        default: {
          // no modal required
          break;
        }
      }
      return requirement;
    },
    [open, openSubscriptionModal],
  );

  const checkCanPlayPublicationLevel = (publicationLevel?: PublicationLevel) =>
    fulfillsRequirement({
      beLoggedIn: publicationLevel !== PublicationLevel.NoAuthentication,
      beVerified:
        publicationLevel === PublicationLevel.AccountRequired || publicationLevel === PublicationLevel.TicketRequired,
      beSubscribed: publicationLevel === PublicationLevel.TicketRequired,
    });

  const checkMediaRequirements = (media: MediaToCheck) => {
    let publicationLevel: PublicationLevel;
    switch (media.__typename) {
      case 'Album': {
        // Album has no publicationLevel currently,
        // so we assume it always requires a ticket for now
        publicationLevel = PublicationLevel.TicketRequired;
        break;
      }
      case 'Trailer': {
        // Trailer streams are always free to watch
        publicationLevel = PublicationLevel.NoAuthentication;
        break;
      }
      default: {
        // in all other cases, we use the publicationLevel from the media
        publicationLevel = media.publicationLevel;
      }
    }
    return checkCanPlayPublicationLevel(publicationLevel);
  };

  const checkCanPlayMedia = (media: MediaToCheck) => {
    const checkResult = checkMediaRequirements(media);
    return checkResult === 'allGood';
  };

  const checkCanFavoriteMedia = () => {
    const checkResult = fulfillsRequirement({ beLoggedIn: true, beVerified: true, beSubscribed: false });
    return checkResult === 'allGood';
  };

  const openRequiredModalsToFavoriteMedia = () => {
    const checkResult = fulfillsRequirement({ beLoggedIn: true, beVerified: true, beSubscribed: false });
    return openRequiredModalsFor(checkResult);
  };

  const openRequiredModalsToPlayMedia = (media: MediaToCheck) => openRequiredModalsFor(checkMediaRequirements(media));

  return {
    permissionsReady,
    /**
     * Check if the current user can play content
     */
    checkCanPlayMedia,
    /**
     * Precise info on what current user needs to play content
     */
    checkMediaRequirements,
    /**
     * open the required modals for the current user to play content
     */
    openRequiredModalsToPlayMedia,

    /**
     * Check if the current user can a favorite content
     */
    checkCanFavoriteMedia,
    /**
     * open the required modals for the current user to favorite content
     */
    openRequiredModalsToFavoriteMedia,
  };
}
