// load only the types and not the whole HLS.js library
import { RefObject } from 'react';
import type { Level, LoaderResponse } from 'hls.js';
import type Hls from 'hls.js';
import { MaxResolution } from 'generated/graphql';
import { ClientDevice, getClientDevice } from 'src/utilities/device-info';
import { captureException } from 'src/utilities/exceptions';
import { HlsJsIsSupported } from 'src/utilities/hls-js-extracted';
import { canPlayDolbyAtmos, canPlayHighBandwidthVideo, canPlayNativeHls } from 'src/utilities/media-capabilities';
import { Token } from 'src/utilities/token';

// a list of the names of the HLS audio streams used in the DG infrastructure
const AudioStreamQualities = {
  sd: 'sd', // the standard quality album stream
  hd: 'hd', // the high quality album stream
  stereoGood: 'stereogood', // the standard quality audio stream in the video
  stereoHigh: 'stereohigh', // the high quality audio stream in the video
  atmos: 'atmos', // the dolby atmos audio stream
  lossless: 'lossless', // the flac album stream
} as const;

/**
 * Extend the HTMLMediaElement interface to support the audioTracks property
 * https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/audioTracks
 */
declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLMediaElement {
    audioTracks?: Iterable<MediaStreamTrack> & {
      addEventListener: (
        event: 'addtrack' | 'removetrack' | 'change',
        callback: (event: Event & { track: MediaStreamTrack }) => void,
      ) => void;
      removeEventListener: (
        event: 'addtrack' | 'removetrack' | 'change',
        callback: (event: Event & { track: MediaStreamTrack }) => void,
      ) => void;
      length: number;
      getTrackById: (id: string) => MediaStreamTrack | undefined;
    };
  }
}

// the type of the supported audio stream qualities
export type AudioStreamQuality = (typeof AudioStreamQualities)[keyof typeof AudioStreamQualities];

// the max bandwidth for low data mode
const lowRateThresholdAudioKbps = 300;
const lowRateThresholdVideoKbps = 2000;

/**
 * a helper to remove the high bitrate levels from the HLS stream
 **/
const removeHighBitrateLevels = (hls: Hls) => {
  // remove all the levels except the lowest ones
  hls.levels
    .map((level: Level, index) => {
      //  check if we have an audio stream
      const isAudio = !level.videoCodec;
      // audio and video streams have different low data caps
      const capBitrate = isAudio ? lowRateThresholdAudioKbps : lowRateThresholdVideoKbps;
      //  get the indexes of the levels that are above the cap
      if (level.bitrate > capBitrate * 1000) {
        return index;
      }
      return null;
    })
    // reverse the array so the mutations don't affect the level array indices
    .reverse()
    .map((levelIndex) => {
      // remove the unneeded levels from the list,
      // make sure we don't remove the last level, otherwise there's nothing left to play
      if (levelIndex !== null && hls.levels.length > 1) {
        hls.removeLevel(levelIndex);
      }
    });
};

/**
 * A helper to adjust the level controller to the users preferences
 * @param hls the HLS.js streaming engine for the given stream
 * @param lowDataMode boolean to enable low data mode
 * @param preferDolbyAtmos boolean to enable dolby atmos if available
 * @returns
 */
export const adjustToStreamingSettingsHlsJs = ({
  hls,
  lowDataMode,
  preferDolbyAtmos,
  preferHighestVideoQuality,
}: {
  hls: Hls;
  lowDataMode: boolean;
  preferDolbyAtmos: boolean;
  preferHighestVideoQuality: boolean;
}) => {
  // if we are in low data mode, we need to adjust the level controller to the low data streaming settings
  if (lowDataMode) {
    // turn on the automatic level selection, so the level controller will automatically switch in case of errors
    hls.nextLevel = -1;
    hls.autoLevelCapping = -1;
    removeHighBitrateLevels(hls);
    // reset to the first level, just in case that it has already jumped to the level that we have removed
    hls.currentLevel = 0;
    // also try to find the lowest bandwidth audio track
    const lowestQualityAudioTrack = hls.allAudioTracks.find(
      (track) => track.name === AudioStreamQualities.stereoGood || track.name === AudioStreamQualities.sd,
    );
    // switch to the lowest audio track if there's one
    hls.setAudioOption(lowestQualityAudioTrack);
    return;
  }

  // check if we can play dolby atmos,
  // important because the preference might be set to true even if the client can't play it right now
  const tryToFindDolbyAtmos = preferDolbyAtmos && canPlayDolbyAtmos();
  // if we can play dolby atmos and user wants to, try to find the dolby atmos audio track
  const dolbyAtmosAudioTrack =
    tryToFindDolbyAtmos && hls.allAudioTracks.find((track) => track.name === AudioStreamQualities.atmos);
  // check if the client can play lossless audio
  const losslessAudioTrack = hls.allAudioTracks.find((track) => track.name === AudioStreamQualities.lossless);
  // otherwise, lets try to find the best audio track
  const bestQualityAudioTrack = hls.allAudioTracks.find(
    (track) => track.name === AudioStreamQualities.stereoHigh || track.name === AudioStreamQualities.hd,
  );

  if (dolbyAtmosAudioTrack) {
    // if the client can play dolby atmos and user wants to, switch to it automatically
    hls.setAudioOption(dolbyAtmosAudioTrack);
  } else if (losslessAudioTrack) {
    // if the client can play lossless audio, switch to it automatically
    hls.setAudioOption(losslessAudioTrack);
  } else {
    // switch to the best audio track if there's one
    hls.setAudioOption(bestQualityAudioTrack);
  }

  // assume that all desktops and tablets can play the high-quality video track
  const canPlayHighBandwidth = canPlayHighBandwidthVideo();
  // lets try to play the best video track if we should and we can
  if (preferHighestVideoQuality && canPlayHighBandwidth) {
    // first, try to find the highest bitrate level of all supported codecs
    const highestQualityLevel = hls.maxAutoLevel;
    // manually set the maximum supported level to the best quality
    hls.autoLevelCapping = highestQualityLevel;
    // try to switch to the highest available video level
    hls.nextLevel = highestQualityLevel;
  } else {
    // otherwise, turn on the automatic level selection, so the level controller will switch in case of errors
    hls.nextLevel = -1;
    hls.autoLevelCapping = -1;
  }
};

/**
 * A helper to switch to a specific native HLS audio track
 */
export const switchToAudioTrackNativeHls = (audioTracks: MediaStreamTrack[], trackToActivate: MediaStreamTrack) => {
  for (const track of audioTracks) {
    // iterate over all tracks, enable or disable depending which one we want to play
    track.enabled = track.id === trackToActivate.id;
  }
};

/**
 * A helper to adjust the level controller to the users preferences
 * @param videoElement the HLS.js streaming engine for the given stream
 * @param lowDataMode boolean to enable low data mode
 * @param preferDolbyAtmos boolean to enable dolby atmos if available
 * @returns
 */
export const adjustToStreamingSettingsNativeHls = ({
  videoElement,
  lowDataMode,
  preferDolbyAtmos,
}: {
  videoElement: HTMLVideoElement | null | undefined;
  lowDataMode: boolean;
  preferDolbyAtmos: boolean;
  preferHighestVideoQuality: boolean;
}) => {
  // if the audio tracks are not available, we have not much to do here
  if (!videoElement?.audioTracks) {
    return;
  }
  const audioTracksArray = [...videoElement.audioTracks];
  // if we are in low data mode, we need to adjust the level controller to the low data streaming settings
  if (lowDataMode) {
    // find the lowest quality audio track
    const lowestQualityAudioTrack = audioTracksArray.find(
      (track) => track.label === AudioStreamQualities.stereoGood || track.label === AudioStreamQualities.sd,
    );
    // switch to the lowest audio track if there's one
    if (lowestQualityAudioTrack) {
      switchToAudioTrackNativeHls(audioTracksArray, lowestQualityAudioTrack);
    }
    // no need to continue here with audio selection, it should either be lowest quality or automatic
    return;
  }

  // check if we can play dolby atmos
  const canPlayDolbyAtmosAudio = canPlayDolbyAtmos();
  // if we can play dolby atmos and user wants to, try to switch to it
  if (preferDolbyAtmos && canPlayDolbyAtmosAudio) {
    // find the id of the dolby atmos audio track
    const dolbyAtmosAudioTrack = audioTracksArray.find((track) => track.label === AudioStreamQualities.atmos);
    if (dolbyAtmosAudioTrack) {
      switchToAudioTrackNativeHls(audioTracksArray, dolbyAtmosAudioTrack);
      return;
    }
  }

  // try to find a lossless audio track
  const losslessAudioTrack = audioTracksArray.find((track) => track.label === AudioStreamQualities.lossless);
  // switch to the lossless audio track if there's one
  if (losslessAudioTrack) {
    switchToAudioTrackNativeHls(audioTracksArray, losslessAudioTrack);
    return;
  }
  // otherwise, lets try to find the best audio track
  const bestQualityAudioTrack = audioTracksArray.find(
    (track) => track.label === AudioStreamQualities.stereoHigh || track.label === AudioStreamQualities.hd,
  );
  // switch to the best audio track if there's one
  if (bestQualityAudioTrack) {
    switchToAudioTrackNativeHls(audioTracksArray, bestQualityAudioTrack);
    return;
  }
};
/**
 * Check how the browser will play the HLS stream
 * @param videoElRef the video element reference
 */
export const hlsPlaybackSupport = () => {
  // quick return if we are not in the browser
  if (typeof window === 'undefined') return null;
  // check if the browser supports hls.js decoding
  const supportsHlsJs = HlsJsIsSupported();
  // check if the browser can play native HLS
  const supportsNativeHls = canPlayNativeHls();
  // get the client device
  const clientDevice = getClientDevice();

  if (clientDevice === ClientDevice.WebAndroid && supportsHlsJs) {
    // on Android, we will try to always use hls.js if we can, as the native HLS player seems to have issues
    // with our stream encoding throwing 'PIPELINE_ERROR_EXTERNAL_RENDERER_FAILED' errors
    return 'hlsJs';
  } else if (supportsNativeHls) {
    // if the browser can play native HLS, we will use that, e.g. Safari, iOS
    return 'hlsNative';
  } else if (supportsHlsJs) {
    // if the browser can play HLS using hls.js, we will rely on that then,
    // the decoding using the MSE is a bit more CPU intensive compared to native HLS though
    return 'hlsJs';
  } else {
    return null;
  }
};

type NativeHlsParameters = {
  videoElRef: RefObject<HTMLVideoElement>;
  streamUrl: string | undefined;
  onError?: (code: MediaError['code'], message: MediaError['message']) => void;
  onReady?: () => void;
  onAudioChange?: () => void;
};

/**
 * Set up the native HLS video player, e.g. Safari iOS
 * @returns object with a cleanup function
 */
export const setupNativeHls = ({ videoElRef, streamUrl, onError, onReady, onAudioChange }: NativeHlsParameters) => {
  const errorHandler = () => {
    const error = videoElRef.current?.error;
    if (!error) {
      return;
    }
    // in some cases the error properties might have to be added
    const code = error.code || 0;
    const message = error.message || 'Native HLS error: No Message';
    // log the exception, e.g. to Sentry
    captureException(error);
    // execute the error callback, e.g. display an error message on screen
    onError?.(code, message);
  };

  const readyHandler = () => {
    onReady?.();
  };

  const audioChannelsChangeHandler = () => {
    onAudioChange?.();
  };

  const cleanupNativeHls = () => {
    const videoElement = videoElRef.current;
    if (videoElement) {
      videoElement.pause();
      videoElement.src = '';
      videoElement.removeEventListener('error', errorHandler);
      videoElement.removeEventListener('loadeddata', readyHandler);
      videoElement.audioTracks?.removeEventListener('change', audioChannelsChangeHandler);
    }
  };

  const videoElement = videoElRef.current;
  if (streamUrl && videoElement !== null) {
    videoElement.src = streamUrl;
    // capture the errors to Sentry when using the native HLS playback
    videoElement.addEventListener('error', errorHandler);
    // execute the ready callback when the video is ready to play and we have the first frame with audio and video channels
    videoElement.addEventListener('loadeddata', readyHandler);
    // execute the audio change callback when the audio channels change
    videoElement.audioTracks?.addEventListener('change', audioChannelsChangeHandler);
  }

  return { cleanupNativeHls };
};

/**
 * Return the authenticated stream url based on the stream url and the access token
 *
 * Optional `resolveUrl` parameter can be used to resolve the stream url to the CDN url,
 * used for setting the video src when a normal HTTP redirect including cookies is not possible (e.g. HLS.js)
 *
 * @example
 * ```ts
 * const streamUrl = 'https://live.stage-plus.com/stream/1234';
 * const accessToken = 'abc1234';
 * const authenticatedStreamUrl = getAuthenticatedStreamUrl(streamUrl, accessToken);
 * // https://live.stage-plus.com/stream/1234?token=abc1234
 */
export async function getAuthenticatedStreamUrl(
  streamUrl: string,
  {
    accessToken,
    resolveUrl = false,
  }: {
    /**
     * The access token to use for the stream url
     * @default undefined
     */
    accessToken?: Token;
    /**
     * Whether to resolve the stream url to the CDN url
     * @default false
     */
    resolveUrl?: boolean;
  },
): Promise<string> {
  const url = new URL(streamUrl);

  // backend can handle free content without token
  if (accessToken) {
    url.searchParams.set('token', accessToken);
  }

  // Resolve the CDN url from the stream url as a JSON response
  if (resolveUrl) {
    // Setting `redirect` to false will return the CDN url as a JSON response instead of doing a redirect
    url.searchParams.set('redirect', 'false');
    // Request the stream url with the token and disabled redirect to get the CDN url
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) throw new Error(response.statusText);
    const responseJSON = (await response.json()) as { cdn_url: string };
    if (!responseJSON.cdn_url) throw new Error('Response does not contain a cdn url');
    return responseJSON.cdn_url;
  }

  return url.toString();
}

/**
 * A special HLS manifest playlist post-processing function
 * that fixes the legacy manifest format issues with hls.js
 * This allows us to avoid re-encoding of the existing HLS content
 */
export function processPlaylistManifest(playlist: LoaderResponse['data']) {
  if (typeof playlist === 'string') {
    return (
      playlist
        // since Hls.js@1.5.0, the library checks only the first audio codec in rendition
        // if it's unsupported, it drops it from the list
        // our Dolby Atmos video manifests e.g.
        // /video/vod_concert_APNM8GRFDPHMASJKBSQ32C0 -> ec-3,mp4a.40.2
        // /video/vod_concert_APNM8GRFDPHMASJKBSQ3CD0 -> ec-3,hvc1.1.6.L90.B0,mp4a.40.2
        // contain double-codec renditions that are officially unsupported by Hls.js
        // As a workaround, we rewrite the manifest to have only one audio codec in each rendition
        // The Dolby Atmos is not supported by Hls.js, so we remove it from the list
        // @todo [2025-02-12] check with the streaming engineer if the dolby atmos manifests were rewritten to have single-audio-codec in one rendition
        // if so, the replacement rule can be taken out
        .replaceAll(/(ec-3,)(.*mp4a.)/gi, '$2') // keep in mind that the comma is important to avoid removing the codec from the video renditions
    );
  }
  return playlist;
}

/**
 * Extracts the relevant stream features from the media object,
 * e.g. duration, resolution, etc.
 * @param media
 */
export function useStreamFeatures(
  media:
    | {
        totalDuration?: number;
        duration?: number;
        maxResolution?: MaxResolution;
        isAtmos?: boolean;
        atmosUpc?: string;
        isLossless?: boolean;
        __typename: string;
      }
    | undefined,
) {
  if (!media) {
    return {
      durationToDisplay: undefined,
      availableIn4K: false,
      availableInDolbyAtmos: false,
      isLossless: false,
      isAudio: false,
    };
  }
  // check if we can get the duration to display it
  const totalDuration = media && 'totalDuration' in media ? media.totalDuration : undefined;
  const duration = media && 'duration' in media ? media.duration : undefined;
  const durationToDisplay = totalDuration || duration;
  // check if we can get the resolution to display it
  const availableIn4K = 'maxResolution' in media && media.maxResolution === MaxResolution.Resolution_4K;
  // read the available formats from the API data
  const availableInDolbyAtmos =
    ('isAtmos' in media && media.isAtmos) || ('atmosUpc' in media && Boolean(media.atmosUpc));
  const isLossless = 'isLossless' in media && media.isLossless;
  // audio albums display a slightly different info
  const isAudio = media?.__typename === 'Album';

  return {
    durationToDisplay,
    availableIn4K,
    availableInDolbyAtmos,
    isLossless,
    isAudio,
  };
}
