import { cloneElement, ReactElement, ComponentProps } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';

type NavigationLinkProps = ComponentProps<typeof Link>;

/**
 * Extends the basic Link component by checking the active route
 * Also passes an `active` prop to the main child component
 *
 * @example
 * function NavigationItem({ active = false, children }) {
 *   return <span style={{ color: active ? 'red': 'black' }}>{children}</span>
 * }
 *
 * // Will render a red link on the `/live` path
 * <NavigationLink href="/live">
 *   <NavigationItem>Live</NavigationItem>
 * </NavigationLink>
 *
 */
export default function NavigationLink({ className, ...props }: NavigationLinkProps) {
  const router = useRouter();
  const href = typeof props.href === 'string' ? props.href : props.href.pathname;
  // Check if the currently active route is under the same scope as the given href
  const isCurrent =
    href === '/'
      ? href === router.route // if href is the root path, do a direct comparison
      : href && router.route.startsWith(href); // otherwise, check if the route starts with the href – this also matches dynamic routes and query params (e.g. `/audio/:pid` matches `/audio`)

  return (
    <Link {...props} className={clsx(className, 'group outline-none')} aria-current={isCurrent ? 'page' : undefined}>
      {/* Pass an `active` prop to the child component */}
      {props.children &&
        cloneElement(props.children as ReactElement, {
          active: isCurrent,
        })}
    </Link>
  );
}
