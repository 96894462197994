import { Fragment, PropsWithChildren } from 'react';
import RenderClientOnly from 'src/components/render-client-only';
import { useIsAuthenticated } from 'src/state/auth';

type AuthenticatedOnlyProps = PropsWithChildren<unknown>;

/**
 * React component which children are only rendered when there is an active authenticated user session
 *
 * @example
 * <AuthenticatedOnly>
 *   <FavoriteToggle id={id} />
 * </AuthenticatedOnly>
 */
export default function AuthenticatedOnly({ children }: AuthenticatedOnlyProps) {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) return null;

  return (
    <RenderClientOnly>
      <Fragment>{children}</Fragment>
    </RenderClientOnly>
  );
}
