import { pushToGoogleTagManager } from 'src/tracking/gtm';
import { TrackingContextValue } from 'src/tracking/tracking-context';
import { TranslationKey } from 'src/types';

type FormInputError = { message?: string };

// All user-relation tracking events should be defined here

type TrackedSignupMethod = 'Email' | 'Facebook' | 'Google' | 'Twitter' | 'Linkedin' | 'Apple';
export type SignupEventPayload =
  | { eventName: 'SignupDisplayed'; method: TrackedSignupMethod; displayContext: TrackingContextValue }
  | {
      eventName: 'SignupSuccess';
      method: TrackedSignupMethod;
      displayContext: TrackingContextValue;
    }
  | {
      eventName: 'SignupError';
      errors?: Record<string, FormInputError | undefined>;
      message?: string;
      method: TrackedSignupMethod;
      displayContext: TrackingContextValue;
    };
export type SignupConfirmationEventPayload = {
  eventName: 'SignupConfirmationSuccess' | 'SignupConfirmationFailure' | 'SignupConfirmationRequired';
};

export type LoginEventPayload =
  | { eventName: 'LoginDisplayed'; method: TrackedSignupMethod; displayContext: TrackingContextValue }
  | {
      eventName: 'LoginSuccess';
      method: TrackedSignupMethod;
      displayContext: TrackingContextValue;
    }
  | {
      eventName: 'LoginError';
      errors?: Record<string, FormInputError | undefined>;
      message?: string;
      method: TrackedSignupMethod;
      displayContext: TrackingContextValue;
    };

export type LogoutEventPayload = {
  eventName: 'Logout';
};

export type NewsletterEventPayload =
  | { eventName: 'NewsletterStart' | 'NewsletterSuccess'; newsletterName: string; addressBookId: string }
  | {
      eventName: 'NewsletterConfirmation';
      newsletterName: string;
      confirmationState: 'success' | 'failure';
      addressBookId: string;
    }
  | {
      eventName: 'NewsletterError';
      newsletterName: string;
      error: FormInputError;
      addressBookId: string;
    };

export type ForgotPasswordEventPayload =
  | {
      eventName: 'ForgotPasswordDisplayed' | 'ForgotPasswordSuccess';
      displayContext: TrackingContextValue;
    }
  | {
      eventName: 'ForgotPasswordError';
      errors?: Record<string, FormInputError | undefined>;
      message?: string;
      displayContext: TrackingContextValue;
    };

export type UserPropertiesEventPayload = {
  eventName: 'UserProperties';
  userType: string;
  userId?: string;
  abTests?: { name: string; variant: string }[];
};

export type CtaSignupLoginEventPayload = {
  eventName: 'CtaSignup' | 'CtaLogin' | 'CtaForgotPassword';
  label: string;
  displayContext: TrackingContextValue;
  cta: {
    id: TranslationKey;
    label: string;
  };
};

/** convert optional field errors to an error event label string */
const formErrorsToTrackingLabel = (errors?: Record<string, FormInputError | undefined>, message?: string) => {
  const errorLabel = errors
    ? Object.entries(errors)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(([_key, value]) => value?.message)
        .join('')
    : message;

  return errorLabel || 'Unknown Error';
};

// default info on each CTA event
const ctaTrackingEventInfo: Record<CtaSignupLoginEventPayload['eventName'], { name: string; action: string }> = {
  CtaLogin: {
    name: 'login_start',
    action: 'Login',
  },
  CtaSignup: {
    name: 'sign_up_start',
    action: 'Registration',
  },
  CtaForgotPassword: {
    name: 'forgot_password_start',
    action: 'Forgot Password',
  },
};

/**
 * Track Signup or Login CTA button clicks
 */
export const trackSignupLoginCta = ({ eventName, displayContext, cta }: CtaSignupLoginEventPayload) => {
  const trackedEventName = ctaTrackingEventInfo[eventName].name;
  pushToGoogleTagManager({
    event: 'Generic Event',
    event_name: trackedEventName,
    [trackedEventName]: {
      display_context: displayContext,
      cta_id: cta.id,
      cta_label: cta.label,
    },
  });
};

/**
 * Track the signup events
 * based on the spec from:
 * https://guide.trakken.de/5f807081e90fd60009b13d1b/published/60829afcb4ea24000aab8185#chapter3.1.1
 */

export const trackSignup = (payload: SignupEventPayload) => {
  const { eventName, method, displayContext } = payload;
  switch (eventName) {
    case 'SignupError': {
      const { errors, message } = payload;
      pushToGoogleTagManager({
        event: 'Generic Event',
        event_name: 'sign_up_fail',
        sign_up_fail: {
          method,
          error_message: formErrorsToTrackingLabel(errors, message),
          display_context: displayContext,
        },
      });
      break;
    }
    case 'SignupDisplayed': {
      pushToGoogleTagManager({
        event: 'Generic Event',
        event_name: 'sign_up_form_impression',
        sign_up_form_impression: {
          method,
          display_context: displayContext,
        },
      });
      break;
    }
    case 'SignupSuccess': {
      pushToGoogleTagManager({
        event: 'Generic Event',
        event_name: 'sign_up_submitted',
        sign_up_submitted: {
          method,
          display_context: displayContext,
        },
      });
      break;
    }
    // No default
  }
};

/**
 * Track user Signup confirmation events
 * @param payload
 */
export const trackSignupConfirmation = (payload: SignupConfirmationEventPayload) => {
  const { eventName } = payload;

  switch (eventName) {
    // track when we ask the user to confirm their email
    case 'SignupConfirmationRequired': {
      pushToGoogleTagManager({
        event: 'Generic Event',
        event_name: 'sign_up_verification_needed',
        sign_up_verification_needed: {},
      });
      break;
    }
    // track when user confirms their email succesfully
    case 'SignupConfirmationSuccess': {
      pushToGoogleTagManager({
        event: 'Generic Event',
        event_name: 'sign_up_confirmed',
        sign_up_confirmed: {},
      });
      break;
    }
    // track when user email confirmation fails
    case 'SignupConfirmationFailure': {
      pushToGoogleTagManager({
        event: 'Generic Event',
        event_name: 'sign_up_confirm_fail',
        sign_up_confirm_fail: {},
      });
      break;
    }
  }
};

/**
 * Track the login events
 * based on the spec from:
 * https://guide.trakken.de/5f807081e90fd60009b13d1b/published/60829afcb4ea24000aab8185#chapter3.1.2
 */
export const trackLogin = (payload: LoginEventPayload) => {
  const { eventName, method, displayContext } = payload;
  switch (eventName) {
    case 'LoginError': {
      const { message, errors } = payload;
      pushToGoogleTagManager({
        event: 'Generic Event',
        event_name: 'login_fail',
        login_fail: {
          method,
          error_message: formErrorsToTrackingLabel(errors, message),
          display_context: displayContext,
        },
      });
      break;
    }
    case 'LoginDisplayed': {
      pushToGoogleTagManager({
        event: 'Generic Event',
        event_name: 'login_form_impression',
        login_form_impression: {
          method,
          display_context: displayContext,
        },
      });
      break;
    }
    case 'LoginSuccess': {
      pushToGoogleTagManager({
        event: 'Generic Event',
        event_name: 'login_success',
        login_success: {
          method,
          display_context: displayContext,
        },
      });
      break;
    }
    // No default
  }
};

/**
 * Track Log out events
 * @param payload
 */
export const trackLogout = () => {
  pushToGoogleTagManager({
    event: 'Generic Event',
    event_name: 'logout_success',
    logout_success: {},
  });
};

/**
 * Track the newsletter events
 * based on the spec from:
 * https://guide.trakken.de/5f807081e90fd60009b13d1b/published/60829afcb4ea24000aab8185#chapter3.1.4
 */
export const trackNewsletter = (payload: NewsletterEventPayload) => {
  const { eventName, newsletterName, addressBookId } = payload;
  if (eventName === 'NewsletterError') {
    const { error } = payload;
    pushToGoogleTagManager({
      event: 'Generic Event',
      event_name: 'newsletter_soi_fail',
      newsletter_soi_fail: {
        address_book_id: addressBookId,
        form_id: newsletterName,
        error_message: error?.message || 'Unknown message',
      },
    });
  } else if (eventName === 'NewsletterConfirmation') {
    const confirmationFailure = payload.confirmationState === 'failure';
    pushToGoogleTagManager({
      event: 'Generic Event',
      event_name: confirmationFailure ? 'newsletter_doi_fail' : 'newsletter_doi_success',
      [confirmationFailure ? 'newsletter_doi_fail' : 'newsletter_doi_success']: {
        address_book_id: addressBookId,
        form_id: newsletterName,
      },
    });
  } else {
    const isStart = eventName === 'NewsletterStart';
    pushToGoogleTagManager({
      event: 'Generic Event',
      event_name: isStart ? 'newsletter_soi_start' : 'newsletter_soi_success',
      [isStart ? 'newsletter_soi_start' : 'newsletter_soi_success']: {
        address_book_id: addressBookId,
        form_id: newsletterName,
      },
    });
  }
};

/**
 * Track user properties so we can use differentiate the events of different user types
 * @param payload
 */
export const trackUserProperties = ({ userType, userId, abTests }: UserPropertiesEventPayload) => {
  pushToGoogleTagManager({
    event: 'user_properties',
    user_type: userType,
    user_id: userId,
  });

  // track the active AB tests if there are any
  if (abTests)
    for (const test of abTests) {
      // track them asynchronously to avoid blocking the main thread
      requestAnimationFrame(() => {
        pushToGoogleTagManager({
          event: '$experiment_started',
          experiment_name: test.name,
          variant_name: test.variant,
        });
      });
    }
};

/**
 * Track the forgot password events
 * based on the spec from:
 * https://guide.trakken.de/5f807081e90fd60009b13d1b/published/60829afcb4ea24000aab8185#chapter3.1.5
 */
export const trackForgotPassword = (payload: ForgotPasswordEventPayload) => {
  const { eventName, displayContext } = payload;
  if (eventName === 'ForgotPasswordError') {
    const { message, errors } = payload;
    // try to flatten the form field errors into a single error message
    const errorLabel = formErrorsToTrackingLabel(errors, message);
    pushToGoogleTagManager({
      event: 'Generic Event',
      event_name: 'forgot_password_fail',
      forgot_password_fail: {
        error_message: errorLabel,
        display_context: displayContext,
      },
    });
  } else {
    const isImpression = eventName === 'ForgotPasswordDisplayed';
    pushToGoogleTagManager({
      event: 'Generic Event',
      event_name: isImpression ? 'forgot_password_form_impression' : 'forgot_password_submitted',
      [isImpression ? 'forgot_password_form_impression' : 'forgot_password_submitted']: {
        display_context: displayContext,
      },
    });
  }
};
