import getT from 'next-translate/getT';
import { default as localesConfig } from '../../i18n';
import {
  TranslationNamespace,
  type SupportedLocale,
  type TranslationKeyCommon,
  type TranslationKeyLegal,
  type TranslationKeySeo,
} from 'src/types';

// Export default locale and supported locales from i18n config
export const defaultLocale = localesConfig.defaultLocale;
export const supportedLocales = localesConfig.locales;

// Returns a union of translation keys for either the `common`, `legal`, or `seo`
// namespace - defaults to `common`
type TranslationKey<T> = T extends typeof TranslationNamespace.Legal
  ? TranslationKeyLegal
  : T extends typeof TranslationNamespace.Seo
    ? TranslationKeySeo
    : TranslationKeyCommon;

/**
 * Returns a translation function for a given locale and translation namespace
 * Used to get translations on the server side where `useTranslation` is not available (e.g. in getStaticProps)
 */
export async function getTranslation<T extends (typeof TranslationNamespace)[keyof typeof TranslationNamespace]>(
  locale: SupportedLocale,
  translationNamespace: T,
): Promise<(key: TranslationKey<T>) => string> {
  const t = await getT(locale, translationNamespace);
  return t;
}

/**
 * Checks if a locale string is a supported locale
 * @param locale Locale to check (e.g. 'de')
 * @returns True if locale is supported
 */
export function isSupportedLocale(locale: string | undefined): locale is SupportedLocale {
  return !!locale && Object.values(localesConfig.locales).includes(locale as SupportedLocale);
}

/**
 * Takes a locale and returns a supported locale
 * @param locale Locale to check (e.g. 'de')
 * @returns The given locale if supported, otherwise the default locale
 */
export function getCurrentLocale(locale: string | undefined): SupportedLocale {
  return isSupportedLocale(locale) ? locale : defaultLocale;
}
