import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { hasToBeAuthorized, redirectToLogin } from 'src/config/redirects';
import { useIsAuthenticated } from 'src/state/auth';

/**
 * React hook that checks whether the current visitor is logged in, authorized to view the page, and redirects to the
 * login page if not.
 */
export default function useAuthRequiredRedirect() {
  const { push, asPath } = useRouter();

  const isLoggedIn = useIsAuthenticated();
  // check if user has to be logged in to access the page
  const authIsRequired = hasToBeAuthorized(asPath) && !isLoggedIn;

  useEffect(() => {
    //if the user is not authenticated, automatically redirects to the login page
    if (authIsRequired) {
      void push(redirectToLogin(asPath));
    }
  }, [authIsRequired, push, asPath]);
}
