import { useEffect } from 'react';
import useSWRMutation from 'swr/mutation';
import useSdk, { TrackingConsentType } from 'src/hooks/use-sdk';
import { useIsAuthenticated } from 'src/state/auth';
import { checkVendorConsent, getVendorConsents, useConsentManagerChange, Vendor } from 'src/utilities/consent-manager';

/**
 * A hook that stores the users consent on the backend,
 * so the backend analytics can be enabled.
 * Note: this has no effect on the frontend analytics,
 * which are handled automatically by the Consent Manager and GTM.
 */
export default function useConsentStorage() {
  // get the SDK
  const sdk = useSdk();
  // check if the user is authenticated
  const isAuthenticated = useIsAuthenticated();

  const { trigger: storeConsent } = useSWRMutation('consentTracking', async () => {
    const isVendorConsentReady = getVendorConsents() !== undefined;
    if (!isAuthenticated || !isVendorConsentReady) return;
    // check if the user has given consent to Mixpanel
    const allowedMixpanel = checkVendorConsent(Vendor.Mixpanel);
    // check if user has given consent to Facebook/Meta tracking
    const allowedFacebookMeta = checkVendorConsent(Vendor.FacebookMeta);
    // store the Mixpanel and Meta consent status on the backend
    await sdk.consentTracking({
      mixpanelConsent: allowedMixpanel ? TrackingConsentType.Allow : TrackingConsentType.Revoke,
      metaConsent: allowedFacebookMeta ? TrackingConsentType.Allow : TrackingConsentType.Revoke,
    });
  });

  // listen for changes in the consent manager, e.g. if user changes consent to a vendor
  // the callback will also be called when consent is read from the CMP cookies on the first page load
  useConsentManagerChange(storeConsent);

  // listen to the user's authentication status
  // if the user logs in, we will store the consent
  useEffect(() => {
    if (isAuthenticated) {
      void storeConsent();
    }
  }, [isAuthenticated, storeConsent]);
}
