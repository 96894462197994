import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { PlayableMediaCompact } from 'src/types';
import {
  getPlayerPath,
  getTrackFromUrl,
  isPlayerUrl,
  isTrailerPlayerUrl,
  getTimeFromUrl,
} from 'src/utilities/url-helpers';

type PlayerUrlState = {
  // a shortcut to check if we have entered a player URL
  playerUrlEntered: boolean;
  // get the track id from the url
  trackId?: string;
  // check if we have to display the trailer instead of the normal content
  trailerActive: boolean;
  // check if we have to start from a particular time in the stream
  cueTime?: number;
};

const generatePlayerUrlData = (url: string): PlayerUrlState => {
  return {
    playerUrlEntered: isPlayerUrl(url),
    trackId: getTrackFromUrl(url),
    trailerActive: isTrailerPlayerUrl(url),
    cueTime: getTimeFromUrl(url),
  };
};

/**
 * A hook that manages the player URLs,
 * by adding or removing the `player` query param,
 * also signaling to the components if the player URL is currently active
 */
export const usePlayerUrl = () => {
  const { query, replace, push, events: routerEvents, asPath } = useRouter();

  const [urlState, setUrlState] = useState<PlayerUrlState>(generatePlayerUrlData(asPath));

  /**
   * remove ?trackId=video_xxx&player=true&trailer=true from the current URL
   * thus preventing the maxi player from being displayed
   **/
  const resetPlayerUrl = useCallback(() => {
    const newQuery = { ...query };
    delete newQuery.player;
    delete newQuery.trackId;
    delete newQuery.trailer;
    delete newQuery.t;
    return replace({ query: { ...newQuery } });
  }, [replace, query]);

  /**
   * activate the player URL programmatically
    @param media - media to play, e.g. a track, trailer video or a video concert
    @param trackId - optional, the track id to play first
    @param seconds - optional, the time in seconds to start the track from
  */
  const activatePlayerUrl = useCallback(
    (media: PlayableMediaCompact, track?: string, seconds?: number) => {
      if (media) {
        // we go to the player deep link URL now, playback will be initialized there automatically
        void push(getPlayerPath(media, track, seconds));
      }
    },
    [push],
  );

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setUrlState(generatePlayerUrlData(url));
    };
    routerEvents.on('routeChangeStart', handleRouteChange);
    return () => {
      routerEvents.off('routeChangeStart', handleRouteChange);
    };
  }, [routerEvents]);

  return {
    playerUrlEntered: urlState.playerUrlEntered,
    trackId: urlState.trackId,
    trailerActive: urlState.trailerActive,
    cueTime: urlState.cueTime,
    resetPlayerUrl,
    activatePlayerUrl,
  };
};
