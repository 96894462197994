import type { SVGProps } from 'react';
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M16.125 5.469c-.187-.125-.437-.063-.531.125l-.375.656c-.094.156-.031.375.125.5A6.21 6.21 0 0 1 18.25 12 6.25 6.25 0 0 1 12 18.25 6.23 6.23 0 0 1 5.75 12c0-2.187 1.125-4.156 2.875-5.25.156-.125.219-.344.125-.5l-.375-.656c-.094-.188-.344-.25-.531-.125C5.687 6.844 4.219 9.25 4.25 12.03c0 4.25 3.5 7.75 7.75 7.719A7.75 7.75 0 0 0 19.75 12c0-2.75-1.469-5.156-3.625-6.531m-3.375 7.156v-8.25A.4.4 0 0 0 12.375 4h-.75a.38.38 0 0 0-.375.375v8.25a.36.36 0 0 0 .375.375h.75a.38.38 0 0 0 .375-.375"
    />
  </svg>
);
export default SvgLogout;
