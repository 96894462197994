import { useEffect } from 'react';
import { TicketStatus } from 'generated/graphql';
import TicketPaymentFailedAction from 'src/components/ticket-payment-failed-action';
import { useShowNotification } from 'src/hooks/use-notification';
import useSession from 'src/hooks/use-session';
import useTranslate from 'src/hooks/use-translate';

/**
 * React hook that checks the active user’s ticket status and shows an error
 * notification in the event the ticket status is `PAYMENT_FAILED`.
 *
 * The resulting notification is a toast that is shown on every page.
 */
export function useTicketPaymentStatusCheck() {
  const { data: sessionData } = useSession();
  const showNotification = useShowNotification();
  const t = useTranslate();

  useEffect(() => {
    if (sessionData?.currentUser?.ticket?.status === TicketStatus.PaymentFailed) {
      showNotification({
        title: t('notification_ticket__payment_failed_title'),
        text: t('notification_ticket__payment_failed_text'),
        type: 'error',
        action: TicketPaymentFailedAction,
      });
    }
  }, [sessionData?.currentUser?.ticket?.status, showNotification, t]);
}
