import { Fragment, PropsWithChildren } from 'react';
import RenderClientOnly from 'src/components/render-client-only';
import { useIsAuthenticated } from 'src/state/auth';

type UnauthenticatedOnlyProps = PropsWithChildren<unknown>;

/**
 * React component whose children are only rendered when there is no authenticated user session
 *
 * @example
 * <UnauthenticatedOnly>
 *   <button>Login</button>
 * </UnauthenticatedOnly>
 */
export default function UnauthenticatedOnly({ children }: UnauthenticatedOnlyProps) {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) return null;

  return (
    <RenderClientOnly>
      <Fragment>{children}</Fragment>
    </RenderClientOnly>
  );
}
