import { useEffect } from 'react';
import useSession from 'src/hooks/use-session';
import { useTrackEventOnce } from 'src/tracking';
import { getUsersAbTests } from 'src/utilities/ab-tests';

/**
 * A hook that tracks the user's properties,
 * the tracking is only done once per session or when the user's status changes,
 * e.g. when user logs in or subscribes to a ticket
 */
const useUserTracking = () => {
  const { data: sessionData, isLoading: sessionIsLoading } = useSession();
  const trackOnce = useTrackEventOnce();

  const userType = sessionIsLoading ? undefined : sessionData?.currentUser?.trackingMetadata?.userType || 'guest';
  const userId = sessionData?.currentUser?.id;

  useEffect(() => {
    if (!userType) return;
    // Track user properties once per session unless the user's status changes
    // Also track A/B tests if user is enrolled in one
    const abTests = getUsersAbTests();
    trackOnce({ eventName: 'UserProperties', userType, userId, abTests });
  }, [trackOnce, userType, userId]);
};

export default useUserTracking;
