import { useEffect, useState } from 'react';
import { useAuth } from 'src/state/auth';
import { getAuthenticatedStreamUrl, hlsPlaybackSupport } from 'src/utilities/streaming-helpers';

type AuthenticatedStream = {
  /**
   * The authenticated stream url
   * @default undefined
   */
  url: string | undefined;
  /**
   * An error that occurred during the authentication process
   * @default undefined
   */
  error: Error | undefined;
};

/**
 * Returns an authenticated stream object for the given stream URL and options.
 *
 * Unfortunately, the preferred approach of automatically redirecting to the authenticated stream only works for native HLS playback.
 * This is because redirecting via the HLS.js implementation causes the Origin header to be set to null which prevents the required auth cookies to be set.
 * The Auth cookie (`jwt`) is required to authenticate future (.m3u8 and .ts) requests to the CDN endpoint.
 * Therefore, with the HLS.js approach, instead of redirecting, a `?redirect=false` query parameter is appended to the stream url, causing the stream url to return a json response instead.
 * This json response contains the authenticated CDN url, which is then used to initialize the HLS.js player.
 *
 * @example
 * ```ts
 * const { url, error } = useAuthenticatedStream(streamUrl, { supportsRedirects: true });
 * ```
 */

export default function useAuthenticatedStream(streamUrl: string | undefined): AuthenticatedStream {
  const { token: accessToken } = useAuth();
  const [authenticatedStream, setAuthenticatedStream] = useState<AuthenticatedStream>({
    url: undefined,
    error: undefined,
  });

  useEffect(() => {
    const hlsPlaybackMethod = hlsPlaybackSupport(); // hlsJs or hlsNative
    if (streamUrl) {
      void getAuthenticatedStreamUrl(streamUrl, {
        accessToken,
        // If the the HLS.js implementation is used, the stream url will need to be resolved
        resolveUrl: hlsPlaybackMethod === 'hlsJs',
      })
        .then((resolvedStreamUrl) => setAuthenticatedStream({ url: resolvedStreamUrl, error: undefined }))
        .catch((error: Error) => setAuthenticatedStream({ url: undefined, error }));
    } else {
      // when there is no stream url or auth token, reset the authenticated stream
      setAuthenticatedStream({
        url: undefined,
        error: undefined,
      });
    }
  }, [accessToken, streamUrl]);

  // Reset the authenticated stream when the component unmounts
  useEffect(() => {
    return () => {
      setAuthenticatedStream({
        url: undefined,
        error: undefined,
      });
    };
  }, []);

  return authenticatedStream;
}
