import { DefaultSeo, OrganizationJsonLd } from 'next-seo';
import { useDefaultSeoConfig } from 'src/hooks/use-default-seo-config';
import { useInitialPageMetaTracking } from 'src/tracking';
import { getAbsoluteUrl } from 'src/utilities/url-helpers';

/**
 * Renders the default SEO tags for the site.
 * This component should be included in the _app.tsx file.
 */
export default function DefaultHeadTags() {
  const defaultSeoConfig = useDefaultSeoConfig();
  // track initial page view on load
  useInitialPageMetaTracking();

  return (
    <>
      <DefaultSeo {...defaultSeoConfig} />
      <OrganizationJsonLd
        name="STAGE+"
        logo={getAbsoluteUrl('/images/dg-logo.png')}
        url="https://www.stage-plus.com"
        legalName="Deutsche Grammophon GmbH"
        address={{
          streetAddress: 'Mühlenstraße 25',
          addressLocality: 'Berlin',
          postalCode: '10243',
          addressCountry: 'DE',
        }}
        contactPoint={[
          {
            contactType: 'customer service',
            email: 'support@stage-plus.com',
            telephone: '+490305200707',
          },
        ]}
      />
    </>
  );
}
