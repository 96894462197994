import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type CouponCode = string;

export type CouponCodeLocalStorageItem = {
  value: CouponCode;
  expires: number;
};

export const couponCodeLocalStorageKey = 'coupon.zustand';

const expiryTime = 1000 * 60 * 60 * 24; // 24 hours

type CouponCodeState = {
  value: CouponCode | undefined;
  expires: number | undefined;
  setCode: (code: CouponCode) => void;
  resetCode: () => void;
};

/**
 * A hook for storing a coupon code. The coupon code is stored in local storage
 * and is automatically removed after the expiry time of 24 hours.
 */
export const useCouponCodeStore = create(
  persist<CouponCodeState>(
    (set) => ({
      value: undefined,
      expires: undefined,
      setCode: (code) => set({ value: code, expires: code ? Date.now() + expiryTime : undefined }),
      resetCode: () => set({ value: undefined, expires: undefined }),
    }),
    {
      name: couponCodeLocalStorageKey,
      onRehydrateStorage: () => {
        return (state) => {
          // Check if the coupon code has expired and reset it if it did
          if (state?.expires && state.expires <= Date.now()) {
            state.resetCode();
          }
        };
      },
    },
  ),
);
