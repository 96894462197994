import { RefObject, useEffect, useRef } from 'react';
import { VideoSeekHandler } from 'src/state/video';

/**
 * Detects if the keyboard event has happened in an interactive element
 * useful for the keyboard controls of non-form elements, e.g. players, sliders, etc.
 */
const keyPressedInInteractiveElement = (event: KeyboardEvent) => {
  const target = event?.target as HTMLElement;
  return ['INPUT', 'TEXTAREA', 'SELECT', 'BUTTON', 'A'].includes(target.nodeName);
};

/**
 * Allows to control the player using the computer keyboard
 * @param videoRef - a useRef reference to the <video> element
 */

const usePlayerKeyboardControls = ({
  videoRef,
  seek,
  play,
  pause,
}: {
  videoRef: RefObject<HTMLVideoElement | undefined>;
  seek: VideoSeekHandler;
  play: () => void;
  pause: () => void;
}) => {
  const originalVolumeRef = useRef(videoRef?.current?.volume || 0.8);
  useEffect(() => {
    const keyHandler = (event: KeyboardEvent) => {
      const video = videoRef.current;
      // check if the video exists and a key was pressed outside of the form input
      if (!video?.duration || keyPressedInInteractiveElement(event)) {
        return;
      }
      switch (event.code) {
        case 'Enter':
        case 'Space': {
          // toggle play/pause
          if (video.paused) {
            play();
          } else {
            pause();
          }
          // in chrome the space bar is mapped to playback already,
          // we make sure it's not clashing with our commands
          event.preventDefault();
          break;
        }
        case 'ArrowLeft': {
          // rewind the video a bit
          seek((currentTime: number) => currentTime - 30);
          break;
        }
        case 'ArrowRight': {
          // fast forward the video a bit
          seek((currentTime: number) => currentTime + 30);
          break;
        }
        case 'ArrowUp': {
          // increase the sound volume
          video.volume = Math.min(1, video.volume + 0.1);
          break;
        }
        case 'ArrowDown': {
          // decrease the sound volume
          video.volume = Math.max(0, video.volume - 0.1);
          break;
        }
        case 'KeyM': {
          // mute/unmute the video
          if (video.volume > 0) {
            // remember the original volume
            originalVolumeRef.current = video.volume;
            video.volume = 0;
          } else {
            // restore the original volume after mute
            video.volume = originalVolumeRef.current;
          }
          break;
        }
        default: {
          break;
        }
      }
    };
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener('keydown', keyHandler);
    };
  }, [videoRef, originalVolumeRef, seek, play, pause]);
};

export default usePlayerKeyboardControls;
