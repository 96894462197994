import type { SVGProps } from 'react';
const SvgBigChevron = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M8.63 22.258a.62.62 0 0 1-.269-.494c0-.18.09-.36.225-.494l8.13-8.85-8.13-8.895a.67.67 0 0 1 .045-.988c.27-.27.719-.27.988.045l8.625 9.344a.65.65 0 0 1 0 .943L9.62 22.213a.67.67 0 0 1-.988.045Z"
    />
  </svg>
);
export default SvgBigChevron;
