import { PropsWithChildren, Ref, forwardRef } from 'react';
import usePermissions from 'src/hooks/use-permissions';
import { useTicketPaymentStatusCheck } from 'src/hooks/use-ticket-payment-status-check';

export type PageProps = PropsWithChildren<{
  /**
   * Required identification name for integration testing, usually something like 'audio-page' or 'search-page'.
   */
  testId: string;
  className?: string;
}>;

/**
 * A basic page component used for all page layouts.
 *
 * @example
 * ```tsx
 * <Page testId="audio-page">
 *   <h1>Audio Page</h1>
 * </Page>
 * ```
 */
export default forwardRef<HTMLElement, PageProps>(function Page(
  componentProps: PageProps,
  forwardedRef: Ref<HTMLElement>,
) {
  const { testId, className, children } = componentProps;
  // Check if the user's permissions were fetched
  const { permissionsReady } = usePermissions();
  // Handle cases where the user’s ticket payment failed
  useTicketPaymentStatusCheck();

  return (
    <main
      id="main-content"
      data-test={testId}
      className={className}
      data-test-permissions={permissionsReady ? 'permissions-ready' : 'permissions-checking'}
      ref={forwardedRef}
    >
      {children}
    </main>
  );
});
