export type CurrencyCode = (typeof availableCurrencyCodes)[number];

/** Readonly list of all available currency codes */
const availableCurrencyCodes = ['USD', 'EUR', 'JPY', 'GBP'] as const;
/** Default currency code to use, usually the globally default currency */
export const defaultCurrencyCode: CurrencyCode = 'USD';
/**
 * Zero-decimal currencies are currencies that have no decimal places since the smallest unit of the currency is 1.
 * For example, the smallest unit of the Japanese Yen is 1.
 */
export const zeroDecimalCurrencies: readonly CurrencyCode[] = ['JPY'];

/**
 * Checks whether a given string is a valid currency code. This is necessary because the API returns currencies as
 * strings. Doubles as a TypeScript type guard converting the string to a CurrencyCode type.
 * @param currencyString The currency code to validate
 * @returns `true` if the given string is a valid currency code. Otherwise returns `false`.
 * @example
 * ```ts
 * const euro = 'EUR';
 * const isValid = isCurrencyCode(euro);
 * // => true, euro is now typed as a CurrencyCode
 * const swedishKrona = 'sek';
 * const isInvalid = isCurrencyCode(swedishKrona);
 * // => false, swedishKrona is still typed as a string
 * ```
 */
export function isCurrencyCode(currencyString: string): currencyString is CurrencyCode {
  return availableCurrencyCodes.includes(currencyString as CurrencyCode);
}

/**
 * Returns a valid currency code from a given string.
 * If the string is not a valid currency code, the default currency code is returned.
 *
 * @param currencyString The currency code to validate
 * @returns A valid currency code
 */
export function getValidCurrencyCode(currencyString: string): CurrencyCode {
  // Convert the currency string to uppercase to ensure it matches the available currency codes
  const currencyStringUpper = currencyString.toUpperCase();
  // If the currency string is not a valid currency code, return the default currency code
  return isCurrencyCode(currencyStringUpper) ? currencyStringUpper : defaultCurrencyCode;
}
