import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { create } from 'zustand';
import { authRequestWithPassword, authRequestWithRefreshToken, PasswordCredentials } from 'src/utilities/auth';
import {
  getAccessToken,
  saveAccessToken,
  destroyAccessToken,
  Token,
  getRefreshToken,
  destroyRefreshToken,
  saveRefreshToken,
} from 'src/utilities/token';

type TokenStoreState = {
  accessToken: Token;
  refreshToken: Token;
  setAccessToken: (newToken: Token) => void;
  setRefreshToken: (newToken: Token) => void;
};

const useTokenStore = create<TokenStoreState>((set) => ({
  // Will initialize with the value of the `ACCESS_TOKEN_KEY` cookie.
  accessToken: getAccessToken(),
  // Will initialize with the value of the `REFRESH_TOKEN_KEY` localStorage value.
  refreshToken: getRefreshToken(),
  setAccessToken: (newToken: Token) => set((state) => ({ ...state, accessToken: newToken })),
  setRefreshToken: (newToken: Token) => set((state) => ({ ...state, refreshToken: newToken })),
}));

export type AuthModalType = 'login' | 'signup' | 'forgotpassword' | 'verify' | undefined;

type AuthModalStore = {
  currentAuthModal: AuthModalType;
  setCurrentAuthModal: (newModal: AuthModalType) => void;
};

const useAuthModalStore = create<AuthModalStore>((set) => ({
  currentAuthModal: undefined as AuthModalType,
  setCurrentAuthModal: (newModal: AuthModalType) => set((state) => ({ ...state, currentAuthModal: newModal })),
}));

export function useAuth() {
  const [accessTokenState, setAccessTokenState] = useTokenStore((state) => [state.accessToken, state.setAccessToken]);
  const [refreshTokenState, setRefreshTokenState] = useTokenStore((state) => [
    state.refreshToken,
    state.setRefreshToken,
  ]);
  const [isFetchingToken, setIsFetchingToken] = useState<boolean>(false);

  const setAccessToken = useCallback(
    (newToken: Token) => {
      // Save/destroy the new token in the cookie first
      if (newToken === undefined) {
        destroyAccessToken();
      } else if (typeof newToken === 'string') {
        saveAccessToken(newToken);
      }
      // Then update the state atom
      setAccessTokenState(newToken);
    },
    [setAccessTokenState],
  );

  const setRefreshToken = useCallback(
    (newToken: Token) => {
      // Save/destroy the new token in the cookie first
      if (newToken === undefined) {
        destroyRefreshToken();
      } else if (typeof newToken === 'string') {
        saveRefreshToken(newToken);
      }
      // Then update the state atom
      setRefreshTokenState(newToken);
    },
    [setRefreshTokenState],
  );

  /**
   * Store the access token in the cookie and update the state atom.
   */
  const storeTokens = useCallback(
    ({ accessToken, refreshToken }: { accessToken: Token; refreshToken: Token }) => {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
    },
    [setAccessToken, setRefreshToken],
  );

  /**
   * Login with username and password.
   * @param credentials user credentials
   * @returns a promise resolving into a token
   * @throws an error if the login failed
   */
  const login = useCallback(
    async (credentials: PasswordCredentials) => {
      setIsFetchingToken(true);
      const token = await authRequestWithPassword(credentials);
      setIsFetchingToken(false);
      storeTokens(token);
      return { ...token };
    },
    [storeTokens],
  );

  /**
   * Logout by clearing the access and refresh tokens.
   */
  const logout = useCallback((): void => {
    storeTokens({ accessToken: undefined, refreshToken: undefined });
  }, [storeTokens]);

  /**
   * Handle a token expired response from the API by refreshing the access token.
   */
  const onTokenExpired = useCallback(async (): Promise<void> => {
    // Don't do anything if we're already fetching a new token or if we don't have a refresh token
    if (isFetchingToken || refreshTokenState === undefined) return;
    setIsFetchingToken(true);
    try {
      // Attempt to refresh the token and store it in the state
      const token = await authRequestWithRefreshToken(refreshTokenState);
      storeTokens(token);
    } catch {
      // If refresh access token fails, log the user out
      // Manipulate state directly instead of calling `logout` to avoid having to add an extra callback dependency
      storeTokens({ accessToken: undefined, refreshToken: undefined });
    }
    setIsFetchingToken(false);
  }, [isFetchingToken, refreshTokenState, storeTokens]);

  return { login, logout, storeTokens, onTokenExpired, token: accessTokenState };
}

export function useAuthModal() {
  // get the current router state
  const { events: routerEvents } = useRouter();
  const [currentAuthModal, setCurrentAuthModal] = useAuthModalStore((state) => [
    state.currentAuthModal,
    state.setCurrentAuthModal,
  ]);

  /** allow to close the auth modal from the app if needed */
  const close = useCallback(() => {
    setCurrentAuthModal(undefined);
  }, [setCurrentAuthModal]);

  // in case the modal is open but the app is navigating to another page, close the modal
  useEffect(() => {
    const handleRouteChange = () => {
      if (currentAuthModal) {
        close();
      }
    };
    routerEvents.on('routeChangeStart', handleRouteChange);
    return () => {
      routerEvents.off('routeChangeStart', handleRouteChange);
    };
  }, [currentAuthModal, close, routerEvents, setCurrentAuthModal]);

  return { open: setCurrentAuthModal, currentAuthModal, close };
}

export function useIsAuthenticated(): boolean {
  const hasToken = useTokenStore((state) => state.accessToken !== undefined);
  return hasToken;
}

export function useToken(): Token {
  const accessToken = useTokenStore((state) => state.accessToken);
  return accessToken;
}
