// pages that require user to be logged in
const requireAuthorizationPaths = [
  '/my-stage',
  '/settings',
  '/account/device',
  '/account/payment',
  '/account/email-changed',
  '/account/email-confirmed',
];

// pages that require user to be authorized, but they are very likely not to have an account yet
// so we will redirect them to the signup page instead of login
const requireSignUpPaths = ['/redeem/confirm'];

/**
 * A helper function to check if the current page requires user to be logged in
 * @param pathname  - a URL pathname to check, e.g. '/my-stage'
 */
export const hasToBeAuthorized = (pathname: string) =>
  requireAuthorizationPaths.some((route) => pathname.startsWith(route));

/**
 * A helper function to check if the current page requires user to be signed up
 * @param pathname  - a URL pathname to check, e.g. '/my-stage'
 */
export const hasToBeSignedUp = (pathname: string) => requireSignUpPaths.some((route) => pathname.startsWith(route));

/**
 * A helper function that creates a URL to login page while maintaining the original request URL
 * @param requestUrl
 */
export const redirectToLogin = (requestUrl: string) => `/account/login?redirect=${encodeURIComponent(requestUrl)}`;

/**
 * A helper function that creates a URL to login page while maintaining the original request URL
 * @param requestUrl
 */
export const redirectToSignup = (requestUrl: string) =>
  `/account/signup?hideNav=1&redirect=${encodeURIComponent(requestUrl)}`;
