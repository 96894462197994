import Link from 'next/link';
import useTranslate from 'src/hooks/use-translate';

/**
 * Renders a link to the settings page. This is used in the ticket payment
 * failed toast notification. This is a separate component because the
 * notification is called from a hook which can’t generate the required
 * JSX element within that context.
 */
export default function TicketPaymentFailedAction() {
  const t = useTranslate();
  return (
    <Link href="/settings/subscription" className="dg-text-regular-3 underline">
      {t('notification_ticket__payment_failed_action_settings')}
    </Link>
  );
}
