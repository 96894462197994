import { env } from 'src/config';
import { SupportedLocale } from 'src/types';

type StoreData = {
  url: string;
  logo: string;
  width: number;
  height: number;
};

const appId = env.NEXT_PUBLIC_APP_ID_IOS;

const appleStores: Record<SupportedLocale, StoreData> = {
  en: {
    url: `https://apps.apple.com/app/id${appId}?mt=8`,
    logo: `apple-en.png`,
    width: 499,
    height: 167,
  },
  de: {
    url: `https://apps.apple.com/de/app/id${appId}?mt=8`,
    logo: `apple-de.png`,
    width: 499,
    height: 167,
  },
  ja: {
    url: `https://apps.apple.com/jp/app/id${appId}?mt=8`,
    logo: `apple-ja.png`,
    width: 454,
    height: 167,
  },
};

const googleStores: Record<SupportedLocale, StoreData> = {
  en: {
    url: `https://play.google.com/store/apps/details?id=com.deutschegrammophon.stageplus&hl=en`,
    logo: `google-en.png`,
    width: 561,
    height: 167,
  },
  de: {
    url: `https://play.google.com/store/apps/details?id=com.deutschegrammophon.stageplus&hl=de`,
    logo: `google-de.png`,
    width: 562,
    height: 167,
  },
  ja: {
    url: `https://play.google.com/store/apps/details?id=com.deutschegrammophon.stageplus&hl=ja`,
    logo: `google-ja.png`,
    width: 562,
    height: 167,
  },
};

const amazonStores: Record<SupportedLocale, StoreData> = {
  en: {
    url: `https://www.amazon.com/Deutsche-Grammophon-GmbH-Stream-Classical/dp/B0CFFJQLSL/ref=sr_1_1?keywords=stage&qid=1706107576&s=mobile-apps&sr=1-1`,
    logo: `amazon-en.png`,
    width: 571,
    height: 167,
  },
  de: {
    url: `https://www.amazon.de/Deutsche-Grammophon-GmbH-Stream-Classical/dp/B0CFFJQLSL/ref=sr_1_1?keywords=stage&qid=1706107576&s=mobile-apps&sr=1-1`,
    logo: `amazon-de.png`,
    width: 571,
    height: 167,
  },
  ja: {
    url: `https://www.amazon.co.jp/Deutsche-Grammophon-GmbH-Stream-Classical/dp/B0CFFJQLSL/ref=sr_1_1?keywords=stage&qid=1706107576&s=mobile-apps&sr=1-1`,
    logo: `amazon-ja.png`,
    width: 571,
    height: 167,
  },
};

const stores = {
  apple: appleStores,
  google: googleStores,
  amazon: amazonStores,
};

export type storeName = keyof typeof stores;

/**
 * Get the app store URL for a given locale
 */
export const getAppStoreData = (
  locale: SupportedLocale,
  store: storeName,
): { url: string; logoSrc: string; width: number; height: number } => {
  const appStore = stores[store];
  const storeLinks: StoreData = (locale && appStore[locale]) || appStore.en;
  return {
    url: storeLinks.url,
    width: storeLinks.width,
    height: storeLinks.height,
    logoSrc: `/images/landing-page/store/${storeLinks.logo}`,
  };
};
