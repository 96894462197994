import { useEffect } from 'react';
import { Experiment, isValidExperiment, useExperiments } from 'src/hooks/use-experiments';

const experimentsQueryKey = 'experiments';

/**
 * A hook that reads experiments from the query string and stores them in the experiments state
 * Experiments are read from the `experiments` query parameter and should be comma separated
 * Example: `?experiments=streamToken:on,darkMode:off`
 */
export function useExperimentsFromQuery(): void {
  const { toggle } = useExperiments();

  useEffect(() => {
    // Get the experiments from the query string
    const experimentsFromQuery = new URLSearchParams(window.location.search).get(experimentsQueryKey);
    // Quick return if the query string is not set
    if (typeof experimentsFromQuery !== 'string') return;
    // Split the experiments by comma and filter out invalid experiments (e.g. `experimentOne:on,experimentTwo:off` => ['experimentOne:on', 'experimentTwo:off'])
    const queryExperiments = experimentsFromQuery
      .split(',')
      .map((experiment) => {
        const [name, value] = experiment.trim().split(':');
        return { name, value };
      })
      .filter(({ name, value }) => {
        const isValidName = isValidExperiment(name);
        const isValidValue = value === 'on' || value === 'off';
        if (!isValidName) console.warn(`Invalid experiment "${name}" in query string`);
        if (!isValidValue) console.warn(`Invalid value "${value}" for experiment "${name}" in query string`);
        return isValidName && isValidValue;
      });

    for (const queryExperiment of queryExperiments) {
      toggle(queryExperiment.name as Experiment, queryExperiment.value === 'on');
    }
  }, [toggle]);
}
