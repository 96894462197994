import type { SVGProps } from 'react';
const SvgCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m16.895 7.047-7.192 7.191-2.625-2.652c-.137-.11-.355-.11-.465 0l-.793.793c-.11.11-.11.328 0 .465l3.664 3.636a.315.315 0 0 0 .465 0l8.203-8.203c.11-.109.11-.328 0-.465l-.793-.765c-.109-.137-.328-.137-.464 0"
    />
  </svg>
);
export default SvgCheckmark;
