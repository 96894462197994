import { SupportedLocale } from 'src/types';

const defaultSocialMediaHandles = {
  facebook: 'stageplusmusic',
  instagram: 'stageplusmusic',
  twitter: 'stageplusmusic',
  youtube: 'UC34DbNyD_0t8tnOc5V38Big',
};

export type NetworkName = keyof typeof defaultSocialMediaHandles;

const socialMediaHandles: Record<SupportedLocale, typeof defaultSocialMediaHandles> = {
  en: defaultSocialMediaHandles,
  de: defaultSocialMediaHandles,
  // Japan has a different Facebook page
  ja: { ...defaultSocialMediaHandles, facebook: 'dgclassicsjapan' },
};

/**
 * Get the social media handles for the given locale
 * @param locale
 * @returns Social media handles
 */
export const getSocialMediaHandles = (locale: SupportedLocale): typeof defaultSocialMediaHandles => {
  return socialMediaHandles[locale] || defaultSocialMediaHandles;
};

/**
 * Get the social media links for a given locale
 * @param locale
 * @returns Social media links
 */
export function getSocialMediaData(locale: SupportedLocale, network: NetworkName) {
  const socialMediaHandles = getSocialMediaHandles(locale);

  const networks: Record<NetworkName, { title: string; url: string }> = {
    facebook: {
      title: 'Facebook',
      url: `https://www.facebook.com/${socialMediaHandles.facebook}/`,
    },
    instagram: {
      title: 'Instagram',
      url: `https://www.instagram.com/${socialMediaHandles.instagram}/`,
    },
    twitter: {
      title: 'X',
      url: `https://twitter.com/${socialMediaHandles.twitter}`,
    },
    youtube: {
      title: 'YouTube',
      url: `https://www.youtube.com/channel/${socialMediaHandles.youtube}`,
    },
  };

  return networks[network];
}
