import type { SVGProps } from 'react';
const SvgMenuClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m12.182 10-8-8L2 4.182l8 8-7.636 7.636L4.545 22l7.637-7.636 7.272 7.272 2.182-2.181-7.273-7.273L22 4.545l-2.182-2.181z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMenuClose;
