import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { DefaultSeoProps } from 'next-seo';
import { env } from 'src/config';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import useTranslate from 'src/hooks/use-translate';
import { supportedLocales } from 'src/utilities/i18n-helpers';
import { getSocialMediaHandles } from 'src/utilities/social-media-helpers';
import { getAbsoluteUrl } from 'src/utilities/url-helpers';

const appIdIos = env.NEXT_PUBLIC_APP_ID_IOS;

/**
 * Returns the default SEO config based on the current url and locale.
 * @returns Default SEO props
 */
export function useDefaultSeoConfig(): DefaultSeoProps {
  const t = useTranslate();
  const currentLocale = useCurrentLocale();
  const { asPath } = useRouter();

  return useMemo(
    () => ({
      defaultTitle: t('seo__default_title'),
      description: t('seo__default_description'),
      additionalMetaTags: (() => {
        const metaTags = [
          {
            name: 'viewport',
            content: 'initial-scale=1.0, maximum-scale=1.0, width=device-width',
          },
        ];

        if (appIdIos) {
          metaTags.push({
            name: 'apple-itunes-app',
            content: `app-id=${appIdIos}`,
          });
        }

        return metaTags;
      })(),
      additionalLinkTags: [
        {
          rel: 'icon',
          href: '/favicon.ico',
          sizes: 'any',
        },
        {
          rel: 'icon',
          href: '/images/favicon.svg',
          type: 'image/svg+xml',
        },
        {
          rel: 'apple-touch-icon',
          href: '/images/apple-touch-icon.png',
        },
        {
          rel: 'manifest',
          href: '/manifest.json',
        },
      ],
      /**
       * Generate alternate urls for each supported locale and the default locale
       * https://developers.google.com/search/docs/advanced/crawling/localized-versions
       *
       * Example:
       * <link rel="alternate" hrefLang="en" href="https://www.stage-plus.com/" />
       * <link rel="alternate" hrefLang="de" href="https://www.stage-plus.com/de" />
       * <link rel="alternate" hrefLang="x-default" href="https://www.stage-plus.com/" />
       */
      languageAlternates: [
        ...supportedLocales.map((locale) => ({
          hrefLang: locale,
          href: getAbsoluteUrl(asPath, locale),
        })),
        {
          hrefLang: 'x-default',
          href: getAbsoluteUrl(asPath, 'en'),
        },
      ],
      // The canonical url should not contain the query string
      canonical: getAbsoluteUrl(asPath.replace(/\?.*/, ''), currentLocale),
      openGraph: {
        url: getAbsoluteUrl(asPath, currentLocale),
        title: t('seo__default_title'),
        type: 'website',
        description: t('seo__default_description'),
        images: [
          {
            url: getAbsoluteUrl('/images/og-image.png'),
            width: 1200,
            height: 630,
          },
        ],
        locale: currentLocale,
        site_name: t('seo__default_site_name'),
      },
      twitter: {
        cardType: 'summary_large_image',
        site: `@${getSocialMediaHandles(currentLocale).twitter}`,
      },
    }),
    [asPath, currentLocale, t],
  );
}
