import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';

// List of all currently available experiments
// !IMPORTANT! If you add or remove an experiment, make sure to bump the version number below
const experiments = [
  // keep a default experiment for integration tests and sanity checks
  'default',
] as const;

// Zustand persisted storage version number to avoid loading stale experiments from session storage
const version = 5;

export type Experiment = (typeof experiments)[number];

type ExperimentsState = {
  experiments: Experiment[];
  isEnabled: (experiment: Experiment) => boolean;
  toggle: (experiments: Experiment, onOff: boolean) => void;
};

const storageKey = 'experimentsState.zustand';

/**
 * Provide access to the locally stored experiments of the current user
 * e.g. if the user has enabled or disabled the stream token experiment
 */
export const useExperiments = create(
  devtools(
    persist<ExperimentsState>(
      (set, get) => ({
        experiments: [],
        isEnabled: (experiment: Experiment) => get().experiments.includes(experiment),
        toggle: (experiment: Experiment, onOff: boolean) =>
          set((state) => {
            const experiments = new Set(state.experiments);
            if (onOff) {
              experiments.add(experiment);
            } else {
              experiments.delete(experiment);
            }
            return { experiments: [...experiments] };
          }),
      }),
      {
        name: storageKey,
        storage: typeof window === 'undefined' ? undefined : createJSONStorage(() => sessionStorage),
        version,
      },
    ),
  ),
);

/**
 * Check if a value is a valid experiment to avoid typos
 */
export function isValidExperiment(value: string): value is Experiment {
  return experiments.includes(value as Experiment);
}
