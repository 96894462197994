import useHasMounted from 'src/hooks/use-has-mounted';

type RenderClientOnlyProps = {
  children: JSX.Element[] | JSX.Element;
};

const RenderClientOnly = ({ children }: RenderClientOnlyProps): JSX.Element => {
  const hasMounted = useHasMounted();
  if (!hasMounted) {
    return <></>;
  }
  return <>{children}</>;
};

export default RenderClientOnly;
