import { useCallback, useEffect } from 'react';
import { create } from 'zustand';
import useSession, { SessionUser } from 'src/hooks/use-session';

/**
 * A slightly modified version of the SessionUser type for faster access to the current user's data.
 */
export type CurrentUser = Pick<SessionUser, 'id' | 'email' | 'emailVerified' | 'ticket'> & {
  insertedAtDate: number;
};

type CurrentUserStore = {
  isLoading: boolean;
  currentUser?: CurrentUser;
  setCurrentUser: (user?: SessionUser) => void;
  resetCurrentUser: () => void;
};

/**
 * A zustand store to hold the current user's data and methods to update it.
 */
const useCurrentUserStore = create<CurrentUserStore>((set) => ({
  // we will use this flag to determine when the store is actually ready to be used
  isLoading: true,
  currentUser: undefined,
  setCurrentUser: (user?: SessionUser) => {
    if (user) {
      const { email, id, insertedAt, emailVerified, ticket } = user;

      set({
        currentUser: {
          id,
          email,
          emailVerified,
          ticket,
          // store the time the user has signed up as a timestamp
          insertedAtDate: new Date(String(insertedAt)).getTime(),
        },
        isLoading: false,
      });
    } else {
      set({ currentUser: undefined, isLoading: false });
    }
  },
  resetCurrentUser: () => {
    set({ currentUser: undefined });
  },
}));

/**
 * A hook to get the current local user's data and methods to update it.
 * The user data is stored in a zustand store and should be updated whenever the session changes.
 */
export default function useCurrentUser() {
  const { currentUser, isLoading, setCurrentUser, resetCurrentUser } = useCurrentUserStore();
  const { data: sessionData, mutate: sessionMutate, isLoading: sessionIsLoading } = useSession();
  const currentUserData = sessionData?.currentUser;

  // a generic revalidation function that will be called whenever the current user changes
  const refreshCurrentUser: () => Promise<void> = useCallback(async () => {
    // update the API cache on the current user
    await sessionMutate();
  }, [sessionMutate]);

  // update the current user state whenever the session changes
  useEffect(() => {
    if (!sessionIsLoading) {
      setCurrentUser(currentUserData);
    }
  }, [currentUserData, setCurrentUser, sessionIsLoading]);

  return {
    currentUser,
    isLoading,
    refreshCurrentUser,
    resetCurrentUser,
  };
}
