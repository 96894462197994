import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type StreamingSettingsStore = {
  lowDataModeEnabled: boolean;
  dolbyAtmosModeEnabled: boolean;
  toggleLowDataMode: () => void;
  toggleDolbyAtmosMode: () => void;
};

const localStorageKey = 'streaming-settings.zustand';

/**
 * Provide access to the locally stored streaming settings of the current user,
 * e.g. if the user has enabled or disabled the low data bandwidth streaming
 * @returns
 */
const useStreamingSettings = create(
  persist<StreamingSettingsStore>(
    (set) => ({
      lowDataModeEnabled: false,
      dolbyAtmosModeEnabled: true,
      toggleLowDataMode: () => set((state) => ({ ...state, lowDataModeEnabled: !state.lowDataModeEnabled })),
      toggleDolbyAtmosMode: () => set((state) => ({ ...state, dolbyAtmosModeEnabled: !state.dolbyAtmosModeEnabled })),
    }),
    {
      name: localStorageKey,
      version: 1,
      // migrate the persisted state to the current version
      migrate(persistedState, version) {
        // we have to cast the persisted state to the correct type first
        const state = persistedState as StreamingSettingsStore;
        if (version < 1) {
          return {
            ...state,
            // apply the default value `true` for all starting with version 1
            dolbyAtmosModeEnabled: true,
          };
        }
        return state;
      },
    },
  ),
);

export default useStreamingSettings;
