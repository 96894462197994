import { RefObject, useState, useEffect } from 'react';

/**
 * A hook that provides a list of all available text tracks for the video as an array.
 * @param videoElRef A reference to the video element.
 * @param videoURL The URL of the video.
 * @returns an array of text tracks.
 */
const useVideoTextTracks = ({
  videoElRef,
  videoURL,
}: {
  videoElRef: RefObject<HTMLVideoElement | undefined>;
  videoURL?: string;
}): TextTrack[] => {
  // observe the text tracks object of the video element, if it exists
  const textTracksObject = videoElRef.current?.textTracks;
  // normalized text tracks / subtitles of the current video context
  const [textTracks, setTextTracks] = useState<TextTrack[]>([]);

  // reset the state when the video url changes
  useEffect(() => {
    setTextTracks([]);
  }, [videoURL]);

  // listen to the change/load event of the text tracks to update the textTracks state
  useEffect(() => {
    const changeHandler = (event: Event) => {
      //  get the latest text track list e.g. if a new subtitle is loaded/added
      const textTrackList = event.target as TextTrackList;
      // convert the TextTrackList to an array
      const textTracksArray = [...textTrackList]
        .map((track) => {
          // if the text track has no language set, we can ignore it, as it might be e.g an id3 track
          if (!track.language) return null;
          // as of 2022-05, the HLS encoding by G&L forces the first subtitle to be the default one
          // so we need to reset them all to disabled until a user chooses a specific one
          track.mode = 'disabled';
          return track;
        })
        .filter((track) => track !== null);
      // update the textTracks state array
      setTextTracks(textTracksArray);
    };
    // add the event listener, the addtrack event is triggered when the text tracks are loaded
    textTracksObject?.addEventListener('addtrack', changeHandler);
    return () => {
      textTracksObject?.removeEventListener('addtrack', changeHandler);
    };
  }, [textTracksObject]);

  return textTracks;
};

/**
 * A method that allows to display or hide a particular text track
 * @param selected - selected text track, if you wish to hide any subtitle, just pass ùndefined`
 * @param textTracks - an array of the text tracks of the video
 */
export const toggleVideoTextTrack = ({
  selectedTrack,
  textTracks,
}: {
  selectedTrack?: TextTrack;
  textTracks?: TextTrack[];
}) => {
  // check if we are toggling off the already showing text track
  const wasShowing = selectedTrack?.mode === 'showing';
  if (textTracks) {
    for (const sub of textTracks) {
      // reset all subtitles first
      sub.mode = 'disabled';
    }
    // then, if we are enabling a previously invisible text track,
    // change its mode to `showing`
    if (selectedTrack && !wasShowing) {
      console.log('enabling', selectedTrack);
      selectedTrack.mode = 'showing';
    }
  }
};

export default useVideoTextTracks;
