import type { SVGProps } from 'react';
const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2.982c2.937 0 3.285.011 4.445.064 1.072.049 1.655.228 2.042.379.514.2.88.438 1.265.823s.624.751.823 1.264c.15.388.33.97.379 2.043.053 1.16.064 1.508.064 4.445s-.011 3.285-.064 4.445c-.05 1.072-.228 1.655-.379 2.043-.2.513-.438.88-.823 1.264s-.751.624-1.265.823c-.387.15-.97.33-2.042.379-1.16.053-1.508.064-4.445.064s-3.285-.011-4.445-.064c-1.072-.05-1.655-.228-2.043-.379-.513-.2-.88-.438-1.264-.823s-.624-.751-.823-1.264c-.15-.388-.33-.97-.379-2.043-.053-1.16-.064-1.508-.064-4.445s.011-3.285.064-4.445c.049-1.072.228-1.655.379-2.043.2-.513.438-.88.823-1.264s.751-.624 1.264-.823c.388-.15.97-.33 2.043-.379 1.16-.053 1.508-.064 4.445-.064M12 1c-2.987 0-3.362.013-4.535.066-1.171.054-1.97.24-2.67.512a5.4 5.4 0 0 0-1.949 1.268 5.4 5.4 0 0 0-1.269 1.949c-.271.7-.457 1.499-.51 2.67C1.012 8.638 1 9.013 1 12s.013 3.362.066 4.535c.054 1.171.24 1.97.511 2.67a5.4 5.4 0 0 0 1.27 1.949 5.4 5.4 0 0 0 1.948 1.269c.7.272 1.499.457 2.67.51C8.638 22.988 9.013 23 12 23s3.362-.013 4.535-.066c1.171-.054 1.97-.24 2.67-.512a5.4 5.4 0 0 0 1.949-1.268 5.4 5.4 0 0 0 1.269-1.948c.271-.7.457-1.5.51-2.67.054-1.174.067-1.549.067-4.536s-.013-3.362-.066-4.535c-.054-1.171-.24-1.97-.512-2.67a5.4 5.4 0 0 0-1.268-1.949 5.4 5.4 0 0 0-1.949-1.268c-.7-.272-1.499-.458-2.67-.512C15.362 1.013 14.987 1 12 1m0 5.351a5.649 5.649 0 1 0 0 11.298A5.649 5.649 0 0 0 12 6.35m0 9.316a3.667 3.667 0 1 1 0-7.334 3.667 3.667 0 0 1 0 7.334m7.192-9.539a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInstagram;
