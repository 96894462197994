import { ReactNode } from 'react';
import clsx from 'clsx';
import { NavigationIcon } from 'src/components/navigation/navigation-icons';

type NavigationItemProps = {
  /**
   * When true it highlights the component in the primary color and uses the filled icon
   * @default false
   */
  active?: boolean;
  /**
   * When true it simulate a visual hover effect on the component
   */
  hover?: boolean;
  children: ReactNode;
  iconComponent?: NavigationIcon;
  /**
   * When true it adds a bottom border to the component when active
   * @default false
   */
  underline?: boolean;
  className?: string;
};

export default function NavigationItem({
  active = false,
  hover = false,
  children,
  iconComponent: Icon,
  underline = false,
  className,
}: NavigationItemProps) {
  return (
    <div
      className={clsx(
        'select-none px-4 py-3 group-hover:text-brandYellowC1',
        hover && 'text-brandYellowC1',
        active
          ? 'border-b-brandYellowC1 border-t-transparent text-brandYellowC1'
          : 'border-b-transparent border-t-transparent',
        underline && 'border-y-4',
        className,
      )}
    >
      <div className="dg-text-regular-4 flex items-center whitespace-nowrap rounded-sm py-2 outline-offset-2 group-focus-visible:focus-outline xl:justify-center">
        {Icon && <Icon className="mr-2" filled={active} aria-hidden />}
        <span>{children}</span>
      </div>
    </div>
  );
}
