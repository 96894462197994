import { MouseEvent } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { TextButtonLink, TextButtonProps } from 'src/components/buttons/text-button';
import useTranslate from 'src/hooks/use-translate';
import { AuthModalType, useAuthModal } from 'src/state/auth';
import { trackEvent } from 'src/tracking';
import { CtaSignupLoginEventPayload } from 'src/tracking/track-user';
import { useTrackingContext } from 'src/tracking/tracking-context';
import { TranslationKeyCommon } from 'src/types';
import { transferImportantQueryParameters } from 'src/utilities/url-helpers';

type AuthLinkProps = {
  // all the auth modals can be opened, except the verify email one currently
  action: Exclude<AuthModalType, 'verify' | undefined>;
  buttonType?: TextButtonProps['variation'];
  onClick?: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  dataTest?: string;
  className?: string;
  children: React.ReactNode;
  trackingTextId: TranslationKeyCommon;
};

const authPaths = {
  login: '/account/login',
  signup: '/account/signup',
  forgotpassword: '/account/forgot',
};

// map the auth link action to the tracking event name
const trackingEventNames: Record<AuthLinkProps['action'], CtaSignupLoginEventPayload['eventName']> = {
  login: 'CtaLogin',
  signup: 'CtaSignup',
  forgotpassword: 'CtaForgotPassword',
};

/**
 * A `AuthLink` component is an unstyled component that wraps its children in a normal anchor tag
 * linking to the signup, login or forgot password pages. On default left click, it opens the modal.
 *
 * The main reason for this component is to avoid auth modal and click handling logic in multiple places in the codebase.
 *
 * @example
 * ```tsx
 * <AuthLink>Sign up now</AuthLink> // unstyled link
 * <AuthLink type="primary">Sign up now</AuthLink> // signup link that looks like a button
 * <AuthLink className="bg-brandYellowC1">Sign up now</AuthLink> // styled link
 * <AuthLink dataTest="header-signup-link">Sign up now</AuthLink> // unstyled link with test attribute
 * ```
 */
function AuthLink({
  action,
  buttonType,
  children,
  className,
  dataTest,
  onClick,
  trackingTextId: ctaKey,
}: AuthLinkProps) {
  const { open } = useAuthModal();

  const t = useTranslate();

  // lets not lose important query params when switching between login and redirect
  const { query, pathname } = useRouter();

  // Get the context where the button is rendered for the tracking
  const displayContext = useTrackingContext();

  function handleClick(event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
    if (pathname.startsWith('/account')) {
      // if we are already on some account management page, don't open the auth modal
      // but let the browser handle the click and go directly to the auth page
      return;
    }
    // track the auth click event
    trackEvent({
      eventName: trackingEventNames[action],
      label: 'Start',
      displayContext,
      cta: {
        id: ctaKey,
        label: t(ctaKey),
      },
    });
    event.preventDefault();
    open(action);
    // in case there is a onClick handler in props, we need to call it
    onClick?.(event);
  }
  // link to the auth page, make sure to carry over important query params e.g. `redirect` or `hideNav`
  const href = { pathname: authPaths[action], query: transferImportantQueryParameters(query) };

  if (buttonType) {
    return (
      <TextButtonLink
        href={href}
        variation={buttonType}
        className={className}
        data-test={dataTest}
        onClick={handleClick}
      >
        {children}
      </TextButtonLink>
    );
  }

  return (
    <Link href={href} className={className} data-test={dataTest} onClick={handleClick}>
      {children}
    </Link>
  );
}

export function SignupLink({ children, ...rest }: Omit<AuthLinkProps, 'action'>) {
  return (
    <AuthLink {...rest} action="signup">
      {children}
    </AuthLink>
  );
}

export function LoginLink({ children, ...rest }: Omit<AuthLinkProps, 'action'>) {
  return (
    <AuthLink {...rest} action="login">
      {children}
    </AuthLink>
  );
}

export function ForgotPasswordLink({ children, ...rest }: Omit<AuthLinkProps, 'action'>) {
  return (
    <AuthLink {...rest} action="forgotpassword">
      {children}
    </AuthLink>
  );
}
