import { Fragment } from 'react';
import useAuthRequiredRedirect from 'src/hooks/use-auth-required-redirect';
import useConsentStorage from 'src/hooks/use-consent-storage';
import { useCouponCodeFromQuery } from 'src/hooks/use-coupon-code-from-query';
import { useExperimentsFromQuery } from 'src/hooks/use-experiments-from-query';
import { useStripeFraudDetection } from 'src/hooks/use-stripe-fraud-detection';
import { useTicketPaymentStatusCheck } from 'src/hooks/use-ticket-payment-status-check';
import { useTrackingParametersFromQuery } from 'src/hooks/use-tracking-parameters-from-query';
import { useVirtualPageViewTracking } from 'src/tracking';

/**
 * A collection of hooks that are initialized on the app load.
 * This way these hooks are activated once and are not re-initialized on each page change.
 * Some of these hooks are used to consume the query parameters and store them in the state.
 * Others are related to the tracking of the users' actions
 * It is structured as a component to keep the `_app.tsx` file clean and easy to read.
 * Note that the hooks might access Context API or other global state,
 * so the _app.tsx insertion order is important.
 * @returns {JSX.Element} The component
 */
export default function InitializationHooks() {
  // Redirect non-authenticated users to the login page on authentication required pages
  useAuthRequiredRedirect();
  // Track each history.push/pop page view in Google Analytics
  useVirtualPageViewTracking();
  // Check if the `coupon` query parameter is set (e.g. `?coupon=30PERCENTOFF`), and store it in the couponCode state
  useCouponCodeFromQuery();
  // Store tracking values in the local storage
  useTrackingParametersFromQuery();
  // Handle cases where the user’s ticket payment failed
  useTicketPaymentStatusCheck();
  // Check if the `experiments` query parameter is set and store it in the experiments state
  useExperimentsFromQuery();
  // Store the user's consent on the backend, so the backend analytics can be enabled
  useConsentStorage();
  // Load the Stripe.js library early in the app lifecycle to enable fraud detection
  useStripeFraudDetection();
  return <Fragment></Fragment>;
}
