import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';

/**
 * React hook that checks whether the `hideNav` query parameter or a cookie is set to `1` and returns `true` if so.
 * Used to hide the main navigation and footer on the page if the page is meant to be a standalone page like the
 * payment page or pages that are loaded in the native iOS AppView and can’t have any navigation.
 */
export default function useHideNavigation() {
  const [hideNavigation, setHideNavigation] = useState(false);
  // create a new cookie instance
  const cookies = new Cookies();
  // check the cookie first, convert to string because in some cases the library returns a number
  const hideNavCookie = String(cookies.get('hideNav')) === '1';
  // check the query parameter, hideNav
  const { query, isReady } = useRouter();

  useEffect(() => {
    if (hideNavCookie || (isReady && query.hideNav === '1')) {
      setHideNavigation(true);
    } else {
      setHideNavigation(false);
    }
  }, [query.hideNav, isReady, hideNavCookie]);

  return hideNavigation;
}
