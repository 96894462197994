import { useCallback, useMemo } from 'react';
import { create } from 'zustand';
import { PerformanceWork } from 'generated/graphql';
import useLiveConcertState from 'src/hooks/use-live-concert-state';
import { usePlayerUrl } from 'src/hooks/use-player-url';
import { PlayableMedia } from 'src/types';
import { findNextItem, findPreviousItem, getFirstItemId } from 'src/utilities/queue-helpers';

// the player has several possible UI styles depending on the user's interaction
type PlayerMode = 'mini' | 'maxi' | 'fullscreen' | undefined;

type QueueState = {
  activeMediaId?: string;
  activeItemId?: string;
  restorePreviousPlayback: boolean;
  // the media that can be put on the queue - albums or videos
  media: (PlayableMedia | undefined)[];
};

type QueueStoreState = {
  playbackQueue: QueueState;
  playerMode: PlayerMode;
  setPlayerMode: (newMode: PlayerMode) => void;
  setPlaybackQueue: (newQueue: QueueState) => void;
  clearQueue: () => void;
};

const usePlaybackStore = create<QueueStoreState>((set) => ({
  playbackQueue: {
    activeMediaId: undefined,
    activeItemId: undefined,
    restorePreviousPlayback: true,
    media: [],
  },
  playerMode: undefined,
  setPlayerMode: (newMode: PlayerMode) => set((state) => ({ ...state, playerMode: newMode })),
  setPlaybackQueue: (newQueue: QueueState) => set((state) => ({ ...state, playbackQueue: newQueue })),
  clearQueue: () =>
    set(() => ({
      playbackQueue: {
        activeMediaId: undefined,
        activeItemId: undefined,
        restorePreviousPlayback: true,
        media: [],
      },
      playerMode: undefined,
    })),
}));

/**
 * The state of the playback queue
 * it defines which media is currently loaded, which performance work or track is currently active
 * also allows replacing, clearing and skipping.
 * The queue has a list of *mediaSets* (albums or concerts), which have lists of *items* (tracks or works)
 * Note: the playback is always initiated via the route change,
 */
export const usePlayback = () => {
  //  get the complete playback state including the queue and the player mode
  const playbackState = usePlaybackStore((state) => state.playbackQueue);
  // control the queue state
  const [setPlaybackQueue, resetQueue] = usePlaybackStore((state) => [state.setPlaybackQueue, state.clearQueue]);
  // control the player style:  maxi, mini, or fullscreen
  const [playerMode, setPlayerMode] = usePlaybackStore((state) => [state.playerMode, state.setPlayerMode]);

  // player URL operations
  const { activatePlayerUrl, resetPlayerUrl } = usePlayerUrl();

  // return the current media - album or video
  const currentMedia = playbackState.media?.find((media) => media?.id === playbackState.activeMediaId);

  //  return all tracks or perf works of the currently active media
  const currentTracks = useMemo(() => {
    return currentMedia?.__typename === 'Album' ? currentMedia?.trackSets.flatMap((set) => set.tracks) : undefined;
  }, [currentMedia]);

  const currentWorks: PerformanceWork[] | undefined = useMemo(() => {
    return currentMedia?.__typename === 'VodConcert'
      ? (currentMedia?.performanceWorks?.filter((work) => !!work) as PerformanceWork[])
      : undefined;
  }, [currentMedia]);

  // return the current, loaded track or perf work
  const currentActiveTrack = useMemo(() => {
    return playbackState.activeItemId === undefined
      ? undefined
      : currentTracks?.find((track) => track?.id === playbackState.activeItemId);
  }, [currentTracks, playbackState]);

  const currentActiveWork = useMemo(() => {
    return playbackState.activeItemId === undefined
      ? undefined
      : currentWorks?.find((work) => work?.id === playbackState.activeItemId);
  }, [currentWorks, playbackState]);

  const replaceQueue = useCallback(
    (mediaSet?: PlayableMedia, initialItemId?: string) => {
      // when replacing the queue, we automatically set the passed media as active on
      // and either automatically load the first or passed track or perf work
      if (mediaSet) {
        setPlaybackQueue({
          media: [mediaSet],
          activeMediaId: mediaSet?.id,
          activeItemId: initialItemId || getFirstItemId({ mediaSet }),
          restorePreviousPlayback: mediaSet?.__typename !== 'Album',
        });
        // also the player is automatically activated in the maxi mode
        setPlayerMode('maxi');
      }
    },
    [setPlaybackQueue, setPlayerMode],
  );

  const activateItem = useCallback(
    (mediaId: string, itemId: string) => {
      // activate the track in the playback state
      setPlaybackQueue({ ...playbackState, activeMediaId: mediaId, activeItemId: itemId });
    },
    [playbackState, setPlaybackQueue],
  );

  // in case we have a live concert active, we need to check if it is streaming right now and which of its streams is live
  const liveConcertState = useLiveConcertState(currentMedia?.__typename === 'LiveConcert' ? currentMedia : undefined);

  // return the stream object of the currently active media
  const currentStream = useMemo(() => {
    // in case it's a live concert, we need to return the main stream or the stream of the current re-run
    if (currentMedia?.__typename === 'LiveConcert') {
      return liveConcertState.activeStream;
    }
    // otherwise
    return (
      currentActiveWork?.stream || // in case it's a performance work in a concert
      currentActiveTrack?.stream || // in case it's a track in an album
      (currentMedia && 'stream' in currentMedia && currentMedia.stream) || // in case it's a video
      undefined
    );
  }, [currentMedia, liveConcertState, currentActiveWork?.stream, currentActiveTrack?.stream]);

  const getNextItem = useCallback(() => {
    if (currentWorks) {
      return findNextItem({
        items: currentWorks,
        currentItemId: playbackState.activeItemId,
      });
    }
    if (currentTracks) {
      return findNextItem({
        items: currentTracks,
        currentItemId: playbackState.activeItemId,
      });
    }
    return;
  }, [currentTracks, currentWorks, playbackState.activeItemId]);

  const getPreviousItem = useCallback(() => {
    if (currentWorks) {
      return findPreviousItem({
        items: currentWorks,
        currentItemId: playbackState.activeItemId,
      });
    }
    if (currentTracks) {
      return findPreviousItem({
        items: currentTracks,
        currentItemId: playbackState.activeItemId,
      });
    }
    return;
  }, [currentTracks, currentWorks, playbackState.activeItemId]);

  const switchTrack = useCallback(
    (newItemId?: string) => {
      if (currentMedia && newItemId) {
        // user is manually switching tracks, so we should not restore the previous playback,
        // otherwise the playback would jump unexpectedly on each load
        setPlaybackQueue({ ...playbackState, restorePreviousPlayback: false });
        // if we have a mini player, we just switch the active item, without activating the url change/ entering maxi player
        if (playerMode === 'mini') {
          activateItem(currentMedia.id, newItemId);
        } else {
          // otherwise we activate the url change and enter the maxi player
          activatePlayerUrl(currentMedia, newItemId);
        }
      }
      return newItemId;
    },
    [currentMedia, setPlaybackQueue, playbackState, playerMode, activateItem, activatePlayerUrl],
  );

  const skipToNext = useCallback(() => {
    const nextItemId = getNextItem()?.id;
    return switchTrack(nextItemId);
  }, [getNextItem, switchTrack]);

  const skipToPrevious = useCallback(() => {
    const previousItemId = getPreviousItem()?.id;
    return switchTrack(previousItemId);
  }, [getPreviousItem, switchTrack]);

  const skipToFirst = useCallback(() => {
    const firstItemId = getFirstItemId({ mediaSet: currentMedia });
    return switchTrack(firstItemId);
  }, [currentMedia, switchTrack]);

  const clearQueue = useCallback(async () => {
    // reset the player url
    await resetPlayerUrl();
    // reset player UI mode
    setPlayerMode(undefined);
    // clear the queue
    resetQueue();
  }, [resetPlayerUrl, resetQueue, setPlayerMode]);

  /**
   * check if the playable media is the same that is currently active in the playback state
   */
  const isCurrentMedia = useCallback(
    (media?: Pick<PlayableMedia, 'id' | '__typename'>) =>
      currentMedia && currentMedia?.id === media?.id && currentMedia?.__typename === media?.__typename,
    [currentMedia],
  );

  return {
    playbackQueue: playbackState,
    currentMedia,
    currentTracks,
    currentWorks,
    currentActiveTrack,
    currentActiveWork,
    skipToNext,
    skipToPrevious,
    skipToFirst,
    nextItem: useMemo(() => getNextItem(), [getNextItem]),
    previousItem: useMemo(() => getPreviousItem(), [getPreviousItem]),
    isCurrentMedia,
    replaceQueue,
    clearQueue,
    activateItem,
    currentStream,
    isAudio: currentMedia?.__typename === 'Album',
    setPlayerMode,
    playerMode,
    liveConcertState,
  };
};
