import { useState, useEffect, RefObject, useCallback, useRef } from 'react';
// be careful not to import the whole Hls class, as that would increase the bundle size
import type Hls from 'hls.js';
import type { Level, LoaderResponse, MediaPlaylist, ErrorData, HlsConfig } from 'hls.js';
import useAuthenticatedStream from 'src/hooks/use-authenticated-stream';
import useStreamingSettings from 'src/hooks/use-streaming-settings';
import { usePlayback } from 'src/state/playback';
import useMuxVideoTracking, { logPlaybackErrorInMux } from 'src/tracking/mux';
import { PlayableMedia } from 'src/types';
import { captureException } from 'src/utilities/exceptions';
import { ErrorTypes } from 'src/utilities/hls-js-extracted';
import {
  adjustToStreamingSettingsHlsJs,
  adjustToStreamingSettingsNativeHls,
  hlsPlaybackSupport,
  processPlaylistManifest,
  setupNativeHls,
  switchToAudioTrackNativeHls,
} from 'src/utilities/streaming-helpers';

export type PlaybackError = {
  errorType: ErrorTypes;
  message?: string;
  code?: number;
};

type UseStreamingParameters = {
  videoElRef: RefObject<HTMLVideoElement>;
  stream: ReturnType<typeof usePlayback>['currentStream'];
  mediaTypename?: Pick<PlayableMedia, '__typename'>['__typename'];
  onError?: (code: MediaError['code'], message: MediaError['message']) => void;
};

type ErrorLoaderResponse = {
  code?: number;
} & LoaderResponse;

// a basic audio track type, should have overlap with the hls.js MediaPlaylist and the native AudioTrack
type AudioTrackBasic = {
  id: string;
  label: string;
};

type VideoLevelsController = {
  videoLevels: Level[];
  currentVideoLevel: Level | undefined;
  switchLevel: ((levelIndex: number) => void) | undefined;
};

type AudioTracksController = {
  audioTracks: AudioTrackBasic[] | undefined;
  currentAudioTrack: AudioTrackBasic | undefined;
  switchAudioTrack: ((trackId: string) => void) | undefined;
};

/**
 * A helper function to convert the hls.js MediaPlaylist to a basic audio track type
 * @param playlist
 */
function convertHlsMediaPlaylistToAudioTrack(playlist: MediaPlaylist): AudioTrackBasic {
  return {
    id: String(playlist.id),
    label: playlist.name,
  };
}

/**
 *
 * @param type - what is used to play back the HLS streams - native or hlsjs player?
 * @param message - the main error message
 * @param context - an additional context to be passed to Sentry
 */
function captureStreamException(
  type: 'hlsjs' | 'native',
  message: string,
  context: Parameters<typeof captureException>[1],
) {
  captureException(new Error(`Web Player ${type === 'native' ? 'Native' : 'Hls.Js'}: ${message}`), context);
}

// this is a dynamic import to avoid bundling hls.js in the main bundle,
// as the library significantly increases the bundle size
const hlsJsDynamic = async () => {
  const HlsDynamic = await import('hls.js');
  // expose parts of the dynamically loaded Hls library the rest of the streaming code
  return { Hls: HlsDynamic.default, Events: HlsDynamic.default.Events, ErrorDetails: HlsDynamic.default.ErrorDetails };
};

// enable stream debug logging to the console when the URL contains debugstream=true
export const enableStreamDebug = typeof window !== 'undefined' && window.location.search.includes('debugstream=true');

/**
 * The hook allows us to stream HLS videos using the <video> tag and Media Source Extensions API (hls.js)
 * @hook
 */
const useStreaming = ({ videoElRef, stream }: UseStreamingParameters) => {
  // maintain a reference to the HLS instance between video url changes
  const hlsRef = useRef<Hls | undefined>();
  // get the authenticated stream URL, based on the API video url
  const authenticatedStream = useAuthenticatedStream(stream?.url);

  // prepare the Mux.com video data monitoring tool
  const trackWithMux = useMuxVideoTracking();

  // store the most recent error
  const [playbackError, setPlaybackError] = useState<PlaybackError | undefined>();

  //  get the streaming settings for the current user
  const { lowDataModeEnabled, dolbyAtmosModeEnabled } = useStreamingSettings();

  // maintain and expose info about the available stream qualities
  const [videoLevels, setVideoLevels] = useState<Level[]>([]);
  const [currentVideoLevel, setCurrentVideoLevel] = useState<Level>();
  const switchVideoLevel = useRef<(levelIndex: number) => void>();
  const [audioTracks, setAudioTracks] = useState<AudioTrackBasic[]>([]);
  const [currentAudioTrack, setCurrentAudioTrack] = useState<AudioTrackBasic>();
  const switchAudioTrack = useRef<(trackLabel: string) => void>();
  // group the video quality level controls in one compact interface
  const levelController: VideoLevelsController = {
    videoLevels,
    currentVideoLevel,
    switchLevel: switchVideoLevel.current,
  };
  // group the audio quality level controls in one compact interface
  const audioTracksController: AudioTracksController = {
    audioTracks,
    currentAudioTrack,
    switchAudioTrack: switchAudioTrack.current,
  };

  // the async interval we'll use to recover from streaming errors
  const recoverIntervalRef = useRef<NodeJS.Timeout | undefined>();

  // this is used to handle errors coming from the native HLS stream e.g. on iOS
  const nativeErrorHandler = useCallback(
    (code: MediaError['code'], message: MediaError['message']) => {
      // map MediaError codes to ErrorTypes
      let errorType;
      // convert native errors to more readable errorTypes
      switch (code) {
        case 2: {
          errorType = ErrorTypes.NETWORK_ERROR;
          break;
        }
        case 3: {
          errorType = ErrorTypes.MEDIA_ERROR;
          break;
        }
        case 4: {
          errorType = ErrorTypes.KEY_SYSTEM_ERROR;
          break;
        }
        default: {
          errorType = ErrorTypes.OTHER_ERROR;
          break;
        }
      }
      setPlaybackError({ errorType, code, message });
      // send the error to Sentry
      captureStreamException('native', message, { extra: { errorType, code, message } });
    },
    [setPlaybackError],
  );

  // this is used to handle errors coming from hls.js engine e.g. on Chrome
  const hlsJsErrorHandler = useCallback(
    (hls: Hls, data: ErrorData) => {
      // extract the error type from the data
      const errorType: ErrorTypes = data.type;
      // extract the HTTP error code from the response
      const httpCode = data.response && (data.response as ErrorLoaderResponse).code;
      const playbackErrorData = {
        errorType: errorType,
        code: httpCode || 0,
        message: data.details,
      };
      // we have to cast the error types to the extracted enum version
      switch (errorType) {
        case ErrorTypes.NETWORK_ERROR: {
          // recover from the errors that are probably temporary
          if (httpCode === 404) {
            // playlist file 404'd (maybe because of CDN replication), wait 1 second and retry
            console.error('network error on playlist load, retrying in 1 second.', data);
            // set error for use in UI
            setPlaybackError({ errorType });
            // delay the retry by 1 second
            recoverIntervalRef.current = setTimeout(() => {
              // try to recover from temporary error
              hls?.recoverMediaError();
              // reset the error for now
              setPlaybackError(undefined);
            }, 1000);
            // Capture handled errors as message (warning)
            captureStreamException('hlsjs', playbackErrorData.message, { level: 'warning' });
          } else {
            // pass the information about the causes of the error up the stack
            setPlaybackError(playbackErrorData);
            // Capture critical errors
            captureStreamException('hlsjs', playbackErrorData.message, {
              level: 'error',
              extra: { httpCode, errorType, details: data.details },
            });
            // report a network error to the Mux.com video quality monitoring service
            if (videoElRef.current) {
              void logPlaybackErrorInMux(videoElRef.current, playbackErrorData);
            }
          }
          break;
        }
        // Unknown errors
        default: {
          // Log the error in the console
          console.error('Stream Errored', playbackErrorData, data);
          if (data.fatal) {
            // report a fatal error to the Mux.com video quality monitoring service
            if (videoElRef.current) {
              void logPlaybackErrorInMux(videoElRef.current, playbackErrorData);
            }
            // and log in our error tracking tool
            // Capture critical errors
            captureStreamException('hlsjs', playbackErrorData.message, {
              level: 'error',
              extra: {
                ...playbackErrorData,
                cause: data,
              },
            });
            // display a player error when a fatal error appears
            setPlaybackError(playbackErrorData);
          }
        }
      }
    },
    [videoElRef],
  );

  // this is used to set up the hls.js to play the audio/video stream
  // it has to be called every time the authenticated stream url changes
  const setupHlsJsStream = useCallback(async () => {
    if (!authenticatedStream.url && videoElRef.current) {
      // pause the video when the stream was reset, e.g. the player was closed
      videoElRef.current.pause();
      return;
    }

    if (authenticatedStream.url && videoElRef.current) {
      const { Hls, Events, ErrorDetails } = await hlsJsDynamic();

      // Now set up the HLS.js engine, it is recommended to init it for each new video source
      const hls = new Hls({
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90,
        // @todo [2025-02-12] check with the streaming engineer if the dolby manifests still need this protection
        loader: class extends Hls.DefaultConfig.loader {
          constructor(config: HlsConfig) {
            super(config);
            const load = this.load.bind(this);
            this.load = function (context, config, callbacks) {
              if ('type' in context && context.type == 'manifest') {
                const onSuccess = callbacks.onSuccess;
                callbacks.onSuccess = function (response, stats, context, networkDetails) {
                  // rewrite the manifest if needed
                  response.data = processPlaylistManifest(response.data);
                  onSuccess(response, stats, context, networkDetails);
                };
              }
              load(context, config, callbacks);
            };
          }
        },
        xhrSetup: (xhr) => {
          // Configure the XMLHttpRequest object used by Hls.js to send cross-site requests with credentials
          // This is necessary to send a JWT token as a cookie along with subsequent requests to the stream domain
          // The token is obtained via a Set-Cookie header in the response to the initial manifest request
          // Without this setting, subsequent requests to the stream domain (needed for .ts segments) would result in a 403 Unauthorized error
          xhr.withCredentials = true;
        },
      });

      // check if the HLS.js was initialized before
      if (hlsRef.current) {
        // destroy the past hls set up as we are setting up a new one
        hlsRef.current.destroy();
      }
      // Now store the new HLS.js instance in a ref
      hlsRef.current = hls;

      // reset old errors at first
      setPlaybackError(undefined);

      // load the video source
      hls.loadSource(authenticatedStream.url);

      // connect the streaming engine to the video tag element
      hls.attachMedia(videoElRef.current);
      // enable the level switch handler
      switchVideoLevel.current = (levelIndex: number) => {
        // set the current level immediately
        hls.currentLevel = levelIndex;
      };
      // enable the audio track switch handler
      switchAudioTrack.current = (trackLabel: string) => {
        // set the current audio track immediately, we are using unique names to make sure we match the right track
        const trackIndex = hls.allAudioTracks.findIndex((track) => track.name === trackLabel);
        hls.setAudioOption(hls.allAudioTracks[trackIndex]);
      };

      // get the possible resolutions once we have all the HLS data
      hls.on(Events.MANIFEST_LOADED, () => {
        // automatically adjust the level controller to the users preferences
        adjustToStreamingSettingsHlsJs({
          hls,
          lowDataMode: lowDataModeEnabled,
          preferDolbyAtmos: dolbyAtmosModeEnabled,
          preferHighestVideoQuality: true, // lets try to load the highest quality by default
        });
        // get the available video resolutions and store them in the state, so it can be displayed in the UI
        setVideoLevels(hls.levels);
        // get all audio tracks from all audio groups and store them in the state
        const audioTracksArray = hls.allAudioTracks
          // convert the audio tracks to a basic type
          .map(convertHlsMediaPlaylistToAudioTrack)
          // Note: the Dolby Atmos track is SOMETIMES included in the audioTracks list even if the browser does not support it
          // this happens because of the inconsistency of our manifest files and post-processing on the client side
          // to be safe, we filter out the Dolby Atmos track from the list of audio tracks
          .filter((track) => track.label !== 'atmos');
        // get the selectable audio tracks and store them in the state
        setAudioTracks(audioTracksArray);
      });
      // when the video quality changes, update the state
      hls.on(Events.LEVEL_SWITCHED, () => {
        setCurrentVideoLevel(hls.levels[hls.currentLevel]);
        // get the current audio track and store it in the state
        // keep in mind that in some rare cases, the audio track might not be available
        const currentAudioTrack = hls.audioTracks[hls.audioTrack];
        setCurrentAudioTrack(currentAudioTrack ? convertHlsMediaPlaylistToAudioTrack(currentAudioTrack) : undefined);
      });
      // when the audio quality changes, update the state
      hls.on(Events.AUDIO_TRACK_SWITCHED, () => {
        // get the current audio track and store it in the state
        // keep in mind that in some rare cases, the audio track might not be available
        const currentAudioTrack = hls.audioTracks[hls.audioTrack];
        setCurrentAudioTrack(currentAudioTrack ? convertHlsMediaPlaylistToAudioTrack(currentAudioTrack) : undefined);
      });

      hls.on(Events.LEVEL_PTS_UPDATED, () => {
        const latestCurrentLevel = hls.levels[hls.currentLevel];
        // this will try to keep the current level in sync with the automatic
        // bandwidth-related adjustments in hls.js
        setCurrentVideoLevel(latestCurrentLevel);
      });

      hls.on(Events.FPS_DROP, () => {
        const latestCurrentLevel = hls.levels[hls.currentLevel];
        //  this will try to keep the current level in sync with the automatic
        // bandwidth-related adjustments in hls.js
        setCurrentVideoLevel(latestCurrentLevel);
      });

      // catch the errors coming from the streaming engine
      hls.on(Events.ERROR, (_event, data) => {
        // handle the errors appropriately
        hlsJsErrorHandler(hls, data);
        // lets switch to auto-quality if buffer stalling errors occur
        if (data.details === ErrorDetails.BUFFER_STALLED_ERROR) {
          adjustToStreamingSettingsHlsJs({
            hls,
            lowDataMode: lowDataModeEnabled,
            preferDolbyAtmos: dolbyAtmosModeEnabled,
            preferHighestVideoQuality: false,
          });
        }
      });
      // set up the Mux video quality monitoring
      void trackWithMux({
        videoElement: videoElRef.current,
        playbackMethod: 'hlsJs',
        videoURL: authenticatedStream.url,
        hlsInstance: hls,
        hlsConstructor: Hls,
      });
    }
  }, [authenticatedStream.url, videoElRef, trackWithMux, lowDataModeEnabled, dolbyAtmosModeEnabled, hlsJsErrorHandler]);

  const setupNativeHlsStream = useCallback(() => {
    // set up the native HLS playback
    const { cleanupNativeHls } = setupNativeHls({
      videoElRef,
      streamUrl: authenticatedStream?.url,
      onError: nativeErrorHandler,
      // when the video is ready to play through the native HLS, we can set up the quality controls
      onReady: () => {
        const audioTracksArray = [...(videoElRef.current?.audioTracks || [])];
        const activeAudioTrack = audioTracksArray.find((track) => track.enabled);
        // store all available audio tracks in the state
        setAudioTracks(audioTracksArray);
        // store the currently active audio track in the state
        setCurrentAudioTrack(activeAudioTrack);
        // automatically adjust the audio/video channels to the users preferences
        adjustToStreamingSettingsNativeHls({
          videoElement: videoElRef.current,
          lowDataMode: lowDataModeEnabled,
          preferDolbyAtmos: dolbyAtmosModeEnabled,
          preferHighestVideoQuality: true, // lets try to load the highest quality by default
        });
        // set up the audio track switch handler
        switchAudioTrack.current = (trackLabel: string) => {
          // set the current audio track immediately, we are using labels to make sure we match the right track
          const audioTracksArray = [...(videoElRef.current?.audioTracks || [])];
          const audioTrackToEnable = audioTracksArray.find((track) => track.label === trackLabel);
          if (audioTrackToEnable) {
            switchToAudioTrackNativeHls(audioTracksArray, audioTrackToEnable);
          }
        };
      },
      onAudioChange: () => {
        // when the audio track changes, update the state
        // (note, the `change` will fire only when we actually set one of the audio tracks as enabled)
        const audioTracksArray = [...(videoElRef.current?.audioTracks || [])];
        const activeAudioTrack = audioTracksArray.find((track) => track.enabled);
        setCurrentAudioTrack(activeAudioTrack);
      },
    });

    // set up the Mux video quality monitoring
    void trackWithMux({
      videoElement: videoElRef.current,
      playbackMethod: 'hlsNative',
      // track the original, un-authenticated url in MUX
      videoURL: stream?.url,
    });
    // return the cleanup function, so the event handlers can be cleaned up when the video url changes
    return () => {
      cleanupNativeHls();
      setAudioTracks([]);
      setCurrentAudioTrack(undefined);
    };
  }, [
    authenticatedStream?.url,
    dolbyAtmosModeEnabled,
    lowDataModeEnabled,
    nativeErrorHandler,
    stream?.url,
    trackWithMux,
    videoElRef,
  ]);

  useEffect(() => {
    // check how browser will play the HLS stream
    const hlsPlaybackMethod = hlsPlaybackSupport();

    if (hlsPlaybackMethod === null) {
      // if neither HLS.js nor native HLS playback is supported, we can't do anything
      setPlaybackError({ errorType: ErrorTypes.OTHER_ERROR, message: 'HLS playback is not supported' });
      return;
    }
    // if we can play native HLS,
    // we can set it up as a basic <video> tag with a HLS source directly
    if (hlsPlaybackMethod === 'hlsNative') {
      const cleanupNativeHls = setupNativeHlsStream();
      // when the video url changes, clean up the native hls set up
      return cleanupNativeHls;
      // if we e.g. are on iOS, we don't need to do anything else
    } else {
      // the rest of the hook is relevant for the non-native HLS playback,
      // we will use the HLS.js and MediaSource Extensions to play the stream

      // create and set up a new HLS.js instance
      void setupHlsJsStream();

      return () => {
        // remove a pending retry interval
        if (recoverIntervalRef.current) {
          clearInterval(recoverIntervalRef.current);
        }
      };
    }
  }, [setupHlsJsStream, setupNativeHlsStream]);

  // Listen to authenticated stream url errors and show them to the user as a playback error
  useEffect(() => {
    if (authenticatedStream.error) {
      setPlaybackError({ errorType: ErrorTypes.OTHER_ERROR, message: authenticatedStream.error.message });
    }
  }, [authenticatedStream.error]);

  return { levels: levelController, playbackError, audio: audioTracksController };
};

export default useStreaming;
