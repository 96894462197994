import type { SVGProps } from 'react';
const SvgSmallChevron = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m9.563 7.414-.47.445c-.093.118-.093.305 0 .399l4.243 4.242-4.242 4.266c-.094.093-.094.28 0 .398l.469.445a.27.27 0 0 0 .398 0l4.922-4.898a.32.32 0 0 0 0-.399L9.96 7.414a.27.27 0 0 0-.398 0"
    />
  </svg>
);
export default SvgSmallChevron;
