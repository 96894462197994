/**
 * Hls.js error types, extracted from the library:
 * https://github.com/video-dev/hls.js/blob/master/src/errors.ts
 */

export enum ErrorTypes {
  // Identifier for a network error (loading error / timeout ...)
  NETWORK_ERROR = 'networkError',
  // Identifier for a media Error (video/parsing/mediasource error)
  MEDIA_ERROR = 'mediaError',
  // EME (encrypted media extensions) errors
  KEY_SYSTEM_ERROR = 'keySystemError',
  // Identifier for a mux Error (demuxing/remuxing)
  MUX_ERROR = 'muxError',
  // Identifier for all other errors
  OTHER_ERROR = 'otherError',
}

/**
 * Check if the browser supports hls.js playback.
 * Extracted from the hls.js project to run the playback compatibility check
 * before loading the full hls.js library.
 * See the original at:
  https://github.com/video-dev/hls.js/blob/master/src/is-supported.ts
 */

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    WebKitMediaSource?: MediaSource;
    WebKitSourceBuffer?: SourceBuffer;
  }
}

function getMediaSource(): typeof MediaSource | undefined {
  return self.MediaSource || (self as Window).WebKitMediaSource!;
}

function getSourceBuffer(): typeof self.SourceBuffer {
  return self.SourceBuffer || (self as Window).WebKitSourceBuffer;
}

function isMSESupported(): boolean {
  const mediaSource = getMediaSource();
  if (!mediaSource) {
    return false;
  }

  // if SourceBuffer is exposed ensure its API is valid
  // Older browsers do not expose SourceBuffer globally so checking SourceBuffer.prototype is impossible
  const sourceBuffer = getSourceBuffer();
  return (
    !sourceBuffer ||
    (sourceBuffer.prototype &&
      typeof sourceBuffer.prototype.appendBuffer === 'function' &&
      typeof sourceBuffer.prototype.remove === 'function')
  );
}

type CodecType = 'audio' | 'video';

function mimeTypeForCodec(codec: string, type: CodecType): string {
  return `${type}/mp4;codecs="${codec}"`;
}

export function HlsJsIsSupported(): boolean {
  if (!isMSESupported()) {
    return false;
  }

  const mediaSource = getMediaSource();
  return (
    typeof mediaSource?.isTypeSupported === 'function' &&
    (['avc1.42E01E,mp4a.40.2', 'av01.0.01M.08', 'vp09.00.50.08'].some((codecsForVideoContainer) =>
      mediaSource.isTypeSupported(mimeTypeForCodec(codecsForVideoContainer, 'video')),
    ) ||
      ['mp4a.40.2', 'fLaC'].some((codecForAudioContainer) =>
        mediaSource.isTypeSupported(mimeTypeForCodec(codecForAudioContainer, 'audio')),
      ))
  );
}
