import { FC, SVGProps } from 'react';

type IconProps = SVGProps<SVGSVGElement>;
type NavigationIconProps = IconProps & { filled: boolean };
export type NavigationIcon = FC<NavigationIconProps>;

function AudioOutlineIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24" {...props}>
      <g fill="currentColor">
        <path d="m3.67001 24c-.56059.0107-1.10261-.201-1.50743-.589-.40481-.3879-.63945-.9205-.65257-1.481v-7.61c.03723-.5462.28045-1.0578.6805-1.4315.40004-.3737.92706-.5816 1.4745-.5816s1.07446.2079 1.4745.5816c.40005.3737.64327.8853.6805 1.4315v7.61c-.00521.2771-.06496.5505-.17583.8045s-.2707.4837-.47035.6759c-.19966.1922-.43523.3432-.69325.4444-.25803.1012-.53346.1505-.81057.1452zm0-10.25c-.1629-.011-.32359.0426-.44716.1494-.12357.1067-.20005.2578-.21284.4206v7.61c.01526.1618.09245.3114.21544.4177.12299.1062.28227.1608.44456.1523.16123.0083.31935-.0466.44074-.153.12138-.1065.19645-.2561.20926-.417v-7.61c-.00381-.081-.02375-.1605-.05864-.2337-.03489-.0733-.08404-.1388-.14456-.1928-.06053-.054-.13122-.0954-.20794-.1218-.07671-.0263-.15792-.0371-.23886-.0317z" />
        <path d="m20.34 24c-.5606.0107-1.1026-.201-1.5074-.589-.4048-.3879-.6395-.9205-.6526-1.481v-7.61c.0372-.5462.2804-1.0578.6805-1.4315.4-.3737.9271-.5816 1.4745-.5816s1.0744.2079 1.4745.5816c.4.3737.6433.8853.6805 1.4315v7.61c-.0052.2771-.0649.5505-.1758.8045s-.2707.4837-.4704.6759c-.1996.1922-.4352.3432-.6932.4444-.2581.1012-.5335.1505-.8106.1452zm0-10.25c-.1623-.0085-.3216.0461-.4446.1523-.123.1063-.2002.2559-.2154.4177v7.61c.0152.1618.0924.3114.2154.4177.123.1062.2823.1608.4446.1523.1612.0083.3193-.0466.4407-.153.1214-.1065.1965-.2561.2093-.417v-7.61c-.0128-.1609-.0879-.3105-.2093-.417-.1214-.1064-.2795-.1613-.4407-.153z" />
        <path d="m1.5 18.29h-1.5v-6.9c0-6.28 5.38-11.39 12-11.39v1.5c-5.79 0-10.5 4.44-10.5 9.89z" opacity=".4" />
        <path d="m24 18.29h-1.5v-6.9c0-5.45-4.71-9.89-10.5-9.89v-1.5c6.62 0 12 5.11 12 11.39z" opacity=".8" />
      </g>
    </svg>
  );
}

function AudioFilledIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 25 25" width="24" height="24" {...props}>
      <path
        d="m12 .980003c-6.62 0-12 5.109997-12 11.389997v6.9h1.51001v3.62c.03723.5462.28045 1.0579.6805 1.4316.40004.3737.92706.5815 1.4745.5815s1.07446-.2078 1.4745-.5815c.40005-.3737.64327-.8854.6805-1.4316v-7.61c-.01496-.5477-.23847-1.0691-.62488-1.4575-.38641-.3885-.90656-.6148-1.45419-.6327-.54764-.0178-1.08144.174-1.49236.5365-.41092.3624-.66793.8681-.71857 1.4137v-2.77c0-5.46 4.71-9.89 10.49999-9.89 5.79 0 10.5 4.43 10.5 9.89v2.77c-.0678-.5354-.3336-1.0261-.7451-1.3753-.4115-.3493-.9388-.5318-1.4781-.5118-.5393.0201-1.0516.2413-1.436.6202-.3843.3789-.6129.8879-.6408 1.4269v7.68c.0372.5462.2805 1.0578.6805 1.4316.4001.3737.9271.5815 1.4745.5815.5475 0 1.0745-.2078 1.4745-.5815.4001-.3738.6433-.8854.6805-1.4316v-3.64h1.51v-6.9c-.05-6.32999-5.43-11.459997-12.05-11.459997z"
        fill="currentColor"
      />
    </svg>
  );
}

function DiscoverOutlineIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24" {...props}>
      <g fill="currentColor">
        <path
          d="m8.27 11.09h-5.45c-.74791 0-1.46519-.2971-1.994042-.826-.528852-.52881-.825958-1.24609-.825958-1.994v-5.44999c.0026353-.7471.30059-1.46285.828873-1.991137.528287-.528283 1.244027-.8262377 1.991127-.828873h5.45c.74444.00527984 1.45659.304711 1.9811.832993.5246.528277.8189 1.242557.8189 1.987017v5.44999c0 .74446-.2943 1.45872-.8189 1.987-.52451.5283-1.23666.8277-1.9811.833zm-5.45-9.68c-.37396 0-.73259.14856-.99702.41298-.26442.26443-.41298.62307-.41298.99703v5.44999c0 .37396.14856.73259.41298.99701.26443.26443.62306.41298.99702.41298h5.45c.37395 0 .73259-.14855.99702-.41298.26443-.26442.41298-.62305.41298-.99701v-5.44999c0-.37396-.14855-.7326-.41298-.99703-.26443-.26442-.62307-.41298-.99702-.41298z"
          opacity=".6"
        />
        <path
          d="m21.18 11.09h-5.45c-.7479 0-1.4652-.2971-1.9941-.826-.5288-.52881-.8259-1.24609-.8259-1.994v-5.44999c0-.74791.2971-1.4652.8259-1.994052.5289-.528852 1.2462-.825958 1.9941-.825958h5.45c.7479 0 1.4652.297106 1.994.825958.5289.528852.826 1.246142.826 1.994052v5.44999c0 .74791-.2971 1.46519-.826 1.994-.5288.5289-1.2461.826-1.994.826zm-5.45-9.68c-.374 0-.7326.14856-.997.41298-.2645.26443-.413.62307-.413.99703v5.44999c0 .37396.1485.73259.413.99701.2644.26443.623.41298.997.41298h5.45c.3739 0 .7326-.14855.997-.41298.2644-.26442.413-.62305.413-.99701v-5.44999c0-.37396-.1486-.7326-.413-.99703-.2644-.26442-.6231-.41298-.997-.41298z"
          opacity=".8"
        />
        <path
          d="m8.27 24h-5.45c-.74791 0-1.46519-.2971-1.994042-.826-.528852-.5288-.825958-1.2461-.825958-1.994v-5.45c0-.7479.297106-1.4652.825958-1.994.528852-.5289 1.246132-.826 1.994042-.826h5.45c.74444.0053 1.45659.3047 1.9811.833.5246.5283.8189 1.2425.8189 1.987v5.45c0 .7445-.2943 1.4587-.8189 1.987-.52451.5283-1.23666.8277-1.9811.833zm-5.45-9.68c-.37396 0-.73259.1486-.99702.413-.26442.2644-.41298.623-.41298.997v5.45c0 .3739.14856.7326.41298.997.26443.2644.62306.413.99702.413h5.45c.37395 0 .73259-.1486.99702-.413s.41298-.6231.41298-.997v-5.45c0-.374-.14855-.7326-.41298-.997s-.62307-.413-.99702-.413z"
          opacity=".4"
        />
        <path d="m18.46 24c-1.0981.002-2.1721-.3218-3.0861-.9305-.914-.6086-1.6269-1.4747-2.0485-2.4887-.4216-1.0139-.533-2.1301-.3199-3.2074.213-1.0773.7408-2.0671 1.5165-2.8443.7758-.7772 1.7647-1.3068 2.8416-1.5217 1.0769-.215 2.1933-.1057 3.208.3141s1.8821 1.1311 2.4924 2.0441c.6103.9129.936 1.9863.936 3.0844-.0026 1.4694-.5868 2.878-1.6249 3.918s-2.4457 1.6267-3.9151 1.632zm0-9.68c-1.0953 0-2.1458.4351-2.9203 1.2097-.7745.7745-1.2097 1.825-1.2097 2.9203-.0019.8197.2394 1.6215.6936 2.3038.4541.6824 1.1006 1.2145 1.8575 1.5291s1.5901.3974 2.3941.238c.8041-.1594 1.5426-.5539 2.1222-1.1335s.9741-1.3182 1.1336-2.1222c.1594-.804.0766-1.6372-.238-2.3941s-.8468-1.4034-1.5292-1.8575c-.6823-.4542-1.4841-.6956-2.3038-.6936z" />
      </g>
    </svg>
  );
}

function DiscoverFilledIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 24 25" width="24" height="24" {...props}>
      <g fill="currentColor">
        <path d="m8.27001 12.09h-5.45c-.74791 0-1.46519-.2971-1.994042-.826-.528852-.5288-.82595811-1.2461-.82595811-1.99401v-5.44999c-.00263841-.37201.06835631-.74087.20889311-1.08532.140536-.34445.347838-.65767.609962-.92166.262125-.26398.573885-.4735.917335-.61647.34345-.14296.71179-.21656 1.08381-.21655h5.45c.37033.002617.73652.07815 1.07766.22229.34113.14414.65054.35408.91053.6178.26.26372.4655.57605.6048.9192s.2096.71038.207 1.08071v5.44999c0 .74451-.2943 1.45871-.8189 1.98701-.5245.5283-1.23665.8277-1.98109.833z" />
        <path d="m21.18 12.09h-5.45c-.7479 0-1.4652-.2971-1.9941-.826-.5288-.5288-.8259-1.2461-.8259-1.99401v-5.44999c-.0027-.37201.0683-.74087.2089-1.08532.1405-.34445.3478-.65767.6099-.92166.2622-.26398.5739-.4735.9174-.61647.3434-.14296.7118-.21656 1.0838-.21655h5.45c.7479 0 1.4652.29711 1.994.82596.5289.52885.826 1.24614.826 1.99405v5.44999c.0026.37201-.0684.74087-.2089 1.0853-.1405.3445-.3478.6577-.61.9217-.2621.264-.5739.4735-.9173.6164-.3435.143-.7118.2166-1.0838.2166z" />
        <path d="m8.27 24.98h-5.45c-.7471-.0026-1.46284-.3006-1.991127-.8289-.528283-.5282-.8262377-1.244-.828873-1.9911v-5.45c0-.7479.297106-1.4652.825958-1.994.528852-.5289 1.246132-.826 1.994042-.826h5.45c.37033.0026.73652.0782 1.07766.2223.34113.1441.65054.3541.91054.6178s.4655.576.6048.9192c.1393.3431.2096.7104.207 1.0807v5.45c-.0052.741-.3019 1.4501-.8259 1.9741-.52396.524-1.23311.8207-1.9741.8259z" />
        <path d="m18.46 24.98c-1.0981.002-2.1721-.3218-3.0861-.9305-.914-.6086-1.6269-1.4747-2.0485-2.4887-.4216-1.0139-.533-2.1301-.3199-3.2074.213-1.0773.7408-2.0671 1.5165-2.8443.7758-.7772 1.7647-1.3068 2.8416-1.5217 1.0769-.215 2.1933-.1057 3.208.3141s1.8821 1.1311 2.4924 2.0441c.6103.9129.936 1.9863.936 3.0844-.0026 1.4694-.5868 2.878-1.6249 3.918s-2.4457 1.6267-3.9151 1.632z" />
      </g>
    </svg>
  );
}

function LiveOutlineIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 24 24" width="24" height="24" {...props}>
      <g fill="currentColor">
        <path
          d="m17.53 24h-5.53v-1.41h5.53c1.3438-.0053 2.6308-.5428 3.5792-1.495.9484-.9521 1.4808-2.2412 1.4808-3.585v-11.00001c0-1.34384-.5324-2.63292-1.4808-3.58503s-2.2354-1.48968-3.5792-1.49497h-5.53v-1.42999h5.53c.851.00131221 1.6933.170222 2.479.497086.7857.326863 1.4993.805274 2.1001 1.407924.6008.60266 1.077 1.31774 1.4015 2.10444.3244.78669.4907 1.62959.4894 2.48056v10.99999c.0039.8527-.1604 1.6977-.4837 2.4867-.3232.789-.799 1.5064-1.4001 2.1112-.601.6048-1.3155 1.085-2.1025 1.4131s-1.631.4977-2.4837.499z"
          opacity=".8"
        />
        <path d="m9.48003 17.4c-.16414-.0008-.32555-.042-.47-.12-.1738-.0996-.31583-.2464-.40963-.4233-.09381-.177-.13553-.377-.12037-.5767v-8.60001c-.0128-.1994.02995-.39844.12352-.57499.09357-.17654.23428-.32367.40648-.42501.17545-.09288.37345-.13462.57146-.12047.19801.01414.38807.0836.54851.20047l6.7 4.24001c.1748.1008.3191.2469.4176.4229.0986.1761.1478.3754.1424.5771.0033.2013-.0468.3998-.1452.5755-.0983.1756-.2414.3221-.4148.4245l-6.71 4.25c-.19458.1101-.41668.1622-.63997.15zm.4-9.12001v7.43001l5.86997-3.72zm6.58997 4.08001-.38-.59.38.59-.38-.59z" />
        <path
          d="m12 24h-5.52999c-.85097-.0013-1.69333-.1702-2.47902-.4971-.78569-.3268-1.4993-.8053-2.1001-1.4079-.60079-.6027-1.077004-1.3178-1.401441-2.1044-.324436-.7867-.49075478-1.6296-.48944055-2.4806v-11.00001c-.00394848-.85264.16041655-1.69767.48367355-2.48667.323256-.78901.799058-1.50647 1.400118-2.11124.60105-.60477 1.31556-1.084974 2.10255-1.413087.787-.328114 1.63101-.49768632 2.48366-.498993h5.52999v1.41h-5.52999c-1.34383.00529-2.63081.54284-3.57918 1.49495-.94836.95211-1.48083 2.24121-1.48082 3.58506v10.99999c-.00001 1.3438.53246 2.6329 1.48082 3.585.94837.9522 2.23535 1.4897 3.57918 1.495h5.52999z"
          opacity=".4"
        />
      </g>
    </svg>
  );
}

function LiveFilledIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 24 25" width="24" height="24" {...props}>
      <path
        d="m17.53.98h-11.05999c-.85097.001312-1.69333.17022-2.47902.49709-.78569.32686-1.4993.80527-2.1001 1.40792-.60079.60266-1.077004 1.31774-1.401441 2.10443-.324436.7867-.49075478 1.6296-.48944055 2.48057v10.99999c-.00394848.8527.16041655 1.6977.48367355 2.4867.323256.789.799058 1.5064 1.400118 2.1112.60105.6048 1.31556 1.085 2.10255 1.4131.787.3281 1.63101.4977 2.48366.499h11.05999c.851-.0013 1.6934-.1702 2.479-.4971.7857-.3268 1.4993-.8053 2.1001-1.4079.6008-.6027 1.077-1.3178 1.4015-2.1044.3244-.7867.4907-1.6296.4894-2.4806v-11.00001c.004-.85264-.1604-1.69767-.4837-2.48668-.3232-.789-.799-1.50646-1.4001-2.11123-.601-.60477-1.3155-1.08497-2.1025-1.41309-.787-.32811-1.631-.497684-2.4837-.49899zm-1.08 12.33-6.68998 4.23c-.32.2-.57001.06-.57001-.31v-8.56c0-.38.26-.52001.58-.32001l6.66999 4.23001c.077.0274.1437.0776.1912.1441.0475.0664.0736.1458.0747.2274.0011.0817-.0229.1617-.0686.2294-.0457.0676-.1111.1197-.1873.1491z"
        fill="currentColor"
      />
    </svg>
  );
}

function ProfileOutlineIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 24 25" width="24" height="24" {...props}>
      <g fill="currentColor">
        <path d="m12 13.89c-1.279.0139-2.53328-.3528-3.60337-1.0535-1.07008-.7007-1.90769-1.7037-2.40634-2.88161-.49864-1.17787-.6358-2.47742-.39407-3.73345.24172-1.25604.85141-2.41183 1.75161-3.3205.90021-.90867 2.05028-1.52918 3.30397-1.78265 1.2537-.253475 2.5545-.128456 3.737.35914 1.1825.4876 2.1933 1.31576 2.904 2.37924s1.0891 2.31425 1.0872 3.59333c.0053 1.69924-.6632 3.3313-1.8592 4.5384-1.1959 1.2072-2.8216 1.891-4.5208 1.9016zm0-11.38001c-.9799-.01388-1.9418.26401-2.76334.79831-.82154.53431-1.4656 1.30089-1.85025 2.20224-.38466.90136-.49254 1.89674-.30991 2.85958.18263.96283.64754 1.84958 1.33554 2.54748.68801.6979 1.56801 1.1754 2.52816 1.3717.9601.1964 1.9569.1027 2.8637-.269.9067-.3718 1.6824-1.0048 2.2284-1.8186.546-.81387.8376-1.7717.8377-2.7517.0052-.64432-.1166-1.28335-.3586-1.88051-.242-.59717-.5995-1.14074-1.0518-1.5996-.4524-.45885-.9908-.82398-1.5844-1.07449-.5937-.25051-1.2309-.38148-1.8752-.38541z" />
        <path
          d="m1.5 25h-1.5v-2.82c.00529532-2.0334.816794-3.9817 2.2565-5.4177 1.43971-1.4359 3.39009-2.2423 5.42349-2.2423h4.32001v1.48h-4.32001c-1.63477.0026-3.20212.6518-4.35995 1.8059s-1.81212 2.7193-1.82004 4.3541z"
          opacity=".4"
        />
        <path
          d="m24 25h-1.5v-2.82c-.0158-1.6462-.6836-3.2189-1.8571-4.3736-1.1734-1.1547-2.7767-1.8171-4.4229-1.8064h-4.22v-1.48h4.24c1.0125-.0066 2.0164.1863 2.9543.5677.938.3814 1.7916.9438 2.5122 1.6551s1.294 1.5576 1.6875 2.4905.5995 1.9342.606 2.9467z"
          opacity=".8"
        />
      </g>
    </svg>
  );
}

function ProfileFilledIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 24 25" width="24" height="24" {...props}>
      <g fill="currentColor">
        <path d="m12 13.89c1.2786.0139 2.5324-.3526 3.6023-1.0528 1.0699-.7003 1.9075-1.7027 2.4064-2.88.4989-1.17729.6367-2.47631.3958-3.73207-.2409-1.25575-.8496-2.41157-1.7488-3.32063-.8992-.90907-2.0483-1.53036-3.3014-1.78496-1.253-.2546-2.5535-.131033-3.73616.35503-1.18266.48606-2.19418 1.31267-2.90606 2.37483s-1.092 2.31195-1.09208 3.59061c-.00798 1.70097.65942 3.33559 1.85563 4.54489 1.1962 1.2093 2.82347 1.8945 4.52437 1.9051z" />
        <path d="m16.24 14.52h-8.56001c-2.03432.0026-3.98471.8112-5.42413 2.2488-1.439421 1.4375-2.25057088 3.3869-2.25586 5.4212v2.79h24v-2.8c-.0065-1.0125-.2125-2.0138-.606-2.9467s-.9669-1.7792-1.6875-2.4905-1.5742-1.2737-2.5122-1.6551c-.9379-.3814-1.9418-.5743-2.9543-.5677z" />
      </g>
    </svg>
  );
}

function SearchOutlineIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 26 25" width="24" height="24" {...props}>
      <g fill="currentColor">
        <path
          d="m10.13 19.26c-1.80694.0016-3.57373-.533-5.07666-1.5361-1.50294-1.0031-2.67443-2.4296-3.36614-4.0989-.691705-1.6693-.872518-3.5063-.51955-5.27844.35296-1.77213 1.22384-3.39968 2.50235-4.67656l1.07 1.08c-1.42687 1.42819-2.22795 3.3647-2.22701 5.3835.00094 2.0189.80382 3.9546 2.23201 5.3815s3.3647 2.2279 5.3835 2.227c2.0189-.0009 3.9546-.8038 5.3815-2.232l1.07 1.08c-.8451.8495-1.8504 1.5228-2.9575 1.9812-1.1072.4583-2.2942.6924-3.4925.6888z"
          opacity=".8"
        />
        <path d="m16.58 16.59-1.07-1.08c1.4268-1.4282 2.2279-3.3647 2.227-5.3835-.001-2.01886-.8038-3.95464-2.232-5.3815-.7072-.70652-1.5466-1.26682-2.4703-1.64893s-1.9136-.57854-2.9133-.57808c-2.01878.00094-3.95456.80382-5.38143 2.23201l-1.06999-1.08c.84834-.84768 1.85533-1.51997 2.96349-1.97849 1.10816-.45851 2.29577-.694262 3.49503-.693798 1.1993.000464 2.3867.237148 3.4945.696518s2.1143 1.13243 2.962 1.98077 1.5199 1.85533 1.9785 2.96349c.4585 1.10816.6942 2.29577.6938 3.49501-.0005 1.1993-.2372 2.3867-.6965 3.4945-.4594 1.1078-1.1325 2.1143-1.9808 2.962z" />
        <path d="m17.0088 15.9173-1.0748 1.0748 7.9974 7.9974 1.0748-1.0748z" opacity=".4" />
      </g>
    </svg>
  );
}

function SearchFilledIcon(props: IconProps) {
  return (
    <svg fill="none" viewBox="0 0 25 25" width="24" height="24" {...props}>
      <path
        d="m25 23.92-7.93-7.94c1.3407-1.588 2.0996-3.5858 2.1511-5.6634.0516-2.07765-.6072-4.11069-1.8675-5.7632s-3.0467-2.8256-5.064-3.32541c-2.0172-.499808-4.14464-.296423-6.03067.57654-1.88603.87296-3.41778 2.36326-4.34216 4.22462-.92438 1.86137-1.186053 3.98245-.74178 6.01265.44428 2.0302 1.56791 3.8481 3.18523 5.1533 1.61731 1.3051 3.63151 2.0195 5.70978 2.0249 2.1601.007 4.2507-.7632 5.89-2.17l7.91 7.91z"
        fill="currentColor"
      />
    </svg>
  );
}

function TicketOutlineIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 24 25" width="24" height="24" {...props}>
      <g fill="currentColor">
        <path
          d="m8.99997 24.99-4.22-3.85.57001-.54c.10484-.0946.18845-.2104.24533-.3396.05688-.1293.08573-.2692.08465-.4104-.00297-.1386-.03473-.2751-.09326-.4007-.05853-.1257-.14256-.2378-.24673-.3293l1-1.1c.25686.2272.46338.5056.60632.8173s.21914.6498.22369.9927c.00786.4227-.09562.84-.30002 1.21l2.09003 1.91 13.17001-11.96c.1064-.0938.1916-.2092.25-.3386.0583-.1293.0885-.2695.0885-.4114s-.0302-.28212-.0885-.41143c-.0584-.12931-.1436-.24472-.25-.33856l-1.25-1.13001c-.524.26754-1.1165.3706-1.7.29565-.5835-.07494-1.1307-.32439-1.57-.71565l1-1.08999c.2513.21349.5703.3307.9.3307s.6487-.11721.9-.3307l.52-.45 2.2 2c.2615.22993.471.51296.6145.83025.1436.31728.2178.66151.2178 1.00974 0 .3482-.0742.6925-.2178 1.0098-.1435.3172-.353.6003-.6145.8302z"
          opacity=".8"
        />
        <path d="m12.35 17.44-5.13998-4.68 1.04-1.09 5.13998 4.67z" />
        <path d="m8.25002 17.44-1.04-1.1 5.13998-4.67 1.04 1.09z" />
        <path
          d="m3.07001 19.57-2.200015-2c-.260336-.2317-.468689-.5159-.611359-.8339-.142669-.318-.2163997-.6626-.2163997-1.0111s.0737307-.6931.2163997-1.0111c.14267-.318.351023-.6022.611359-.8339l14.130005-12.889995 4.23 3.839995-.59.55c-.1047.0938-.1885.20862-.2459.337-.0574.12837-.087.26739-.087.408s.0296.27963.087.408c.0574.12838.1412.24322.2459.33701l-1 1.09c-.2566-.2295-.4629-.50974-.6058-.823s-.2192-.65275-.2242-.99701c-.0095-.39891.0799-.79396.26-1.14999l-2.09-1.9-13.11 11.89999c-.10795.0948-.19417.2118-.25284.3429-.05867.1312-.08841.2734-.08716.4171.00159.1403.0327.2787.09128.4062.05857.1275.1433.2412.24872.3338l1.25 1.14c.52372-.264 1.11482-.3642 1.69628-.2875.58147.0767 1.12637.3267 1.56372.7175l-1 1.09c-.24968-.2127-.56696-.3296-.89498-.3296-.32803 0-.64534.1169-.89502.3296z"
          opacity=".4"
        />
        <path d="m12.66 8.94-2.05-1.91 1.1-1 1.99 1.81z" />
        <path d="m15.69 11.69-2.16-1.96 1.04-1.09 2.16 1.96z" />
        <path d="m18.54 14.28-1.98-1.8 1.04-1.09 2.06 1.87z" />
      </g>
    </svg>
  );
}

function TicketFilledIcon(props: IconProps) {
  return (
    <svg viewBox="0 0 26 25" width="24" height="24" {...props}>
      <path
        d="m24.17 8.44-2.2-2-.52.45999c-.2504.21541-.5697.33387-.9.33387s-.6496-.11846-.9-.33387c-.1042-.09146-.1882-.20358-.2467-.32925s-.0903-.26214-.0933-.40074c-.0011-.14123.0278-.28109.0847-.41036.0568-.12927.1405-.24502.2453-.33964l.59-.55-4.23-3.890004-14.16998 12.890004c-.26153.2299-.47104.513-.61457.8303-.14352.3172-.217769.6615-.217769 1.0097s.074249.6925.217769 1.0097c.14353.3173.35304.6004.61457.8303l2.19998 2 .52002-.46c.24968-.2127.56696-.3296.89499-.3296.32802 0 .6453.1169.89499.3296.10296.0903.18626.2008.24475.3247.05848.1239.0909.2584.09524.3953.00108.1412-.02777.2811-.08465.4104-.05688.1292-.14049.245-.24533.3396l-.57001.55 4.22 3.84 14.17-12.88c.2615-.2299.4711-.513.6146-.8303.1435-.3172.2177-.6615.2177-1.0097 0-.34824-.0742-.69247-.2177-1.00975-.1435-.31729-.3531-.60032-.6146-.83025zm-11.46-2.40001 2 1.81-1 1.09001-2-1.90001zm1.68 10.31001-1 1.09-2-1.87-2.05 1.87-1-1.09 2-1.8-2-1.79 1-1.09 2.05 1.86 2-1.86 1 1.09-2 1.79zm.14-6.62 1.05-1.09 2.15 2-1 1.09zm5 4.56-2-1.81 1-1.09 2.06 1.88z"
        fill="currentColor"
      />
    </svg>
  );
}

export function AudioIcon({ filled, ...iconProps }: NavigationIconProps) {
  const IconComponent = filled ? AudioFilledIcon : AudioOutlineIcon;
  return <IconComponent {...iconProps} />;
}

export function DiscoverIcon({ filled, ...iconProps }: NavigationIconProps) {
  const IconComponent = filled ? DiscoverFilledIcon : DiscoverOutlineIcon;
  return <IconComponent {...iconProps} />;
}

export function LiveIcon({ filled, ...iconProps }: NavigationIconProps) {
  const IconComponent = filled ? LiveFilledIcon : LiveOutlineIcon;
  return <IconComponent {...iconProps} />;
}

export function ProfileIcon({ filled, ...iconProps }: NavigationIconProps) {
  const IconComponent = filled ? ProfileFilledIcon : ProfileOutlineIcon;
  return <IconComponent {...iconProps} />;
}

export function SearchIcon({ filled, ...iconProps }: NavigationIconProps) {
  const IconComponent = filled ? SearchFilledIcon : SearchOutlineIcon;
  return <IconComponent {...iconProps} />;
}

export function TicketIcon({ filled, ...iconProps }: NavigationIconProps) {
  const IconComponent = filled ? TicketFilledIcon : TicketOutlineIcon;
  return <IconComponent {...iconProps} />;
}
