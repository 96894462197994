import type { ComponentType } from 'react';
import FacebookIcon from '@stageplus/icons/react/facebook';
import InstagramIcon from '@stageplus/icons/react/instagram';
import XIcon from '@stageplus/icons/react/x';
import YoutubeIcon from '@stageplus/icons/react/youtube';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import { getSocialMediaData, NetworkName } from 'src/utilities/social-media-helpers';

const icons: Record<NetworkName, ComponentType> = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  twitter: XIcon,
  youtube: YoutubeIcon,
};

const SocialLink = ({ name }: { name: NetworkName }): JSX.Element => {
  const locale = useCurrentLocale();
  const Icon = icons[name];

  const { url, title } = getSocialMediaData(locale, name);
  return (
    <a
      href={url}
      className="flex size-10 items-center justify-center rounded-full bg-white text-mainBgBlueC2 outline-offset-2 focus-visible:focus-outline"
    >
      <Icon aria-hidden />
      <span className="sr-only">{title}</span>
    </a>
  );
};

export default SocialLink;
