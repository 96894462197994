import dynamic from 'next/dynamic';

const DynamicDevelopmentTools = dynamic(() => import('src/components/development-tools/development-tools'), {
  ssr: false,
});

/**
 * Only show development tools when `NEXT_PUBLIC_DEVELOPMENT_TOOLS` is set to `true`
 */
export default function DevelopmentToolsIfEnabled() {
  if (process.env.NEXT_PUBLIC_DEVELOPMENT_TOOLS === 'true') {
    return <DynamicDevelopmentTools />;
  }
  return null;
}
