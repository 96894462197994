import type { SVGProps } from 'react';
const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m17.934 9.344-.52-.547c-.137-.137-.355-.137-.465 0L12 13.747l-4.977-4.95c-.109-.137-.328-.137-.464 0l-.52.547c-.137.11-.137.328 0 .465l5.715 5.714a.315.315 0 0 0 .465 0l5.715-5.714c.136-.137.136-.356 0-.465"
    />
  </svg>
);
export default SvgChevronDown;
