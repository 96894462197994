import { CuratedContentContainerFieldsFragment } from 'generated/graphql';
import { isContentAvailable } from 'src/utilities/content-availability-helpers';

type SliderClickTargetHref = string;
type SliderClickOrigin = {
  /** The id of the slider where the click has happened */
  sliderId: string;
  /** The order index of the slider on the page */
  sliderIndexOnPage: number;
  /** The order index of the clicked item in the slider */
  sliderSlideIndex: number;
};

/**
 * Filters out unavailable content from sliders based on geo-blocking rules and publish dates
 */
export function availableContentSliders(
  sliders: CuratedContentContainerFieldsFragment[],
  options: Parameters<typeof isContentAvailable>[1],
): CuratedContentContainerFieldsFragment[] {
  return sliders.map((slider) => {
    if (slider.__typename === 'ListSlider') {
      return {
        ...slider,
        items: slider.items.filter((item) => isContentAvailable(item, options)),
      };
    }
    return slider;
  });
}

/**
 * Returns true if the slider should be indexable by search engines based on the number of items
 */
export function isSliderIndexable(totalItems: number): boolean {
  return totalItems > 3;
}

/**
 * Global object to store the slider clicks that has navigated to a detail page
 * e.g. { '/videos/vod_concert_1': { sliderId: 'slider-1', sliderIndexOnPage: 0, itemIndex: 1 } }
 *
 * This is used to track which slide was clicked to navigate to a given page
 */
const sliderClicks: Record<SliderClickTargetHref, SliderClickOrigin> = {};

/**
 * Stores the click in the slider that navigated to a detail page
 * @example tsx```
 * setSliderClick('/videos/vod_concert_1', { sliderId: 'slider-1', sliderIndexOnPage: 0, itemIndex: 1 });
 * ```
 */
export const setSliderClick = (target: SliderClickTargetHref, origin: SliderClickOrigin): void => {
  sliderClicks[target] = origin;
};

/**
 * Retrieves the stored click info if it happened in a slider
 * @example tsx```
 * const clickOrigin = getSliderClick('/videos/vod_concert_1');
 * // clickOrigin = { sliderId: 'slider-1', sliderIndexOnPage: 0, itemIndex: 1 }
 * ```
 */
export const getSliderClick = (target: SliderClickTargetHref): SliderClickOrigin | undefined => sliderClicks[target];
