import { Fragment, useEffect } from 'react';
import Script from 'next/script';
import { env } from 'src/config';

// Declare the global window.phrase object so that TypeScript doesn’t complain
declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    phrase?: {
      ui?: {
        target?: HTMLDivElement;
      };
    };
  }
}

/**
 * The configuration for the Phrase editor
 * @see https://support.phrase.com/hc/en-us/articles/5784095916188-In-Context-Editor-Strings-
 */
export const phraseAppConfig = {
  projectId: env.NEXT_PUBLIC_PHRASE_PROJECT_ID,
  phraseEnabled: true,
  prefix: '{{__', // Phrase editor will look for strings beginning with this prefix
  suffix: '__}}', // Phrase editor will look for strings ending with this suffix
  fullReparse: true, // Re-parse the entire page when a DOM change is detected (needed for React virtual DOM)
};

/**
 * Get the Phrase root UI element and reset its styles
 */
function resetPhraseUiElementStyles() {
  // Get the root element of the Phrase UI from the global window.phrase object
  const phraseUiElement = window.phrase?.ui?.target instanceof HTMLDivElement ? window.phrase.ui.target : undefined;

  if (phraseUiElement) {
    // Don’t inherit any styles from the global CSS styles
    phraseUiElement.style.all = 'initial';
    // Do inherit the `fontFamily` and `fontSize` from the Phrase UI root element
    phraseUiElement.style.fontFamily = 'inherit';
    phraseUiElement.style.fontSize = 'inherit';

    return true;
  }
  return false;
}

/**
 * Function to load when the Phrase script is loaded
 *
 * The Phrase script will inject the Phrase UI root element as a sibling of the body element.
 * This results in the Phrase UI inheriting our global CSS styles and looking broken.
 * To fix this, we need to reset the styles of the Phrase UI root element after the script is loaded.
 */
function handleOnScriptLoad() {
  const success = resetPhraseUiElementStyles();
  if (!success) {
    // Poll for the Phrase UI root element until it’s found
    const intervalId = setInterval(() => {
      const success = resetPhraseUiElementStyles();
      if (success) {
        clearInterval(intervalId);
      }
    }, 100);
    // Stop polling after 10 seconds
    setTimeout(() => clearInterval(intervalId), 10_000);
  }
}

/**
 * The Phrase editor is a tool that allows translators to edit the text of the website in place
 *
 * When this component is rendered, it will:
 *  - load a script from phraseapp.com which will inject the Phrase editor UI
 *  - the script will show the Phrase login modal
 *  - when logged in, the Phrase editor will automatically detect all translatable strings in the DOM and allow them to be edited
 *
 * Note:
 *  - This should only be rendered in development, preview, or staging environments
 *  - Translated fields wrapped in Markdown are currently not supported
 *  - The Phrase editor can’t programatically be disabled so the best way to disable it is to reload the page
 */
export default function PhraseEditor() {
  // On unmount, reload the page to clean up any Phrase UI elements
  useEffect(() => {
    return () => window.location.reload();
  }, []);

  return (
    <Fragment>
      <Script id="phrase-editor-config">{`window.PHRASEAPP_CONFIG = ${JSON.stringify(phraseAppConfig)};`}</Script>
      <Script
        id="phrase-editor-script"
        src="https://phraseapp.com/assets/in-context-editor/2.0/app.js"
        onLoad={handleOnScriptLoad}
      />
    </Fragment>
  );
}
