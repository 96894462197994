import ContainerGrid from 'src/components/container-grid';
import { TransientErrorMessage } from 'src/components/errors/transient-messages';
import HeadTags from 'src/components/head-tags';
import PageLayout from 'src/components/layouts/page-layout';
import Page from 'src/components/page';
import useTranslate from 'src/hooks/use-translate';
import { useImpressionTracking } from 'src/tracking';
import { GraphQLErrorType } from 'src/utilities/api-helpers';

type FallbackProps = {
  error: unknown;
  resetError: () => void;
};

const ErrorBoundaryFallback = ({ error }: FallbackProps): JSX.Element => {
  const t = useTranslate();
  // track each display of an error page in the analytics, this is a client error so no code is provided
  const { impressionObserverRef } = useImpressionTracking({ eventName: 'ErrorPageImpression', errorCode: 0 });
  const message = error instanceof Error ? error.message : 'Unknown error';
  // check if error is a not found error
  const isNotFoundError = message === GraphQLErrorType.NotFound;
  // display a different title for not found errors
  const title = isNotFoundError ? t('error_page_404__title') : t('error_page_generic__title');
  // display a different message for not found errors and limit the message to 240 characters to avoid overflowing the page and to avoid leaking sensitive information
  const displayMessage = isNotFoundError ? t('error_page_404__message') : message?.slice(0, 240);
  // display a different action for not found errors
  const action = isNotFoundError ? 'homepage' : undefined;

  return (
    <PageLayout>
      <Page testId="error-boundary-fallback">
        <HeadTags title={t('page__error_title')} noindex />
        <ContainerGrid>
          <div className="col-span-full my-10" ref={impressionObserverRef}>
            <TransientErrorMessage title={title} message={displayMessage} action={action} />
          </div>
        </ContainerGrid>
      </Page>
    </PageLayout>
  );
};

export default ErrorBoundaryFallback;
