const uuidRegex = /^[\da-f]{8}-[\da-f]{4}-4[\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12}$/i;

/**
 * Checks whether a string is a valid UUID.
 * @param uuid The string to check.
 * @returns Whether the string is a valid UUID.
 */
export function isValidUuid(uuid: string): boolean {
  return uuidRegex.test(uuid);
}

/**
 * Returns a random UUID. Uses `crypto.randomUUID()` if available, otherwise generates a random UUID using
 * `crypto.getRandomValues()` which is better supported cross-browser.
 * @returns A random UUID
 * @example
 * ```
 * generateUuid() // 'efbd2136-94d2-4530-8983-1a8e89f19f10'
 * ```
 */
export function generateUuid(): string {
  if ('randomUUID' in crypto) {
    return crypto.randomUUID();
  }
  // Based on https://stackoverflow.com/a/2117523
  return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replaceAll(/[018]/g, (c) =>
    (Number(c) ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))).toString(16),
  );
}
